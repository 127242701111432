import { Avatar, AvatarGroup, Box, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo, useState } from 'react'
import { Collaborator, Organization } from '../../api/coreapi'
import { useOrganizationReposWithCollaborators } from '../../hooks/api/useOrganizationReposWithCollaborators'
import { useOrganizations } from '../../hooks/api/useOrganizations'
import { formatBytes, toInitials } from '../../utils/textUtils'
import { FlexRow } from '../base/Flex'
import { Column, Table } from '../base/Table'
import { OrgSelector } from './OrgSelector'
import { useOrganizationMember } from '../../hooks/api/useOrganizationMember'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { StyledSkeleton } from '../base/SkeletonTableCell'
import { StyledSkeletonCell } from '../base/SkeletonTableCell'
import { useOrganizationSelection } from '../../hooks/useOrganizationSelection'

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.colors.black.primary,
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}))

const SubHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const RepoNameCell = styled(Typography)(({ theme }) => ({
  height: '44px',
  alignContent: 'center',
  color: theme.colors.black.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const TableContent = styled(Typography)(({ theme }) => ({
  color: theme.colors.black.primary,
}))

type TableRepo = {
  id: string
  name: string
  createdAt: string
  size: number
  collaborators: Collaborator[]
}

const MAX_COLLABORATORS_AVATARS = 4
const MAX_TOOLTIP_COLLABORATORS_DISPLAYED = 6

const getCollaboratorDisplayName = (collaborator: Collaborator): string => collaborator.name ?? collaborator.email ?? ''

const CollaboratorsList = ({
  collaborators,
  maxDisplay = 6,
}: {
  collaborators: Collaborator[]
  maxDisplay?: number
}) => {
  const displayedCollaborators = collaborators.slice(0, maxDisplay)
  const extraCount = collaborators.length - maxDisplay

  return (
    <Box component="span" sx={{ whiteSpace: 'pre-line' }}>
      {displayedCollaborators.map((c) => getCollaboratorDisplayName(c)).join('\n')}
      {extraCount > 0 && `\nand ${extraCount} more...`}
    </Box>
  )
}

export const OrganizationReposRoute = () => {
  const { data: user } = useUserInfo()
  const [page, setPage] = useState(0)
  const { organizations } = useOrganizations()
  const { currentOrg, setCurrentOrgId } = useOrganizationSelection(organizations)
  const { isOrgAdmin } = useOrganizationMember(currentOrg?.id, user?.id)

  const {
    repos,
    loading: isReposLoading,
    total: totalRepos,
    refresh: refreshRepos,
  } = useOrganizationReposWithCollaborators(currentOrg, page, isOrgAdmin)

  const tableRepos = useMemo(
    () =>
      repos.map((repo) => ({
        id: repo.repo_id,
        name: repo.repo_name,
        createdAt: new Date(repo.created_timestamp * 1000).toLocaleDateString(),
        size: repo.size_bytes,
        collaborators: repo.collaborators,
      })),
    [repos]
  )

  const handleOrgSelect = useCallback(
    (org: Organization) => {
      setCurrentOrgId(org.id)
      setPage(0)
      refreshRepos?.()
    },
    [setCurrentOrgId, refreshRepos]
  )

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage)
  }, [])

  const columns = useMemo<Column<TableRepo>[]>(
    () => [
      {
        id: 'name',
        label: 'Name',
        render: (row) => <RepoNameCell>{row.name}</RepoNameCell>,
        width: isOrgAdmin ? '45%' : '70%',
      },
      {
        id: 'created',
        label: 'Created',
        render: (row) => <TableContent>{row.createdAt}</TableContent>,
        width: '15%',
      },
      {
        id: 'size',
        label: 'Size',
        render: (row) => <TableContent>{formatBytes(row.size)}</TableContent>,
        width: '15%',
      },
      ...(isOrgAdmin
        ? [
            {
              id: 'collaborators',
              label: 'Collaborators',
              render: (row: TableRepo) => (
                <AvatarGroup
                  max={MAX_COLLABORATORS_AVATARS}
                  renderSurplus={(surplus) => (
                    <Tooltip
                      title={
                        <CollaboratorsList
                          collaborators={row.collaborators.slice(MAX_COLLABORATORS_AVATARS - 1)}
                          maxDisplay={MAX_TOOLTIP_COLLABORATORS_DISPLAYED}
                        />
                      }
                    >
                      <span>+{surplus.toString()}</span>
                    </Tooltip>
                  )}
                  sx={{ justifyContent: 'flex-end' }}
                >
                  {row.collaborators.map((collaborator: Collaborator) => (
                    <Tooltip title={getCollaboratorDisplayName(collaborator)}>
                      <Avatar src={collaborator.image}>{toInitials(getCollaboratorDisplayName(collaborator))}</Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              ),
              width: '25%',
            },
          ]
        : []),
    ],
    [isOrgAdmin]
  )

  return (
    <Container>
      <Header>
        <SubHeader>
          <StyledHeader variant="h5">Repositories in {currentOrg?.name}</StyledHeader>
          <FlexRow gap={2}>
            <OrgSelector organizations={organizations} currentOrg={currentOrg} onOrgSelect={handleOrgSelect} />
          </FlexRow>
        </SubHeader>
      </Header>
      <Table
        columns={columns}
        data={tableRepos ?? []}
        isLoading={isReposLoading}
        page={page}
        totalCount={totalRepos}
        onPageChange={handlePageChange}
        skeletonCell={
          <StyledSkeletonCell height="77px" colSpan={columns.length} align="left">
            <StyledSkeleton variant="text" width="40%" />
          </StyledSkeletonCell>
        }
      />
    </Container>
  )
}
