import { formatBytes, RIGHT_ARROW, truncateStart, TWO_SIDED_ARROW } from '../../utils/textUtils'
import { CopyIcon } from '../base/CopyIcon'
import { FlexFiller } from '../base/Flex'
import styled from '@emotion/styled'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { BannerPadding } from '../base/PaddingStyle'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { download } from '../../utils/download'
import isNil from 'lodash/isNil'
import { getFileName, getMoveLabel } from '../../utils/pathUtils'
import { FileState } from '../../models/fileState'
import { notNil } from '../../utils/objectUtil'
import { TextSmall } from '../base/TextStyle'
import { Tooltip } from '@mui/material'
import { SwitchButtons } from '../base/SwitchButtons'
import DiffFilesIcon from '@mui/icons-material/Compare'
import SingleFileIcon from '@mui/icons-material/Article'
import HistoryIcon from '@mui/icons-material/History'
import { useUrlState } from '../../hooks/useUrlState'
import { useNavigate } from 'react-router'
import { routeToFileHistory } from '../../RouteDefinitions'
import { DeleteFileButton } from '../sidepanel/workspace/delete/DeleteFileButton'
import { BASE_SIDE, OTHER_SIDE } from '../../utils/conflictUtils'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { useOpenLocalItemWithDefaultApp } from '../../desktop/hooks/useOpenLocalItemWithDefaultApp'
import { useIsLocallyCloned } from '../../desktop/hooks/useIsLocallyCloned'

type Props = {
  file: FileState
  baseFile?: FileState
  allowDiffSwitch: boolean
  isDiffView?: boolean
  setDiffView?: (isDiffView: boolean) => void
  hasParentRelationship: boolean
  showFileHistory: boolean
}

type ViewOptions = 'file' | 'diff'

export const FILE_HEADER_HEIGHT_REM = 3.625

const Header = styled.div`
  ${BannerPadding};
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 100%;
  max-height: ${FILE_HEADER_HEIGHT_REM}rem;
  min-height: 0;
  box-sizing: border-box;
  align-items: center;
  gap: 2rem;
  overflow-y: hidden;
  overflow-x: auto;
`

const FilePath = styled.div`
  color: ${({ theme }) => theme.colors.blue.primary};
`

const FileType = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const MovePathArrow = styled.span`
  margin: 0 0.5rem;
`

const FileSize = styled.div`
  color: ${({ theme }) => theme.colors.black.secondary};
`

const formatFileSize = (file: FileState, baseFile: FileState | undefined, hasParentRelationship: boolean) => {
  const formatted = formatBytes(file.sizeBytes)
  if (isNil(baseFile) || !baseFile.existsOnServer) {
    return formatted
  }
  const formattedBase = formatBytes(baseFile.sizeBytes)
  if (formatted === formattedBase) {
    return formatted
  }
  return `${formattedBase} ${hasParentRelationship ? RIGHT_ARROW : TWO_SIDED_ARROW} ${formatted}`
}

const formatItemType = (file: FileState, baseFile: FileState | undefined) => {
  if (!file.isDirectory && !baseFile?.isDirectory) {
    return
  }
  if (file.isDirectory && baseFile?.isDirectory) {
    return 'Directory'
  }
  if (!file.isDirectory && baseFile?.isDirectory === false) {
    return
  }
  return `${file.isDirectory ? 'Directory' : 'File'} ${TWO_SIDED_ARROW} ${baseFile?.isDirectory ? 'Directory' : 'File'}`
}

const LineBreak = styled.div`
  color: ${({ theme }) => theme.colors.black.secondary};
`

const detectLineBreak = (content?: string) => {
  if (!content) return 'N/A'
  return content.includes('\r\n') ? 'CRLF' : 'LF'
}

const formatLineBreak = (file: FileState, baseFile: FileState | undefined, hasParentRelationship: boolean) => {
  if (file.isDirectory || isNil(baseFile) || !baseFile.existsOnServer) {
    return null
  }
  const fileLineBreak = detectLineBreak(file.content)
  const baseFileLineBreak = detectLineBreak(baseFile.content)
  if (fileLineBreak === baseFileLineBreak) {
    return null
  }
  return `${baseFileLineBreak} ${hasParentRelationship ? RIGHT_ARROW : TWO_SIDED_ARROW} ${fileLineBreak}`
}

const ConditionalFileType = ({ file, baseFile }: { file: FileState; baseFile: FileState | undefined }) => {
  const text = formatItemType(file, baseFile)
  return text ? <FileType>{text}</FileType> : <></>
}

const DownloadButton = styled.div`
  color: ${({ theme }) => theme.colors.black.secondary};
  cursor: pointer;
`

const HistoryIconButton = styled.div`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`

export const FileTitleLine = ({
  file,
  baseFile,
  allowDiffSwitch,
  isDiffView,
  setDiffView,
  hasParentRelationship,
  showFileHistory,
}: Props) => {
  const navigate = useNavigate()
  const { repoId, branchId, workspaceId, commitId } = useUrlState()
  const { isLocallyCloned } = useIsLocallyCloned(workspaceId)
  const { openLocalItemWithDefaultApp } = useOpenLocalItemWithDefaultApp()
  const inEditContext = notNil(workspaceId) && isNil(commitId)
  const copyToClipboard = useCopyToClipboardAction()
  const { filePath } = file
  const fileMoved = notNil(baseFile?.filePath) && filePath !== baseFile!.filePath
  const lineBreakInfo = formatLineBreak(file, baseFile, hasParentRelationship)
  return (
    <Header>
      <ConditionalFileType file={file} baseFile={baseFile} />
      <FilePath title={filePath}>
        {fileMoved && (
          <>
            {truncateStart(baseFile!.filePath)}
            {hasParentRelationship ? (
              <MovePathArrow title={getMoveLabel(filePath, baseFile!.filePath)}>{RIGHT_ARROW}</MovePathArrow>
            ) : (
              <MovePathArrow title="Items path differ as well">{TWO_SIDED_ARROW}</MovePathArrow>
            )}
          </>
        )}
        {truncateStart(filePath)} <CopyIcon copyToClipboard={copyToClipboard} title="Copy file path" text={filePath} />
      </FilePath>
      {baseFile?.existsOnServer && baseFile.downloadUrl && !hasParentRelationship && (
        <Tooltip title={`Download ${OTHER_SIDE} file`} arrow>
          <DownloadButton onClick={() => download(baseFile.downloadUrl!, getFileName(baseFile?.filePath))}>
            <DownloadForOfflineIcon />
          </DownloadButton>
        </Tooltip>
      )}
      {!baseFile?.isDirectory && <FileSize>{formatFileSize(file, baseFile, hasParentRelationship)}</FileSize>}
      {!baseFile?.isDirectory && lineBreakInfo && <LineBreak>{lineBreakInfo}</LineBreak>}
      {file.existsOnServer && file.downloadUrl && (
        <Tooltip title={hasParentRelationship ? 'Download file' : `Download ${BASE_SIDE} file`} arrow>
          <DownloadButton onClick={() => download(file.downloadUrl!, getFileName(filePath))}>
            <DownloadForOfflineIcon />
          </DownloadButton>
        </Tooltip>
      )}
      {isLocallyCloned && workspaceId && repoId && (
        <Tooltip title={`Open with default app`} arrow>
          <DownloadButton>
            <FileOpenIcon
              onClick={async () => {
                await openLocalItemWithDefaultApp(repoId, workspaceId, filePath)
              }}
            />
          </DownloadButton>
        </Tooltip>
      )}
      {allowDiffSwitch && (
        <>
          <SwitchButtons<ViewOptions>
            selected={isDiffView! ? 'diff' : 'file'}
            setSelected={(selected) => setDiffView!(selected === 'diff')}
            buttons={[
              { key: 'file', title: 'File View', icon: <SingleFileIcon /> },
              { key: 'diff', title: 'Diff View', icon: <DiffFilesIcon /> },
            ]}
          />
        </>
      )}
      {showFileHistory && (
        <Tooltip title="File History" arrow>
          <HistoryIconButton onClick={() => navigate(routeToFileHistory(repoId!, filePath, branchId, workspaceId))}>
            <HistoryIcon />
          </HistoryIconButton>
        </Tooltip>
      )}
      {inEditContext && <DeleteFileButton filePath={filePath} />}
      <FlexFiller />
    </Header>
  )
}
