import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import { useSessionStorage } from 'usehooks-ts'
import { useEffect, useRef, useState } from 'react'
import { Styleable } from '../../theme'
import { CircularProgress } from '@mui/material'
import { BoxPadding } from '../base/PaddingStyle'

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.3rem;
`

const StyledTextField = styled.div<{ hasInput: boolean }>`
  margin: 0.5rem;
  display: flex;
  align-items: center;
  ${BoxPadding};
  height: fit-content;
  border: ${({ theme, hasInput }) =>
    hasInput ? `2px solid ${theme.colors.blue.primary}` : `1px solid ${theme.colors.stroke}`};
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.secondary};
  border-radius: 1rem;
  width: ${({ hasInput }) => (hasInput ? 'calc(100% - 52px)' : '100%')};
  transition: width 0.3s ease-in-out;
`

const StyledInput = styled.input`
  background-color: inherit;
  padding-left: 1rem;
  outline: 0 none;
  width: 100%;
`

const ClearButton = styled.div<{ visible: boolean }>`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  opacity: ${({ visible }) => (visible ? '0.8' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition:
    opacity 0.6s,
    color 0.6s;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;

  svg {
    width: 20px;
  }

  :hover {
    opacity: 1;
  }
`

const SmallLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.black.secondary};
  margin-left: 0.5rem;
  position: absolute;
  right: 0;
`

type Props = Styleable & {
  hint: string
  onChange: (query: string) => void
  isLoading?: boolean
  noPersistSearch?: boolean
  maxTextLength?: number
  autoFocus?: boolean
}

export const SearchBox = ({
  hint,
  onChange,
  isLoading,
  className,
  noPersistSearch,
  maxTextLength,
  autoFocus = true,
}: Props) => {
  const [persistedQuery, setPersistedQuery] = useSessionStorage<string | undefined>(`search.${hint}`, undefined)
  const [stateQuery, setStateQuery] = useState<string | undefined>()
  const [query, setQuery] = noPersistSearch ? [stateQuery, setStateQuery] : [persistedQuery, setPersistedQuery]

  const initialRenderRef = useRef(true)

  // Only run this effect once on initial render to set up persisted query
  useEffect(() => {
    if (initialRenderRef.current && query) {
      onChange(query)
      initialRenderRef.current = false
    }
  }, [query, onChange])

  const hasInput = Boolean(query && query.length > 0)

  return (
    <SearchContainer className={className}>
      <StyledTextField hasInput={hasInput}>
        <SearchIcon />
        <StyledInput
          value={query}
          autoFocus={autoFocus}
          type="text"
          maxLength={maxTextLength}
          placeholder={hint}
          onChange={(event) => {
            const { value } = event.target
            setQuery(value)
            onChange(value)
          }}
        />
      </StyledTextField>

      {isLoading ? (
        <SmallLoader size={20} />
      ) : (
        <ClearButton
          visible={hasInput}
          title="Clear"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()
            setQuery('')
            onChange('')
          }}
        >
          Clear
        </ClearButton>
      )}
    </SearchContainer>
  )
}
