/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComparisonItem } from '../models/ComparisonItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RepositoryContentComparisonService {
    /**
     * Compare two refs (commit/branch/workspace/tag/tree) in this repo. (Executed on secondary shard)
     * @returns any A list of tree comparison objects
     * @throws ApiError
     */
    public static srcHandlersv2TreeCompare({
        repoId,
        baseId = '',
        otherId,
        recurse = 'modified',
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A workspace_id, branch_id, commit_id or tag_id.
         * If omitted, assuming the empty tree, unless other_id is a workspace,
         * in which case base_id is assumed to be the workspace base ref.
         * If `recurse` is set to `all_changes`, new/deleted folders will be recursed into, to include the children.
         * If `recurse` is set to `modified` (default), only the modified folders will be recursed
         * into. New/deleted folders will be 'collapsed' into a single entry.
         *
         */
        baseId?: string,
        /**
         * A reference to a source version from which changes will be taken
         */
        otherId?: string,
        /**
         * Specifies whether to include cascaded new/deleted folders (`all_changes`) or not (`modified`).
         */
        recurse?: 'modified' | 'all_changes',
    }): CancelablePromise<{
        object?: 'ComparisonItem';
        items: Array<ComparisonItem>;
        /**
         * Number of cascaded changes, for insight of total compare size
         */
        cascaded_changes_count?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/compare',
            path: {
                'repo_id': repoId,
            },
            query: {
                'base_id': baseId,
                'other_id': otherId,
                'recurse': recurse,
            },
        });
    }
}
