import { Auth } from 'aws-amplify'
import { fetchGetAsync } from './fetcher'
import config from '../env/config'
import { OpenAPI as CoreApiConfig } from './coreapi'
import { UserInfo } from '../models/userInfo'
import { log } from '../utils/log'
import { errorToast } from '../utils/toast'
import * as Sentry from '@sentry/react'
import { identifyUser } from '../hooks/api/useAnalytics'
import { hubspotIdentifyUser } from '../utils/hubspot'
import { rawNavigate } from '../utils/navigateRaw'
import {
  routeToDesktopLogin,
  routeToDesktopLogout,
  routeToLogout,
  routeToSignIn,
  routeToSignInPasswordReset,
  routeToSignInPasswordResetVerifyWithoutQueryParams,
  routeToSignUp,
  routeToSignUpVerifyWithoutQueryParams,
} from '../RouteDefinitions'
import { initiateAgentCredentials } from '../desktop/components/utils/agentLogin'
import { IsDesktopApp } from '../desktop/components/utils/DesktopAppApi'
import { sanitizePath } from '../utils/pathUtils'
import { buildSigninUrl } from '../components/auth/configure'

const BASE_AUTH_API_PATH = `https://${config.AUTH_DOMAIN}`

log.info('set up auth api', BASE_AUTH_API_PATH)

const getAuthToken = () => CoreApiConfig.TOKEN as string | undefined
const setAuthToken = (token: string) => (CoreApiConfig.TOKEN = token)

// Desktop OAuth pages are not "auth pages" in the same sense as sign-in/sign-up
// They need to be able to redirect to sign-in when the session is invalid
// Therefore they are not included in the check for isInAuthPage.
const authPages = [
  routeToSignUp(),
  routeToSignIn(),
  routeToSignUpVerifyWithoutQueryParams(),
  routeToSignInPasswordResetVerifyWithoutQueryParams(),
]
const isInAuthPage = () => authPages.includes(sanitizePath(window.location.pathname))

// This pages doesn't require auth to be loaded, we redirect to the desktop application to continue the auth flow
const isInWebOnlyAuthPages = () =>
  [routeToDesktopLogin(), routeToDesktopLogout()].includes(sanitizePath(window.location.pathname))

// Paths that don't make sense to redirect back to after authentication
export const shouldSkipRedirectUri = (path: string) => {
  const sanitizedPath = sanitizePath(path)
  return [...authPages, routeToDesktopLogout(), routeToSignInPasswordReset(), routeToLogout(), ''].includes(
    sanitizedPath
  )
}

const initializeAuthSessionAsync = async () => {
  try {
    const session = await Auth.currentSession()
    log.info('stored session token', session.getAccessToken().payload)
    setAuthToken(session.getAccessToken().getJwtToken())
    if (IsDesktopApp()) {
      await initiateAgentCredentials(session.getRefreshToken().getToken())
    }
    return true
  } catch (sessionError) {
    log.info('session is invalid, will redirect to signin', sessionError)
    try {
      if (!(isInAuthPage() || (!IsDesktopApp() && isInWebOnlyAuthPages()))) {
        const currentUrl = window.location.href

        // Only include redirect URI if it makes sense
        const signInUrl = shouldSkipRedirectUri(window.location.pathname)
          ? routeToSignIn()
          : buildSigninUrl(routeToSignIn(), undefined, currentUrl)

        rawNavigate(signInUrl)
      }
    } catch (signInError) {
      log.error('failed to initiate sign in', signInError)
      errorToast((signInError as Error)?.message)
    }
    return false
  }
}

const signOutAsync = async () => {
  Sentry.setUser(null)
  await Auth.signOut()
  rawNavigate(routeToSignIn())
}

export const USER_INFO_API = '/oauth2/userInfo'
const emptyUser = { email: '', name: undefined, picture: undefined, username: '' }

const getUserInfoAsync = async (): Promise<UserInfo> => {
  let userInfo: UserInfo
  try {
    userInfo = await fetchGetAsync<UserInfo>(`${BASE_AUTH_API_PATH}${USER_INFO_API}`, getAuthToken())
  } catch (error) {
    log.warn('failed to get user info from auth server, retrying with auth session', error)
    let userIsLoggedIn = false
    try {
      userIsLoggedIn = await initializeAuthSessionAsync()
      userInfo = await fetchGetAsync<UserInfo>(`${BASE_AUTH_API_PATH}${USER_INFO_API}`, getAuthToken())
    } catch (error) {
      // If the user isn't logged in, they will be redirected to the sign-in page, and we should ignore this error
      if (userIsLoggedIn) {
        log.error('failed to get user info from auth server after re-auth', error)
      }
      return emptyUser
    }
  }
  config.IS_INTERNAL_USER = userInfo.email.endsWith(config.INTERNAL_USER_EMAIL_SUFFIX)
  try {
    Sentry.setUser(userInfo)
  } catch (error) {
    log.error('failed to set user info in sentry', error)
  }
  try {
    hubspotIdentifyUser(userInfo)
  } catch (error) {
    log.error('failed to set user info in hubspot', error)
  }
  try {
    identifyUser()
  } catch (error) {
    log.error('failed to set user info for analytics', error)
  }
  return userInfo
}

export { initializeAuthSessionAsync, signOutAsync, getUserInfoAsync }
