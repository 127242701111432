import { useCallback, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useTreeData } from '../../../hooks/api/useTreeData'
import { useWorkspace } from '../../../hooks/api/useWorkspace'
import { log } from '../../../utils/log'
import isNil from 'lodash/isNil'
import { useBranch } from '../../../hooks/api/useBranch'
import { FileEntry } from '../../../api/coreapi'
import { useWorkspaceStatus, WorkspaceStatusType } from '../../../hooks/api/useWorkspaceStatus'
import { ActionValidationContext, ActionValidationState } from './actionValidationContext'
import { useCommits } from '../../../hooks/api/useCommits'
import { TriggerRefreshAgent } from '../../../desktop/components/utils/refreshAgent'
import { FilesPage } from '../../../hooks/api/getTreePage'
import { maxDepthToFetchInTree, useRefreshOnWorkspaceRevisionUpdated, WorkspacePanel } from './WorkspacePanel'
import { getAncestorPaths } from '../../../utils/pathUtils'

type Props = {
  repoId: string
  workspaceId: string
  selectedFilePath?: string
}

const getListTreeViewKeys = (items?: FileEntry[]) => {
  if (isNil(items)) {
    return []
  }
  return items.map((i) => i.path)
}

export const WorkspacePanelWrapper = ({ repoId, workspaceId, selectedFilePath }: Props) => {
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const { workspace, refresh: refreshWorkspaceInstance } = useWorkspace(repoId, workspaceId)
  const [checkedPathsCount, setCheckedPathsCount] = useState<number>(0) // might not be valid count if all checkbox was triggered
  const [actionValidationState, setActionValidationState] = useState(ActionValidationState.ReadyForAction)

  const { refresh: refreshTree, refreshWithOptions: refreshTreeWithOptions } = useTreeData(
    repoId,
    workspaceId,
    undefined,
    {},
    maxDepthToFetchInTree,
    workspace?.branch_id || workspace?.base_commit_id
  )
  const { refresh: refreshChanges, data: changesData } = useWorkspaceStatus('TreeData', repoId, workspaceId)
  useRefreshOnWorkspaceRevisionUpdated(() => {
    log.info('workspace revision updated')
    refreshWorkspaceInstance()
    refreshTree()
    refreshChanges()
  })
  const { refresh: refreshBranch } = useBranch(repoId, workspace?.branch_id)
  const { refresh: refreshCommits } = useCommits(repoId!!, workspace?.branch_id || workspace?.base_commit_id, 5)

  const checkableKeys = useMemo(() => {
    const paths = getListTreeViewKeys(changesData?.items)

    // Get all paths and their ancestors
    return Array.from(new Set(paths.flatMap((path) => [path, ...getAncestorPaths(path)])))
  }, [changesData])

  const allChecked = useMemo(
    () => (isEmpty(checkedKeys) ? false : checkableKeys.every((key) => checkedKeys.includes(key)) ? true : null),
    [checkableKeys, checkedKeys]
  )
  const onChecked = useCallback(
    (keys: string[]) => {
      const uncheckedPath = checkedKeys.find((key) => !keys.includes(key))
      if (uncheckedPath) {
        const ancestors = getAncestorPaths(uncheckedPath)
        setCheckedKeys(keys.filter((key) => !ancestors.includes(key)))
        return
      }

      const selectedKeysSet = new Set(keys)
      const childPaths = checkableKeys.filter((path) => {
        if (selectedKeysSet.has(path)) {
          return false
        }
        return getAncestorPaths(path).some((ancestor) => selectedKeysSet.has(ancestor))
      })
      setCheckedKeys([...keys, ...childPaths])
    },
    [checkableKeys, checkedKeys]
  )

  const cleanWorkspace = useCallback(
    (
      clearChecked: boolean = true,
      optimisticTreeDataFn?: (currentData?: FilesPage) => FilesPage | undefined,
      optimisticChangesDataFn?: (currentData?: WorkspaceStatusType) => WorkspaceStatusType | undefined
    ) => {
      log.info('cleaning workspace state', { clearChecked })
      TriggerRefreshAgent(workspaceId, repoId)
      setActionValidationState(ActionValidationState.PostAction)
      refreshTreeWithOptions({
        optimisticData: optimisticTreeDataFn,
        rollbackOnError: true,
      }).finally(() => {
        setActionValidationState(ActionValidationState.ReadyForAction)
      })
      refreshChanges({
        optimisticData: optimisticChangesDataFn,
        rollbackOnError: true,
      })
      refreshBranch()
      if (clearChecked) {
        setCheckedKeys([])
        setCheckedPathsCount(0)
      }
      refreshCommits()
    },
    [workspaceId, repoId, refreshTreeWithOptions, refreshChanges, refreshBranch, refreshCommits]
  )

  return (
    <ActionValidationContext.Provider value={{ actionValidationState, revalidateState: cleanWorkspace }}>
      <WorkspacePanel
        repoId={repoId}
        workspaceId={workspaceId}
        selectedFilePath={selectedFilePath}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        allChecked={allChecked}
        checkedPathsCount={checkedPathsCount}
        onChecked={onChecked}
        checkableKeys={checkableKeys}
        setCheckedPathsCount={setCheckedPathsCount}
      />
    </ActionValidationContext.Provider>
  )
}
