import { ActionDialog } from '../dialogs/ActionDialog'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Anchor } from '../base/Anchor'
import { TextBold } from '../base/TextStyle'
import { CommitChangesTree } from './CommitChangesTree'
import { FileEntry } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  postCommitAsync: () => Promise<void>
  mergeIntoWorkspace: boolean
  shouldUseCountdownTimer?: boolean
  checkedChangedItems?: FileEntry[]
}

const COUNTDOWN_TIME_SEC = 15

const SupportLink = styled(Anchor)`
  color: ${({ theme }) => theme.colors.blue.primary};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue.dark};
  }
`

const TextBlock = styled.div`
  margin-bottom: 1rem;
`

const TextSpanBold = styled.span`
  ${TextBold}
`

const ConfirmationMessage = () => {
  return (
    <div style={{ lineHeight: '1.6' }}>
      <TextBlock>You're doing great work! 🚀</TextBlock>
      <TextBlock>
        We noticed you haven't purchased a sufficient number of licenses for your organization. To continue committing
        and unlock the full power of Diversion, <TextSpanBold>please update your plan.</TextSpanBold>
      </TextBlock>
      <TextBlock>
        ⚠️ Heads up! If you don't update your payment method, committing changes will soon be disabled. Don't let your
        progress come to a halt!
      </TextBlock>
      <TextBlock>
        Need more time to evaluate? Please{' '}
        <SupportLink target={'mailto:billing@diversion.dev'}>contact billing</SupportLink>. Having any issues with the
        product? <SupportLink target={'mailto:support@diversion.dev'}>Contact support</SupportLink>.
      </TextBlock>
    </div>
  )
}

const dialogText = (mergeIntoWorkspace: boolean, useCountdownTimer: boolean, checkedChangedItems?: FileEntry[]) => {
  // Set the basic labels based on operation type
  const label = mergeIntoWorkspace ? 'Apply' : 'Commit'
  const baseLoadingMessage = mergeIntoWorkspace ? 'Applying your changes...' : 'Committing your changes...'

  // Handle case when no items are provided
  if (!checkedChangedItems?.length) {
    const baseMessage = mergeIntoWorkspace
      ? 'You are about to apply changes, do you want to proceed?'
      : 'You are about to commit changes, do you want to proceed?'

    return {
      label,
      message: !mergeIntoWorkspace && useCountdownTimer ? <ConfirmationMessage /> : baseMessage,
      loadingMessage: baseLoadingMessage,
    }
  }

  const fileCount = checkedChangedItems.length
  const fileText = fileCount === 1 ? 'file' : 'files'

  const messageContent =
    !mergeIntoWorkspace && useCountdownTimer ? (
      <ConfirmationMessage />
    ) : (
      <FlexColumn gap={0.5}>
        You are about to {mergeIntoWorkspace ? 'apply changes to' : 'commit'} {fileCount} {fileText}:
        <CommitChangesTree checkedChangedItems={checkedChangedItems} />
      </FlexColumn>
    )

  return {
    label,
    message: messageContent,
    loadingMessage: baseLoadingMessage,
  }
}

export const CommitDialog = ({
  isOpen,
  setOpen,
  postCommitAsync,
  mergeIntoWorkspace,
  shouldUseCountdownTimer = false,
  checkedChangedItems,
}: Props) => {
  const { label, message, loadingMessage } = dialogText(
    mergeIntoWorkspace,
    shouldUseCountdownTimer,
    checkedChangedItems
  )
  const [timeRemaining, setTimeRemaining] = useState(COUNTDOWN_TIME_SEC)
  const [isCommitButtonDisabled, setIsCommitButtonDisabled] = useState(shouldUseCountdownTimer)

  useEffect(() => {
    if (!shouldUseCountdownTimer || !isOpen) {
      setTimeRemaining(COUNTDOWN_TIME_SEC)
      setIsCommitButtonDisabled(shouldUseCountdownTimer)
      return
    }

    let timer: NodeJS.Timeout | null = null
    if (isOpen && timeRemaining > 0) {
      timer = setTimeout(() => {
        setTimeRemaining((prevTime) => prevTime - 1)
      }, 1000)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isOpen, timeRemaining, shouldUseCountdownTimer])

  useEffect(() => {
    if (timeRemaining === 0) {
      setIsCommitButtonDisabled(false)
    }
  }, [timeRemaining])

  const buttonLabel = shouldUseCountdownTimer && isCommitButtonDisabled ? `${label} (${timeRemaining}s)` : label

  return (
    <ActionDialog
      title={label}
      isOpen={isOpen}
      onConfirmAsync={postCommitAsync}
      message={message}
      setOpen={setOpen}
      confirmButtonLabel={buttonLabel}
      loadingMessage={loadingMessage}
      disabled={shouldUseCountdownTimer && isCommitButtonDisabled}
    />
  )
}
