import React, { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { PrimaryButton } from '../base/PrimaryButton'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router'
import { routeToSignIn, routeToSignUpVerify } from '../../RouteDefinitions'
import { usePageTitle } from '../../hooks/usePageTitle'
import { callAsync } from '../../utils/callAsync'
import { Loader } from '../base/Loader'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { AuthColumn, AuthErrorText, AuthPage, StyledInput, StyledLink, SubText, useSetEmailFromQuery } from './Common'
import { signInWithGoogleAsync, signUpAsync } from './actions'
import { Separator } from '../base/Separator'
import { StyledGoogleIcon } from './SignInPage'
import { TextTitle } from '../base/TextStyle'
import { Anchor } from '../base/Anchor'
import config from '../../env/config'
import { useKeyPress } from '../../hooks/useKeyPress'

const passwordRequirements =
  'The password must be at least 10 characters long and must not contain leading or trailing spaces.'

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledSeparator = styled(Separator)`
  margin: 1rem 0;
`

const TitleText = styled.div`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
  text-align: center;
  width: 100%;
`

const StyledAnchor = styled(Anchor)`
  text-decoration: underline;
`

const expectedSignUpErrors = ['UserLambdaValidationException', 'InvalidParameterException', 'InvalidPasswordException']

type Props = {
  signUpDisabled?: boolean
}

export const SignUpPage = ({ signUpDisabled }: Props) => {
  const navigate = useNavigate()
  const [password, setPassword] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [emailError, setEmailError] = useState<string>()
  const [continuedWithEmail, setContinuedWithEmail] = useState<boolean>()
  const [requestError, setRequestError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const defaultEmail = useSetEmailFromQuery(setEmail)
  const onApiError = useContext(PublishApiErrorContext)
  const postAnalytics = useAnalytics()
  usePageTitle('Sign Up')

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  useKeyPress(
    'Enter',
    (event: KeyboardEvent) => {
      if (continuedWithEmail ? !isEmpty(email) && !isEmpty(password) : !isEmpty(email)) {
        continuedWithEmail ? signUp() : initiateSignup(email!)
      }
    },
    true
  )

  useEffect(() => {
    if (signUpDisabled) {
      navigate(routeToSignIn())
    }
  }, [navigate, signUpDisabled])

  const initiateSignup = (inputEmail: string) => {
    const trimmedEmail = String(inputEmail).toLowerCase().trim()

    if (!validateEmail(trimmedEmail)) {
      setEmailError('Please enter a valid email address')
      return
    }

    setEmail(trimmedEmail)
    setEmailError(undefined)
    setContinuedWithEmail(true)
  }

  const signUp = async () => {
    const emailToUse = String(email!).toLowerCase().trim()

    if (!validateEmail(emailToUse)) {
      setEmailError('Please enter a valid email address')
      return
    }

    setEmail(emailToUse)
    setEmailError(undefined)
    postAnalytics('SignUpClicked', { email: emailToUse })
    await callAsync(
      async () => {
        const username = emailToUse.replace('@', '_at_')
        await signUpAsync(username!, password!, emailToUse)
        // Unfortunately, we can't use the autoSignIn feature of signUpAsync
        // https://github.com/aws-amplify/amplify-js/issues/2562
        sessionStorage.setItem('autoSignIn', 'true')
        navigate(routeToSignUpVerify(username!, emailToUse))
      },
      setLoading,
      (error) => {
        if (expectedSignUpErrors.includes(error.name)) {
          setRequestError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }

  const loginWithGoogle = async () => {
    postAnalytics('SignInWithGoogleClicked', {})
    await signInWithGoogleAsync()
  }

  return (
    <AuthPage>
      <AuthColumn>
        <TitleText>Join Diversion</TitleText>
        <div />
        {!continuedWithEmail && (
          <>
            <StyledButton disabled={false} onClick={loginWithGoogle}>
              <StyledGoogleIcon /> Continue with Google
            </StyledButton>
            <StyledSeparator />
          </>
        )}
        <StyledInput
          autoFocus
          type="email"
          placeholder="name@host.com"
          autoComplete="email"
          value={email}
          defaultValue={defaultEmail}
          onChange={(e) => {
            setRequestError(undefined)
            setEmailError(undefined)
            setEmail(e.target.value)
          }}
        />
        {emailError && <AuthErrorText>{emailError}</AuthErrorText>}
        {continuedWithEmail && (
          <>
            <StyledInput
              type="password"
              placeholder="Password"
              autoComplete="new-password"
              onChange={(e) => {
                setRequestError(undefined)
                setPassword(e.target.value)
              }}
            />
            <SubText>{passwordRequirements}</SubText>
          </>
        )}
        {loading ? (
          <Loader />
        ) : (
          <StyledButton
            disabled={continuedWithEmail ? isEmpty(email) || isEmpty(password) : isEmpty(email)}
            onClick={() => {
              continuedWithEmail ? signUp() : initiateSignup(email!)
            }}
          >
            {continuedWithEmail ? 'Sign up' : 'Continue with email'}
          </StyledButton>
        )}
        {requestError && <AuthErrorText>{requestError}</AuthErrorText>}
        <div>
          Already have an account? <StyledLink onClick={() => navigate(routeToSignIn())}>Sign in</StyledLink>
        </div>
        <StyledSeparator />
        <SubText>
          By creating an account, you acknowledge that you have read and understood, and agree to the{' '}
          <StyledAnchor target={config.TOS_LINK}>Terms of Service</StyledAnchor> and{' '}
          <StyledAnchor target={config.PRIVACY_POLICY_LINK}>Privacy Policy</StyledAnchor>. We'll occasionally send you
          account-related emails.
        </SubText>
      </AuthColumn>
    </AuthPage>
  )
}
