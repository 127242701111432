import { useCallback, useEffect, useMemo } from 'react'
import { Organization } from '../api/coreapi'
import { useLocation, useSearchParams } from 'react-router-dom'
import { organizationRoutes, orgIdQueryParam } from '../RouteDefinitions'

export interface OrganizationSelectionResult {
  /** Currently selected organization, if any */
  currentOrg: Organization | undefined
  /** Set the current organization ID in URL */
  setCurrentOrgId: (orgId: string) => void
  /** Whether the user is part of multiple organizations */
  hasMultipleOrganizations: boolean
}

/**
 * Hook for organization selection through URL query parameters
 */
export function useOrganizationSelection(organizations: Organization[]): OrganizationSelectionResult {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const orgIdParam = searchParams.get(orgIdQueryParam)

  const currentOrg = useMemo(() => {
    if (!orgIdParam || organizations.length === 0) return undefined
    return organizations.find((org) => org.id === orgIdParam)
  }, [orgIdParam, organizations])

  const setCurrentOrgId = useCallback(
    (orgId: string) => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.set(orgIdQueryParam, orgId)
        return newParams
      })
    },
    [setSearchParams]
  )

  useEffect(() => {
    const isOrganizationSettingsRoute = organizationRoutes.some((route) => location.pathname.includes(route))

    if (isOrganizationSettingsRoute && organizations.length > 0 && !orgIdParam) {
      setCurrentOrgId(organizations[0]!.id)
    }
  }, [location.pathname, orgIdParam, organizations, setCurrentOrgId])

  return {
    currentOrg,
    setCurrentOrgId,
    hasMultipleOrganizations: organizations.length > 1,
  }
}
