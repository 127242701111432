import { useCallback, useState } from 'react'
import { InitRepoDialog } from '../components/InitRepoDialog'
import { LocationContext } from '../../models/Analytics'
import { FlexRow } from '../../components/base/Flex'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { PrimaryButton } from '../../components/base/PrimaryButton'
import styled from '@emotion/styled'

const StyledFlexRow = styled(FlexRow)`
  white-space: nowrap;
`

export const useInitRepo = (
  locationContext: LocationContext,
  onOpen: (open: boolean) => void,
  onSuccess: () => void
) => {
  const [isOpen, setIsOpen] = useState(false)

  const onModalOpen = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      onOpen(open)
    },
    [setIsOpen, onOpen]
  )
  return {
    Dialog: () => (
      <InitRepoDialog isOpen={isOpen} setOpen={onModalOpen} onSuccess={onSuccess} locationContext={locationContext} />
    ),
    Button: () => (
      <PrimaryButton disabled={false} onClick={() => onModalOpen(true)}>
        <StyledFlexRow gap={0.5} centered={true} centerContent={true}>
          <AddBoxOutlinedIcon />
          New Repository
        </StyledFlexRow>
      </PrimaryButton>
    ),
    isOpen,
    setIsOpen,
  }
}
