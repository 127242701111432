import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { appendFilePath, routeToCommitCompare, routeToCommitCompareWithCommit } from '../../RouteDefinitions'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { ForwardedRef, forwardRef, useMemo } from 'react'
import { CommitCard } from './CommitCard'
import { Loader } from '../base/Loader'
import { BannerPadding } from '../base/PaddingStyle'
import { useTagsByCommitId } from '../../hooks/api/useTagsByCommitId'
import { notNil } from '../../utils/objectUtil'
import isNil from 'lodash/isNil'
import { Tag } from '../../api/coreapi'
import { useCommit } from '../../hooks/api/useCommit'
import { FlexRowStyle } from '../base/Flex'

type Props = {
  repoId: string
  commitId: string
  compareCommitId?: string
  filePath?: string
}

const Container = styled.div`
  ${BannerPadding};
  ${FlexRowStyle};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue.light};
  overflow: auto;
  scroll-behavior: auto;
  min-height: fit-content;
`

const CompareButton = styled(Link)`
  ${FlexRowStyle};
  margin: 0 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue.primary};
  gap: 0.5rem;
`

const StyledLoader = styled(Loader)`
  justify-content: start;
  margin-left: 9rem;
`

export const CommitHeader = forwardRef(
  ({ repoId, commitId, compareCommitId, filePath }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { commit, loading: commitLoading } = useCommit(repoId, commitId)
    const { commit: compareCommit, loading: compareCommitLoading } = useCommit(repoId, compareCommitId)
    const { tagsByCommitId } = useTagsByCommitId(repoId)
    const copyToClipboard = useCopyToClipboardAction()

    const loaded = !commitLoading && !compareCommitLoading && commit && (!compareCommitId || compareCommit)

    const tags: Tag[] | undefined = useMemo(
      () => (notNil(compareCommitId) || isNil(tagsByCommitId) ? undefined : tagsByCommitId[commitId]),
      [commitId, compareCommitId, tagsByCommitId]
    )

    return (
      <Container ref={ref}>
        {!loaded ? (
          <StyledLoader />
        ) : (
          <>
            {compareCommitId && (
              <>
                <CommitCard
                  repoId={repoId}
                  commit={compareCommit!}
                  copyToClipboard={copyToClipboard}
                  linkeable
                  highlight={false}
                  showUserName
                />
                <CompareButton
                  title="Flip"
                  to={appendFilePath(routeToCommitCompareWithCommit(repoId, compareCommitId, commitId), filePath)}
                >
                  <CompareArrowsIcon />
                </CompareButton>
              </>
            )}
            <CommitCard
              repoId={repoId}
              commit={commit}
              copyToClipboard={copyToClipboard}
              tags={tags}
              linkeable={notNil(compareCommitId)}
              highlight={notNil(compareCommitId)}
              showUserName
            />
            {!compareCommitId && (
              <CompareButton title="Compare" to={routeToCommitCompare(repoId, commitId)}>
                <CompareArrowsIcon />
                Compare
              </CompareButton>
            )}
          </>
        )}
      </Container>
    )
  }
)
