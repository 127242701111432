import { SidePanel } from './SidePanel'
import { Section } from './compare/Section'
import { BranchSelector } from '../selector/BranchSelector'
import { CommitSelector } from '../selector/CommitSelector'
import { useEffect, useState } from 'react'
import { log } from '../../utils/log'
import isEmpty from 'lodash/isEmpty'
import { routeToCommitCompareWithCommit } from '../../RouteDefinitions'
import styled from '@emotion/styled'
import { INTERPUNCT, truncate, truncateCommitMessage } from '../../utils/textUtils'
import { useCommit } from '../../hooks/api/useCommit'
import { BannerPadding } from '../base/PaddingStyle'
import { TextRegular } from '../base/TextStyle'
import { useBranchesById } from '../../hooks/api/useBranches'
import { FlexColumn } from '../base/Flex'

type Props = {
  repoId: string
  baseCommitId: string
  compareCommitId?: string
}

const Container = styled(FlexColumn)`
  height: 50%;
`

const HeaderLine = styled.div`
  ${BannerPadding};
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  text-align: start;
`

const RefName = styled.span`
  ${TextRegular};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  width: fit-content;
  padding: 0 ${({ theme }) => theme.padding.m}rem;
`

const HeaderText = ({ commitName, branchName }: { commitName: string; branchName: string | undefined }) => (
  <div>
    <div>Choose commit to compare with</div>
    <div>
      {branchName && (
        <>
          <RefName title={branchName.trim()}>{truncate(branchName.trim())}</RefName>
          {` ${INTERPUNCT} `}
        </>
      )}
      <RefName title={commitName.trim()}>{truncate(commitName).trim()}</RefName>
    </div>
  </div>
)

const Scrollable = styled.div`
  overflow: auto;
`

const StyledSidePanel = styled(SidePanel)`
  padding-bottom: 4rem;
`

export const CompareSidePanel = ({ repoId, baseCommitId, compareCommitId }: Props) => {
  const [compareBranchId, setCompareBranchId] = useState<string>()
  const { branchesById, loading: branchesLoading } = useBranchesById(repoId)
  const [query, setQuery] = useState<string>()

  const { commit: baseCommit, loading: baseCommitLoading } = useCommit(repoId, baseCommitId)

  const isSingleBranch = branchesById && Object.keys(branchesById).length === 1

  useEffect(() => {
    if (!compareBranchId && !isEmpty(branchesById)) {
      const firstBranchId = Object.keys(branchesById)[0]
      log.info('selecting first branch as default', { repoId, branchId: firstBranchId })
      setCompareBranchId(firstBranchId)
    }
  }, [branchesById, compareBranchId, repoId])

  return (
    <StyledSidePanel
      contextName={'compareSidePanel'}
      loading={branchesLoading || baseCommitLoading}
      hasOutletContent={Boolean(compareBranchId) && Boolean(compareCommitId)}
    >
      <>
        <HeaderLine>
          <HeaderText
            commitName={(baseCommit && truncateCommitMessage(baseCommit)) || baseCommitId}
            branchName={undefined}
          />
        </HeaderLine>
        {isSingleBranch ? (
          <Scrollable>
            <CommitSelector
              repoId={repoId}
              refId={compareBranchId}
              selectedCommitId={compareCommitId}
              noContentMessage={compareBranchId ? undefined : 'Choose a branch to list commits'}
              commitLink={(commit) => routeToCommitCompareWithCommit(repoId, baseCommitId, commit.commit_id)}
              query={query}
              setQuery={setQuery}
            />
          </Scrollable>
        ) : (
          <>
            <Container>
              <Section title="Choose branch to list commits">
                <Scrollable>
                  <BranchSelector
                    repoId={repoId}
                    branchesById={branchesById}
                    workspacesById={{}}
                    selectedBranchId={compareBranchId}
                    hideWorkspace
                    onBranchSelected={(branch) => setCompareBranchId(branch.branch_id)}
                  />
                </Scrollable>
              </Section>
            </Container>
            <Container>
              <Section title="Choose commit">
                <Scrollable>
                  <CommitSelector
                    repoId={repoId}
                    refId={compareBranchId}
                    selectedCommitId={compareCommitId}
                    noContentMessage={compareBranchId ? undefined : 'Choose a branch to list commits'}
                    commitLink={(commit) => routeToCommitCompareWithCommit(repoId, baseCommitId, commit.commit_id)}
                    query={query}
                    setQuery={setQuery}
                  />
                </Scrollable>
              </Section>
            </Container>
          </>
        )}
      </>
    </StyledSidePanel>
  )
}
