import React, { useCallback } from 'react'
import { useUrlState } from '../../hooks/useUrlState'
import { errorToast, infoToast } from '../../utils/toast'
import { Commit, RepositoryCommitManipulationService, RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { ActionDialog } from '../dialogs/ActionDialog'
import { routeToMerge, routeToWorkspaceEdit } from '../../RouteDefinitions'
import { useNavigate } from 'react-router'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ApiError } from '../../api/coreapi'
import { log } from '../../utils/log'
import { CodeRef } from '../base/CodeRef'
import { isMergeIdResponse } from '../../utils/idUtils'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'

const MAX_CHANGES_FOR_CHERRY_PICK = 100000 // Update later according to the empiric limit - DIV-6700.

const useCherryPickAsync = () => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  return useCallback(
    async (repoId: string, pickCommitId: string, workspaceId: string) => {
      try {
        const countResponse = await RepositoryCommitManipulationService.srcHandlersv2CommitCountChanges({
          repoId,
          commitId: pickCommitId,
        })
        if (countResponse.changes_count > MAX_CHANGES_FOR_CHERRY_PICK) {
          errorToast('Cherry-picked commit has too many changes and is not currently supported', true)
          postAnalytics('CherryPickTooManyChanges', { changes_count: countResponse.changes_count.toString() })
          return
        }
        const cpResponse = await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceCherryPick({
          repoId,
          workspaceId,
          requestBody: { ref_id: pickCommitId },
        })

        if (isMergeIdResponse(cpResponse)) {
          postAnalytics('CherryPickConflicts', {})
          infoToast('Conflicts discovered during cherry-picking', true)
          navigate(routeToMerge(repoId, cpResponse.merge_id))
        } else {
          infoToast('Successfully cherry-picked changes into workspace', true)
          navigate(routeToWorkspaceEdit(repoId, workspaceId))
        }
      } catch (e) {
        if (e instanceof ApiError && e.status === 409) {
          errorToast('Cannot cherry-pick as workspace has uncommitted changes')
        } else {
          errorToast('Error occurred during cherry-pick')
          log.error('Unexpected error in cherry-pick', e)
        }
      }
    },
    [navigate, postAnalytics]
  )
}

interface CherryPickDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  commit: Commit
  repoId?: string
}

export const CherryPickDialog: React.FC<CherryPickDialogProps> = ({ isOpen, setOpen, commit, repoId }) => {
  const { workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const { refresh: refreshWorkspace } = useWorkspace(repoId, workspace?.workspace_id)
  const postAnalytics = useAnalytics()

  const cherryPickAsync = useCherryPickAsync()
  return (
    <ActionDialog
      title={`Cherry-pick ${commit.commit_id}`}
      message={
        <>
          The cherry-picked commit's message is: <CodeRef>{commit.commit_message}</CodeRef>
          <br />
          <br />
          This action will create changes on the active workspace that re-apply the changes made in the selected commit.
          <br />
          <br />
          Do you want to proceed?
        </>
      }
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={async () => {
        if (!commit) {
          return
        }
        postAnalytics('CherryPickDialogConfirmed', { commit_id: commit.commit_id })
        await cherryPickAsync(workspace?.repo_id!, commit.commit_id, workspace?.workspace_id!)
        refreshWorkspace()
      }}
      confirmButtonLabel={'Cherry-pick'}
      loadingMessage={'Applying cherry-picked changes...'}
    />
  )
}
