import React, { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { PrimaryButton } from '../base/PrimaryButton'
import { Google as GoogleIcon } from '@mui/icons-material'
import isEmpty from 'lodash/isEmpty'
import { useNavigate } from 'react-router'
import { routeToDashboard, routeToSignInPasswordReset, routeToSignUp } from '../../RouteDefinitions'
import { usePageTitle } from '../../hooks/usePageTitle'
import { callAsync } from '../../utils/callAsync'
import { FullPageLoader, Loader } from '../base/Loader'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { AuthColumn, AuthErrorText, AuthPage, StyledInput, StyledLink, SubText, useSetEmailFromQuery } from './Common'
import { signInAsync, signInWithGoogleAsync } from './actions'
import { initializeAuthSessionAsync } from '../../api/auth'
import { Auth } from 'aws-amplify'
import { log } from '../../utils/log'
import { rawNavigate, rawReplace } from '../../utils/navigateRaw'
import { buildOAuthState, didCustomConfigure, getRedirectUri, isOAuthComplete } from './configure'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { TextTitle } from '../base/TextStyle'
import { Separator } from '../base/Separator'
import { useKeyPress } from '../../hooks/useKeyPress'
import * as EmailValidator from 'email-validator'

export const refreshTokenQueryParam = 't'

export const StyledGoogleIcon = styled(GoogleIcon)`
  margin-right: 1rem;
`

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledSeparator = styled(Separator)`
  margin: 1rem 0;
`

const TitleText = styled.div`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
  text-align: center;
  width: 100%;
`

const expectedUsernamePasswordErrors = ['NotAuthorizedException', 'UserNotFoundException']

const finalizeRedirectUriForSignInOnlyMode = (redirectUri: string, session: CognitoUserSession) =>
  `${redirectUri}?${refreshTokenQueryParam}=${encodeURIComponent(session.getRefreshToken().getToken())}`

type Props = {
  inSignInOnlyMode?: boolean
}

export const SignInPage = ({ inSignInOnlyMode }: Props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = inSignInOnlyMode ? rawNavigate : useNavigate()
  const [emailOrUsername, setEmailOrUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [continuedWithEmail, setContinuedWithEmail] = useState<boolean>()
  const [loginError, setLoginError] = useState<string>()
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const defaultEmail = inSignInOnlyMode ? undefined : useSetEmailFromQuery(setEmailOrUsername)
  const onApiError = useContext(PublishApiErrorContext)
  const postAnalytics = useAnalytics()
  usePageTitle('Sign In')

  useKeyPress(
    'Enter',
    (event: KeyboardEvent) => {
      if (continuedWithEmail ? !isEmpty(emailOrUsername) && !isEmpty(password) : !isEmpty(emailOrUsername)) {
        continuedWithEmail ? loginWithCredentials() : initLoginWithCredentials(emailOrUsername!)
      }
    },
    true
  )

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        if (inSignInOnlyMode) {
          const redirectUri = getRedirectUri()
          if (!isEmpty(redirectUri)) {
            log.info('sign in flow completed, redirecting back to target', { redirectUri })
            rawNavigate(finalizeRedirectUriForSignInOnlyMode(redirectUri!, session))
          }
          return
        }
        log.info('session is valid, triggering explicit sign out')
        Auth.signOut().catch((error) => {
          log.error('failed to sign out', { error })
        })
      })
      .catch(() => {})
  }, [inSignInOnlyMode])

  const initLoginWithCredentials = (identityToUse: string) => {
    if (EmailValidator.validate(identityToUse)) {
      setEmailOrUsername(String(identityToUse).toLowerCase())
    }
    setContinuedWithEmail(true)
  }

  const loginWithCredentials = async () => {
    let identityToUse = emailOrUsername!
    if (EmailValidator.validate(emailOrUsername!)) {
      identityToUse = String(emailOrUsername).toLowerCase()
      setEmailOrUsername(identityToUse)
    }
    postAnalytics('SignInWithCredentialsClicked', { email: identityToUse })
    await callAsync(
      async () => {
        await signInAsync(identityToUse, password!)
        const redirectUri = getRedirectUri()
        if (inSignInOnlyMode) {
          if (!isEmpty(redirectUri)) {
            log.info('sign in flow completed, redirecting back to target', { redirectUri })
            const session = await Auth.currentSession()
            rawNavigate(finalizeRedirectUriForSignInOnlyMode(redirectUri!, session))
            return
          }
        }
        await initializeAuthSessionAsync()
        if (redirectUri) {
          rawReplace(decodeURIComponent(redirectUri))
        } else {
          navigate(routeToDashboard())
        }
      },
      setLoading,
      (error) => {
        if (expectedUsernamePasswordErrors.includes(error.name)) {
          setLoginError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }

  const loginWithGoogle = async () => {
    postAnalytics('SignInWithGoogleClicked', {})
    await signInWithGoogleAsync(buildOAuthState())
  }

  if (isOAuthComplete()) {
    return <FullPageLoader />
  }

  return (
    <AuthPage>
      {inSignInOnlyMode && !didCustomConfigure ? (
        <AuthErrorText>Invalid page context</AuthErrorText>
      ) : (
        <>
          <AuthColumn>
            <TitleText>Sign in to Diversion</TitleText>
            <div />
            {!continuedWithEmail && (
              <>
                <StyledButton disabled={false} onClick={loginWithGoogle}>
                  <StyledGoogleIcon /> Continue with Google
                </StyledButton>
                <StyledSeparator />
              </>
            )}
            <StyledInput
              autoFocus
              type="text"
              placeholder="name@host"
              autoComplete="username"
              defaultValue={defaultEmail}
              value={emailOrUsername}
              onChange={(e) => {
                setLoginError(undefined)
                setEmailOrUsername(e.target.value)
              }}
            />
            {continuedWithEmail && (
              <StyledInput
                type="password"
                placeholder="Password"
                autoComplete="current-password"
                onChange={(e) => {
                  setLoginError(undefined)
                  setPassword(e.target.value)
                }}
              />
            )}
            {!inSignInOnlyMode && continuedWithEmail && (
              <SubText>
                <StyledLink onClick={() => navigate(routeToSignInPasswordReset())}>Forgot your password?</StyledLink>
              </SubText>
            )}
            {loading ? (
              <Loader />
            ) : (
              <StyledButton
                disabled={continuedWithEmail ? isEmpty(emailOrUsername) || isEmpty(password) : isEmpty(emailOrUsername)}
                onClick={() =>
                  continuedWithEmail ? loginWithCredentials() : initLoginWithCredentials(emailOrUsername!)
                }
              >
                {continuedWithEmail ? 'Sign in' : 'Continue with email'}
              </StyledButton>
            )}
            {loginError && <AuthErrorText>{loginError}</AuthErrorText>}
            {!inSignInOnlyMode && (
              <div>
                Need an account? <StyledLink onClick={() => navigate(routeToSignUp())}>Sign up</StyledLink>
              </div>
            )}
          </AuthColumn>
        </>
      )}
    </AuthPage>
  )
}
