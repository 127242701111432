import React, { ReactNode } from 'react'
import { Divider, MenuItem, Select, SelectProps } from '@mui/material'
import styled from '@emotion/styled'
import { TextRegular } from './TextStyle'

const StyledMenuItem = styled(MenuItem)`
  ${TextRegular}
`

const StyledDivider = styled(Divider)`
  margin: 0.5rem 0;
`

export type SelectOption = {
  value: string
  label: ReactNode
}

export type OptionGroup = {
  label: string
  options: SelectOption[]
}

type CustomSelectProps = SelectProps & {
  options: Array<SelectOption | OptionGroup>
  hasGroups?: boolean
}

export const CustomSelect = ({ options, hasGroups = false, ...selectProps }: CustomSelectProps) => {
  const defaultMenuProps = {
    disableScrollLock: true,
    BackdropProps: {
      invisible: true,
      style: { backgroundColor: 'transparent', opacity: 0 },
    },
    PaperProps: {
      elevation: 4,
      sx: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      },
    },
  }

  const menuProps = {
    ...defaultMenuProps,
    ...selectProps.MenuProps,
  }

  // Ensure options is a valid array
  const optionsArray = Array.isArray(options) ? options : []

  const renderOptions = () => {
    if (hasGroups) {
      const normalOptions: ReactNode[] = []
      const groupOptions: ReactNode[] = []

      optionsArray.forEach((option) => {
        if ('options' in option) {
          // This is a group
          groupOptions.push(
            <StyledDivider key={`divider-${option.label}`} textAlign="left">
              {option.label}
            </StyledDivider>
          )

          option.options.forEach((subOption) => {
            groupOptions.push(
              <StyledMenuItem key={subOption.value} value={subOption.value}>
                {subOption.label}
              </StyledMenuItem>
            )
          })
        } else {
          // This is a normal option
          normalOptions.push(
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          )
        }
      })

      return [...normalOptions, ...groupOptions]
    } else {
      // For flat options list
      return (optionsArray as SelectOption[]).map((option) => (
        <StyledMenuItem key={option.value} value={option.value}>
          {option.label}
        </StyledMenuItem>
      ))
    }
  }

  return (
    <Select
      {...selectProps}
      MenuProps={menuProps}
      sx={{
        '& .MuiSelect-select': {
          fontFamily: 'canada-type-gibson, sans-serif',
          fontSize: '0.95rem',
          fontWeight: 400,
          padding: '0.4rem 0.8rem',
        },
        ...selectProps.sx,
      }}
    >
      {renderOptions()}
    </Select>
  )
}
