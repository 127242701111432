import { Loader } from '../base/Loader'
import { FileTitleLine } from './FileTitleLine'
import { GenericFileEditor } from './GenericFileEditor'
import { FileState } from '../../models/fileState'
import { useLocalStorage } from 'usehooks-ts'

type Props = {
  loading?: boolean
  repoId: string
  file?: FileState
  baseFile?: FileState
  forceDiffView?: boolean
  hasParentRelationship: boolean
  showFileHistory: boolean
  editorBottomPadding?: number
}

export const TitledGenericFileEditor = ({
  loading,
  file,
  baseFile,
  forceDiffView,
  hasParentRelationship,
  showFileHistory,
  editorBottomPadding,
}: Props) => {
  const [isDiffView, setIsDiffView] = useLocalStorage<boolean>(
    `editor.diffView.${forceDiffView}}`,
    Boolean(forceDiffView)
  )
  const canDiff = Boolean(!forceDiffView && file?.isDiffable && (!baseFile || baseFile.isDiffable))
  return (
    <>
      {loading || !file ? (
        <Loader addPadding />
      ) : (
        <>
          <FileTitleLine
            file={file}
            baseFile={baseFile}
            allowDiffSwitch={canDiff}
            isDiffView={isDiffView}
            setDiffView={setIsDiffView}
            hasParentRelationship={hasParentRelationship}
            showFileHistory={showFileHistory}
          />
          <GenericFileEditor
            file={file}
            baseFile={baseFile}
            isDiffView={isDiffView}
            editorBottomPadding={editorBottomPadding}
            readOnly
          />
        </>
      )}
    </>
  )
}
