import { useApi } from '../useApi'
import { RepositoryWorkspaceManipulationService, Workspace } from '../../api/coreapi'
import { PLACEHOLDER_FOR_NO_PARENT } from '../../utils/commitParent'
import { getIsUserSideFailure } from '../../utils/errorClassify'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import {
  getWorkspaceWithAgentJournalId,
  WorkspaceWithJournalIdOn,
} from '../../desktop/components/utils/GetWorkspaceWithAgentJournalId'

export function useWorkspace(
  repoId: string | undefined,
  workspaceId: string | undefined
): {
  workspace?: Workspace
  workspaceLoading: boolean
  failed: boolean
  refresh: () => Promise<void>
} {
  const {
    data: workspace,
    loading: workspacesLoading,
    error,
    refresh,
  } = useApi<Workspace>(
    ['repos', repoId, 'workspace', workspaceId],
    repoId && workspaceId
      ? () => {
          if (IsDesktopApp() && WorkspaceWithJournalIdOn) {
            return getWorkspaceWithAgentJournalId(workspaceId, repoId)
          } else {
            return RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetWorkspace({
              repoId,
              workspaceId,
            })
          }
        }
      : null
  )
  return {
    workspace,
    workspaceLoading: workspacesLoading,
    failed: error && !getIsUserSideFailure(error).isUserSideFailure,
    refresh,
  }
}

export const getWorkspaceBaseCommitId = (workspace?: Workspace) => {
  if (!workspace) {
    return undefined
  }
  return workspace.base_commit_id || PLACEHOLDER_FOR_NO_PARENT
}
