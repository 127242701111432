import { useCallback, useMemo, useState } from 'react'
import { Box, Typography, Chip, Button, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Table, Column } from '../base/Table'
import { Add, Menu } from '@mui/icons-material'
import { useMembers } from '../../hooks/api/useMembers'
import { AccessMode, Organization, OrganizationMemberData } from '../../api/coreapi'
import { useOrganizations } from '../../hooks/api/useOrganizations'
import { useOrganizationSelection } from '../../hooks/useOrganizationSelection'
import { MemberNameCell } from './MemberNameCell'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { RemoveMemberDialog } from '../dialogs/RemoveMemberDialog'
import { OrganizationInviteDialog } from '../dialogs/OrganizationInviteDialog'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { useOrganizationMember } from '../../hooks/api/useOrganizationMember'
import { FlexRow } from '../base/Flex'
import { OrgSelector } from './OrgSelector'
import { StyledSkeletonCell } from '../base/SkeletonTableCell'
import { StyledSkeleton } from '../base/SkeletonTableCell'
import { OrganizationInviteRepoDialog } from '../dialogs/OrganizationInviteRepoDialog'
import { ChangeRoleDialog } from '../dialogs/ChangeRoleDialog'

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.colors.black.primary,
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}))

const SubHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

type TableMember = {
  id: string
  email: string
  fullName: string
  image?: string
  role: string
}

const selectRole = (role: AccessMode) => {
  switch (role) {
    case AccessMode.ADMIN:
      return 'ADMIN'
    case AccessMode.OWNER:
      return 'OWNER'
    case AccessMode.READ:
      return 'MEMBER'
    case AccessMode.WRITE:
      return 'MEMBER'
  }
}

const selectMember = (member: OrganizationMemberData): TableMember => ({
  id: member.user.id,
  email: member.user.email || '',
  fullName: member.user.full_name || '',
  image: member.user.image || '',
  role: selectRole(member.member.role),
})

export const MembersRoute = () => {
  const { data: user } = useUserInfo()
  const [page, setPage] = useState(0)
  const { organizations } = useOrganizations()
  const { currentOrg, setCurrentOrgId, hasMultipleOrganizations } = useOrganizationSelection(organizations)

  const {
    members: apiMembers,
    loading: isMembersLoading,
    total: totalMembers,
    refresh: refreshMembers,
  } = useMembers(currentOrg?.id, page)
  const { isOrgAdmin } = useOrganizationMember(currentOrg?.id, user?.id)

  const tableMembers = useMemo(() => apiMembers.map(selectMember), [apiMembers])
  const [currentMember, setCurrentMember] = useState<TableMember | null>(null)
  const [isInviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [isInviteToReposDialogOpen, setInviteToReposDialogOpen] = useState(false)
  const [invitedEmail, setInvitedEmail] = useState('')
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false)
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] = useState(false)
  const handleOrgSelect = useCallback(
    (org: Organization) => {
      setCurrentOrgId(org.id)
      setPage(0)
      refreshMembers()
    },
    [setCurrentOrgId, refreshMembers]
  )

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage)
      refreshMembers()
    },
    [refreshMembers]
  )

  const handleRemoveMemberClose = useCallback(() => {
    setCurrentMember(null)
    refreshMembers()
  }, [refreshMembers])

  const onSelected = useCallback((member: TableMember, setDialogOpen: (value: boolean) => void) => {
    setCurrentMember(member)
    setDialogOpen(true)
  }, [])

  const columns = useMemo<Column<TableMember>[]>(
    () => [
      {
        id: 'name',
        label: 'Name',
        render: (row) => <MemberNameCell image={row.image} fullName={row.fullName} email={row.email} />,
        width: '60%',
      },
      {
        id: 'role',
        label: 'Role',
        render: (row) => <Chip label={row.role} color={row.role === 'OWNER' ? 'primary' : 'default'} size="medium" />,
        width: isOrgAdmin ? '30%' : '40%',
      },

      ...(isOrgAdmin
        ? [
            {
              id: 'actions',
              label: '',
              render: (row: TableMember) => (
                <OptionsDropdown
                  button={
                    <IconButton color="primary">
                      <Menu />
                    </IconButton>
                  }
                  items={[
                    ...(row.role !== 'OWNER'
                      ? [
                          {
                            key: 'change-role',
                            title: 'Change role',
                            onSelected: () => onSelected(row, setIsChangeRoleDialogOpen),
                          },
                        ]
                      : []),
                    {
                      key: 'remove',
                      title: 'Remove member',
                      onSelected: () => onSelected(row, setIsRemoveMemberDialogOpen),
                      disabled: row.role === 'OWNER',
                      tooltip: 'Cannot remove the owner of the organization',
                    },
                  ]}
                  arrow={false}
                />
              ),
              width: '10%',
              align: 'right' as const,
            },
          ]
        : []),
    ],
    [isOrgAdmin, onSelected]
  )

  return (
    <Container>
      <Header>
        <SubHeader>
          <StyledHeader variant="h5">Members of {currentOrg?.name}</StyledHeader>
          <FlexRow gap={2}>
            {hasMultipleOrganizations && (
              <OrgSelector organizations={organizations} currentOrg={currentOrg} onOrgSelect={handleOrgSelect} />
            )}
            {isOrgAdmin && (
              <Button variant="contained" startIcon={<Add />} onClick={() => setInviteDialogOpen(true)}>
                Invite Member
              </Button>
            )}
          </FlexRow>
        </SubHeader>
      </Header>
      <Table
        columns={columns}
        data={tableMembers}
        isLoading={isMembersLoading}
        page={page}
        totalCount={totalMembers}
        onPageChange={handlePageChange}
        skeletonCell={
          <StyledSkeletonCell colSpan={columns.length} align="left">
            <StyledSkeleton variant="circular" width={40} height={40} />
            <StyledSkeleton variant="text" width="30%" />
          </StyledSkeletonCell>
        }
      />
      <RemoveMemberDialog
        orgId={currentOrg?.id || ''}
        orgName={currentOrg?.name || ''}
        userId={currentMember?.id || ''}
        userName={currentMember?.fullName || ''}
        userEmail={currentMember?.email || ''}
        isOpen={isRemoveMemberDialogOpen}
        setIsOpen={(open) => !open && setIsRemoveMemberDialogOpen(false)}
        afterRemove={handleRemoveMemberClose}
      />
      <OrganizationInviteDialog
        isOpen={isInviteDialogOpen}
        setOpen={setInviteDialogOpen}
        orgId={currentOrg?.id || ''}
        orgName={currentOrg?.name || ''}
        invitedEmail={invitedEmail}
        setInvitedEmail={setInvitedEmail}
        afterInvite={() => {
          refreshMembers()
          setInviteToReposDialogOpen(true)
        }}
      />
      <OrganizationInviteRepoDialog
        isOpen={isInviteToReposDialogOpen}
        setOpen={setInviteToReposDialogOpen}
        orgId={currentOrg?.id || ''}
        invitedEmail={invitedEmail}
        onClose={() => {
          setInvitedEmail('')
        }}
      />
      <ChangeRoleDialog
        isOpen={isChangeRoleDialogOpen}
        setOpen={setIsChangeRoleDialogOpen}
        orgId={currentOrg?.id || ''}
        userId={currentMember?.id || ''}
        memberName={currentMember?.fullName || currentMember?.email || ''}
        currentRole={currentMember?.role || ''}
        refreshMembers={refreshMembers}
      />
    </Container>
  )
}
