import { SidePanelTabs } from './tabs/Tabs'
import { WorkspacePanelWrapper } from './workspace/WorkspacePanelWrapper'
import { SidePanel } from './SidePanel'
import { useState } from 'react'
import { FileStatusInOtherRefsDialog, OtherRefsFileStatusContext } from '../dialogs/FileStatusInOtherRefsDialog'
import { RefsFileStatus } from '../../api/coreapi'

type Props = {
  repoId: string
  workspaceId: string
  hasOutletContent: boolean
  selectedFilePath?: string
}

export const WorkspaceEditSidePanel = ({ repoId, workspaceId, hasOutletContent, selectedFilePath }: Props) => {
  const [fileStatusInOtherWorkspacesDialogIsOpen, setFileStatusInOtherWorkspacesDialogIsOpen] = useState<boolean>(false)
  const [refsFileStatus, setRefsFileStatus] = useState<RefsFileStatus>()

  return (
    <>
      <FileStatusInOtherRefsDialog
        repoId={repoId}
        isOpen={fileStatusInOtherWorkspacesDialogIsOpen}
        onClose={() => {
          setFileStatusInOtherWorkspacesDialogIsOpen(false)
          setRefsFileStatus(undefined)
        }}
        refsFileStatus={refsFileStatus}
      />
      <OtherRefsFileStatusContext.Provider
        value={{
          refsFileStatus,
          setFileStatus: (refsFileStatus) => {
            setRefsFileStatus(refsFileStatus)
            setFileStatusInOtherWorkspacesDialogIsOpen(true)
          },
        }}
      >
        <SidePanel contextName={'workspacePanel'} hasOutletContent={hasOutletContent}>
          <SidePanelTabs selectedMode="edit" repoId={repoId} workspaceId={workspaceId} />
          <WorkspacePanelWrapper repoId={repoId} workspaceId={workspaceId} selectedFilePath={selectedFilePath} />
        </SidePanel>
      </OtherRefsFileStatusContext.Provider>
    </>
  )
}
