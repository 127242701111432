import { Styleable } from '../../theme'
import styled from '@emotion/styled'
import { Icon } from 'material-file-icons'
import { capitalize } from '../../utils/textUtils'
import { TextFileEditor } from './TextFileEditor'
import { VerticalSeparator } from '../base/Separator'
import isNil from 'lodash/isNil'
import { Loader } from '../base/Loader'
import { DynamicImage } from './DynamicImage'
import { notNil } from '../../utils/objectUtil'
import { getFileName } from '../../utils/pathUtils'
import { FileState } from '../../models/fileState'
import { ThreeViewer } from '../base/ThreeViewer'
import { useRef } from 'react'
import { FlexColumn, FlexRow } from '../base/Flex'

type Props = Styleable & {
  file?: FileState
  baseFile?: FileState
  isDiffView: boolean
  readOnly: boolean
  onChange?: (text: string) => void
  editorBottomPadding?: number
}

const NotTextOrImage = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.black.secondary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ theme }) => theme.padding.l}rem;
  gap: 1rem;
`

const ImagesContainer = styled(FlexRow)`
  min-height: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue.light};
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
`

const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const StyledIcon = styled.div`
  height: 4rem;
  width: 4rem;
  filter: grayscale(0.8);

  :hover {
    filter: none;
  }
`

const FileIcon = ({ icon }: { icon: Icon }) => (
  <StyledIcon dangerouslySetInnerHTML={{ __html: icon.svg }} title={capitalize(icon.name)} />
)

const getNoPreviewMessage = (isEmpty: boolean, isTooLargeToPreview: boolean, notExistsOnServer: boolean) => {
  if (notExistsOnServer) {
    return 'File was deleted'
  }
  if (isEmpty) {
    return 'File is empty'
  }
  if (isTooLargeToPreview) {
    return 'File is too large to preview'
  }
  return 'Preview is not available'
}

const canPreviewFile = (file?: FileState) =>
  !file || (!file.isFileTooLarge && (file.isTextFile || file.isImage || file.is3dModel))

export const GenericFileEditor = ({
  className,
  file,
  baseFile,
  isDiffView,
  readOnly,
  onChange,
  editorBottomPadding,
}: Props) => {
  const showFileIsEmptyMessage = !isDiffView && (!file || file?.content?.length === 0)
  const notExistsOnServer = !isDiffView && file?.existsOnServer === false
  const isFileTooLarge = Boolean(file?.isFileTooLarge || baseFile?.isFileTooLarge)
  const canPreview = canPreviewFile(file) && canPreviewFile(baseFile)
  const baseContainerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  if (!canPreview || showFileIsEmptyMessage) {
    return (
      <NotTextOrImage className={className}>
        <div>{getNoPreviewMessage(showFileIsEmptyMessage, isFileTooLarge, notExistsOnServer)}</div>
        {file?.fileIcon && <FileIcon icon={file.fileIcon} />}
      </NotTextOrImage>
    )
  }
  if (file?.isImage || file?.is3dModel) {
    const fileName = getFileName(file.filePath)
    return (
      <ImagesContainer title={fileName}>
        {isDiffView && baseFile && (
          <>
            <ImageContainer ref={baseContainerRef}>
              {file.isImage && <DynamicImage file={baseFile} fileName={fileName} />}
              {file.is3dModel && <ThreeViewer blob={baseFile.blob!!} parentRef={baseContainerRef} />}
            </ImageContainer>
            <VerticalSeparator />
          </>
        )}
        <ImageContainer ref={containerRef}>
          {file.isImage && <DynamicImage file={file} fileName={fileName} />}
          {file.is3dModel && <ThreeViewer blob={file.blob!!} parentRef={containerRef} />}
        </ImageContainer>
      </ImagesContainer>
    )
  }
  return (
    <>
      {notNil(file?.content) && (isNil(baseFile) || notNil(baseFile?.content)) ? (
        <TextFileEditor
          className={className}
          text={file!.content!}
          baseText={baseFile?.content}
          isDiffView={isDiffView}
          filePath={file!.filePath}
          uniqueId={file?.contentHash || ''}
          readOnly={readOnly}
          onChange={onChange}
          editorBottomPadding={editorBottomPadding}
        />
      ) : (
        <Loader addPadding />
      )}
    </>
  )
}
