import Dialog from '@mui/material/Dialog'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { Loader } from '../base/Loader'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { TextBold, TextTitle } from '../base/TextStyle'
import { useKeyPress } from '../../hooks/useKeyPress'
import * as KeyCode from 'keycode-js'
import { Tooltip } from '@mui/material'

const Message = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const Title = styled(Message)`
  ${TextBold};
`

const InputError = styled.span`
  color: ${({ theme }) => theme.colors.red.primary};
`

const Description = styled.div`
  a {
    color: ${({ theme }) => theme.colors.blue.primary};
    :hover {
      text-decoration: underline;
    }
  }
`

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 1rem;
  :disabled {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

type Props = {
  height?: string
  title: string
  description?: ReactNode
  isOpen: boolean
  onClose: () => void
  saveLoading: boolean
  saveLoadingMessage: string
  inputPlaceholder: string
  input: string
  setInput: (input: string) => void
  createActionName: string
  createDisabled: boolean
  createButtonTooltip?: string
  handleCreate: () => void
  inputError?: string
}

export const InputActionDialog = ({
  height,
  title,
  description,
  isOpen,
  onClose,
  saveLoading,
  saveLoadingMessage,
  inputPlaceholder,
  input,
  setInput,
  createActionName,
  createDisabled,
  createButtonTooltip,
  handleCreate,
  inputError,
}: Props) => {
  useKeyPress(KeyCode.CODE_ENTER, handleCreate, isOpen && !createDisabled)
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent centerText={saveLoading} maxHeight={height}>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {saveLoading ? (
          <>
            <Loader />
            <Message>{saveLoadingMessage}</Message>
          </>
        ) : (
          <StyledInput
            autoFocus
            type="text"
            defaultValue={input}
            placeholder={inputPlaceholder}
            onChange={(event) => setInput(event.target.value || '')}
          />
        )}
        {inputError && <InputError>{inputError}</InputError>}
      </StyledDialogContent>
      {!saveLoading && (
        <StyledDialogActions>
          <Tooltip title={createButtonTooltip || ''} arrow>
            <PrimaryButton disabled={createDisabled} onClick={handleCreate}>
              {createActionName}
            </PrimaryButton>
          </Tooltip>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
