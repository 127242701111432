import { AntTree } from './AntTree'
import styled from '@emotion/styled'
import { TREE_LEFT_MARGIN_REM } from '../../theme'
import { TextRegular } from '../base/TextStyle'
import { HIDE_INTACT_CLASSNAME } from './useHideIntact'

export const StyledAntTree = styled(AntTree)`
  ${TextRegular};
  background-color: transparent;
  margin-left: ${TREE_LEFT_MARGIN_REM}rem;

  .ant-tree-switcher {
    background-color: transparent;
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.blue.primary};
    border-color: ${({ theme }) => theme.colors.blue.primary};
  }

  .ant-tree-checkbox {
    margin-right: 0.2rem;
  }

  .ant-tree-checkbox + span {
    padding: 0;
  }

  .ant-tree-node-selected {
    background-color: unset !important;
  }

  .ant-tree-node-content-wrapper:hover {
    background-color: unset !important;
  }

  .ant-tree-switcher-icon {
    color: ${({ theme }) => theme.colors.black.secondary};
  }

  .ant-tree-switcher-loading-icon {
    color: ${({ theme }) => theme.colors.blue.primary};
  }

  .${HIDE_INTACT_CLASSNAME} {
    display: none;
  }
`
