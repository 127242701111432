import { ErrorInfo, useEffect, useMemo, useState } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { Auth, Hub } from 'aws-amplify'
import { initializeAuthSessionAsync } from './api/auth'
import { GlobalStyle, muiDarkTheme, muiLightTheme } from './theme'
import { ThemeProvider } from '@mui/material'
import 'material-react-toastify/dist/ReactToastify.css'
import ToastContainer from './components/ToastContainer'
import { useApi } from './hooks/useApi'
import {
  routeToDashboard,
  routeToDesktopLogin,
  routeToDesktopLogout,
  routeToPending,
  routeToSignIn,
  routeToSignInPasswordResetVerifyWithoutQueryParams,
  routeToSignUp,
  routeToSignUpVerifyWithoutQueryParams,
  routeToWelcome,
} from './RouteDefinitions'
import { AppRoutes } from './Routes'
import { FullPageLoader } from './components/base/Loader'
import { log } from './utils/log'
import { ErrorBoundary } from 'react-error-boundary'
import { FullErrorPage } from './components/base/Error'
import { errorToast, infoToast } from './utils/toast'
import config, { exposeDebugInfo } from './env/config'
import { InMergesContext } from './components/merge/MergesRoute'
import { Revalidator, RevalidatorOptions, SWRConfig, useSWRConfig } from 'swr'
import { Global } from '@emotion/react'
import { sentry } from './env/sentry'
import {
  getIsUserSideFailure,
  isTokenExpiredError,
  isTokenOutdatedError,
  userFailureMessage,
  UserFailureType,
} from './utils/errorClassify'
import { setFavicon } from './utils/setFavicon'
import { rawNavigate, rawReload, rawReplace } from './utils/navigateRaw'
import { InDetachedCommitContext } from './components/commit/DetachedCommitRoute'
import { useLocalStorage, useSessionStorage } from 'usehooks-ts'
import { useAnalyticsRoot } from './hooks/api/useAnalytics'
import { SignInPage } from './components/auth/SignInPage'
import { IsDesktopApp } from './desktop/components/utils/DesktopAppApi'
import { useIsDarkTheme } from './hooks/useIsDarkTheme'
import { sanitizePath } from './utils/pathUtils'
import { PublishApiErrorContext } from './contexts/ErrorContext'

export const App = () => {
  const skipAuthCheck = config.AUTH_TOKEN_MODE
  const inAuthPage = useMemo(
    () =>
      [
        routeToSignUp(),
        routeToSignIn(),
        routeToSignUpVerifyWithoutQueryParams(),
        routeToSignInPasswordResetVerifyWithoutQueryParams(),
        routeToDesktopLogin(),
        routeToDesktopLogout(),
      ].includes(sanitizePath(window.location.pathname)),
    []
  )
  const { data: authLoaded } = useApi(
    `session?skipAuthCheck=${skipAuthCheck}`,
    async () => (skipAuthCheck ? { authLoaded: false } : await initializeAuthSessionAsync()),
    true
  )
  const [inMergesContext, setInMergesContext] = useState(false)
  const [inDetachedCommitContext, setInDetachedCommitContext] = useState(false)
  const { onErrorRetry: swrOnErrorRetry } = useSWRConfig()
  const [wasForcedRefreshed, setForcedRefreshed] = useSessionStorage<boolean>('errorHandling.forcedRefresh', false)
  const [isFirstLogin, setFirstLogin] = useLocalStorage('session.firstLogin', true)
  const [isDarkTheme] = useIsDarkTheme()
  useEffect(() => setFavicon(), [])

  useEffect(() => {
    if (!skipAuthCheck && authLoaded && isFirstLogin && !IsDesktopApp()) {
      log.info('navigating to welcome as this is first sign in', { pathname: window.location.pathname })
      setFirstLogin(false)
      rawNavigate(routeToWelcome())
    }
  }, [authLoaded, isFirstLogin, setFirstLogin, skipAuthCheck])

  useEffect(() => {
    return Hub.listen('auth', ({ payload: { event, data } }) => {
      log.info('auth-event', { event, data })
      switch (event) {
        case 'customOAuthState':
          if (config.AUTH_TOKEN_MODE) {
            rawReplace(data)
          } else {
            const decodedData = decodeURIComponent(data)
            rawNavigate(decodedData)
          }
          break
        case 'autoSignIn':
          initializeAuthSessionAsync().then(() => rawNavigate(routeToDashboard()))
          break
      }
    })
  }, [])

  const onApiError = (error: any, key?: string) => {
    if (isTokenExpiredError(error)) {
      return
    }
    if (isTokenOutdatedError(error)) {
      Auth.signOut().then(() => initializeAuthSessionAsync())
      return
    }
    const { isUserSideFailure, userFailureType } = getIsUserSideFailure(error)
    if (isUserSideFailure) {
      log.info('api request user failure', { error, key, userFailureType })
      if (userFailureType === UserFailureType.ReloadRequired && !wasForcedRefreshed) {
        setForcedRefreshed(true)
        rawReload()
        return
      }
      if (userFailureType === UserFailureType.UserDisabled) {
        rawNavigate(routeToPending())
        return
      }
      if (userFailureType === UserFailureType.Network) {
        errorToast(userFailureMessage(userFailureType))
        return
      }
      userFailureType && infoToast(userFailureMessage(userFailureType))
      if (userFailureType === UserFailureType.ResourceNotFound) {
        rawReplace(routeToDashboard())
      }
      return
    }
    log.warn(`api request failed - ${key}`, { error, key })
    errorToast(exposeDebugInfo() ? error.message : undefined)
    sentry.captureException(error, key ? { apiKey: key } : {}, {})
  }

  const onErrorRetry = (
    error: any,
    key: string,
    config: any,
    revalidate: Revalidator,
    revalidateOpts: Required<RevalidatorOptions>
  ) => {
    if (isTokenExpiredError(error)) {
      initializeAuthSessionAsync().then((authenticated) => {
        if (authenticated) {
          revalidate(revalidateOpts)
        }
      })
    } else if (!getIsUserSideFailure(error).isUserSideFailure) {
      swrOnErrorRetry(error, key, config, revalidate, revalidateOpts)
    }
  }

  const onRenderError = (error: Error, info: ErrorInfo) => {
    log.warn('render failed', error, info)
    sentry.captureException(error, {}, { ...info })
  }

  const postAnalytics = useAnalyticsRoot(onApiError)
  useEffect(() => {
    postAnalytics('WebLoaded', {
      authenticated: Boolean(authLoaded).toString(),
      is_first_login: isFirstLogin.toString(),
    })
  }, [authLoaded, isFirstLogin, postAnalytics])

  return (
    <ThemeProvider theme={isDarkTheme && !inAuthPage ? muiDarkTheme : muiLightTheme}>
      <Global styles={GlobalStyle} />
      <PublishApiErrorContext.Provider value={onApiError}>
        <SWRConfig value={{ onError: onApiError, onErrorRetry: onErrorRetry }}>
          <InMergesContext.Provider value={{ inMergesContext, setInMergesContext }}>
            <InDetachedCommitContext.Provider value={{ inDetachedCommitContext, setInDetachedCommitContext }}>
              <ErrorBoundary FallbackComponent={FullErrorPage} onError={onRenderError}>
                {config.AUTH_TOKEN_MODE ? (
                  <SignInPage inSignInOnlyMode />
                ) : authLoaded || inAuthPage ? (
                  <AppRoutes />
                ) : (
                  <FullPageLoader />
                )}
              </ErrorBoundary>
              <ToastContainer limit={3} />
            </InDetachedCommitContext.Provider>
          </InMergesContext.Provider>
        </SWRConfig>
      </PublishApiErrorContext.Provider>
    </ThemeProvider>
  )
}
