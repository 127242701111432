import styled from '@emotion/styled'
import { OptionsDropdown } from './OptionsDropdown'
import { FlexRow } from '../base/Flex'
import { menuAction } from './HoveredDropdown'

const ContextMenuContainer = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
`

type ContextMenuProps = {
  actions: Record<string, menuAction>
  position: { x: number; y: number } | null
  onClose: () => void
}

export const ContextMenu = ({ actions, position, onClose }: ContextMenuProps) => {
  if (!position) return null

  return (
    <ContextMenuContainer x={position.x} y={position.y}>
      <OptionsDropdown
        items={Object.entries(actions).map(([key, entryProps]) => ({
          key,
          title: (
            <FlexRow gap={0.5} centered={true}>
              {entryProps.icon}
              {key}
            </FlexRow>
          ),
          onSelected: () => {
            entryProps.action()
            onClose()
          },
        }))}
        open={!!position}
        onOpenChanged={(open) => !open && onClose()}
      />
    </ContextMenuContainer>
  )
}
