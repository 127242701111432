import { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
import CodeOutlined from '@mui/icons-material/CodeOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import WavingHandIcon from '@mui/icons-material/WavingHand'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ChatIcon from '@mui/icons-material/Chat'
import BillingIcon from '@mui/icons-material/Payment'
import { signOutAsync } from '../../api/auth'
import { Loader } from '../base/Loader'
import { UserAvatar } from '../base/UserAvatar'
import { Styleable } from '../../theme'
import { TextBold } from '../base/TextStyle'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { useNavigate } from 'react-router'
import {
  routeToApiTokens,
  routeToBilling,
  routeToMembers,
  routeToProfile,
  routeToWelcome,
} from '../../RouteDefinitions'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { openInNewTab, rawNavigate } from '../../utils/navigateRaw'
import config from '../../env/config'
import { AnalyticsCallback, useAnalytics } from '../../hooks/api/useAnalytics'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { AccountService, BillingSession, DefaultService, Tier } from '../../api/coreapi'
import { errorToast } from '../../utils/toast'
import { log } from '../../utils/log'
import { useIsDarkTheme } from '../../hooks/useIsDarkTheme'
import { Switch } from '@mui/material'
import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'
import { SetUserTierDialog } from '../dialogs/SetUserTierDialog'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { openInExternalBrowser } from '../../desktop/components/utils/openInExternalBrowser'
import { sanitizeUrl } from '../../utils/url'
import { Person } from '@mui/icons-material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { useOrganizations } from '../../hooks/api/useOrganizations'
import { pluralize } from '../../utils/textUtils'

const Bold = styled.span`
  ${TextBold};
`

type Item = {
  key: string
  title: ReactNode
  onSelect?: (analyticCallback: AnalyticsCallback, navigate: NavigateFunction) => void
  disabled?: boolean
  isDivider?: boolean
  keepOpenAfterSelect?: boolean
}

const ItemRow = ({ text, icon }: { text: string; icon: ReactNode }) => (
  <FlexRow gap={0.5} centered={true}>
    {icon}
    {text}
  </FlexRow>
)

const SignedInAsSection = styled(FlexColumn)`
  color: ${({ theme }) => theme.colors.blue.contrastText};
`

export const LoggedInUserAvatar = ({ className }: Styleable) => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  const [userTierDialogIsOpen, setUserTierDialogIsOpen] = useState(false)
  const [isDarkTheme, setIsDarkTheme] = useIsDarkTheme()
  const { data: userInfo, loading } = useUserInfo()
  const { organizations } = useOrganizations()
  const isOrganizationOwner = organizations.some((org) => org.owner_id === userInfo?.id)

  const isBillingSession = (session: any): session is BillingSession =>
    session.location !== undefined && session.location !== ''
  const items: Item[] = [
    {
      key: 'name',
      title: (
        <SignedInAsSection centered={true}>
          <div>Signed in as</div>
          <Bold>{userInfo?.full_name || userInfo?.email}</Bold>
        </SignedInAsSection>
      ),
      disabled: true,
    },
    {
      key: 'darkmode',
      title: (
        <FlexRow centered={true} gap={0.5}>
          {isDarkTheme ? <DarkMode /> : <LightMode />}
          Dark mode
          <FlexFiller />
          <Switch checked={isDarkTheme} color="default" onChange={() => setIsDarkTheme(!isDarkTheme)} />
        </FlexRow>
      ),
      onSelect: () => setIsDarkTheme(!isDarkTheme),
      keepOpenAfterSelect: true,
    },
    {
      key: 'divider1',
      title: '',
      isDivider: true,
    },
    {
      key: 'profile',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <Person />
          Your profile
        </FlexRow>
      ),
      onSelect: (_, navigate) => navigate(routeToProfile()),
    },
    ...(organizations.length > 0
      ? [
          {
            key: 'organization',
            title: (
              <FlexRow gap={0.5} centered={true}>
                <CorporateFareIcon />
                Your {pluralize(organizations.length, 'organization', 's', false)}
              </FlexRow>
            ),
            onSelect: (_: AnalyticsCallback, navigate: NavigateFunction) => navigate(routeToMembers()),
          },
        ]
      : []),
    ...(isOrganizationOwner || organizations.length === 0
      ? [
          {
            key: 'billing',
            title: (
              <FlexRow gap={0.5} centered={true}>
                <BillingIcon />
                Plan & Billing
              </FlexRow>
            ),
            onSelect: async () => {
              // Open the tier dialog if the user has not set their tier, or we didn't successfully fetch the user info
              if (!userInfo || userInfo?.tier === Tier.UNKNOWN) {
                setUserTierDialogIsOpen(true)
              } else {
                const returnLocation = window.location.href
                const pathname = window.location.pathname
                navigate(routeToBilling())

                try {
                  const billingService = await AccountService.srcHandlersv2AccountGetBillingSession({
                    returnUrl: returnLocation,
                  })
                  if (isBillingSession(billingService)) {
                    rawNavigate(billingService.location)
                  } else {
                    throw new Error('Failed to load billing page')
                  }
                } catch (e) {
                  log.error(e)
                  navigate(pathname, { replace: true })
                  errorToast('Failed loading billing page. Please contact support.')
                }
              }
            },
          },
        ]
      : []),
    {
      key: 'integrations',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <CodeOutlined />
          Integrations
        </FlexRow>
      ),
      onSelect: (_, navigate) =>
        IsDesktopApp()
          ? openInExternalBrowser(`${sanitizeUrl(config.WEBAPP_URL!)}${routeToApiTokens()}`)
          : navigate(routeToApiTokens()),
    },
    {
      key: 'welcome',
      title: <ItemRow text="Welcome Page" icon={<WavingHandIcon />} />,
      onSelect: (_, navigate) => navigate(routeToWelcome()),
    },
    {
      key: 'docs',
      title: <ItemRow text="Documentation" icon={<MenuBookIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.QUICKSTART_LINK, analyticCallback),
    },
    {
      key: 'feature-request',
      title: <ItemRow text="Feature Request" icon={<ArrowCircleUpIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.FEATURE_REQUEST_LINK, analyticCallback),
    },
    {
      key: 'contact',
      title: <ItemRow text="Contact by email" icon={<MailOutlineIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(`mailto:${config.SUPPORT_EMAIL}`, analyticCallback),
    },
    {
      key: 'discord',
      title: <ItemRow text="Chat on Discord" icon={<ChatIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.DISCORD_LINK, analyticCallback),
    },
  ]

  if (config.IS_DEV_ENV) {
    items.push({
      key: 'setusertier',
      title: <ItemRow text="Set User Tier" icon={<SettingsAccessibilityIcon />} />,
      onSelect: () => setUserTierDialogIsOpen(true),
    })
  }

  items.push(
    {
      key: 'divider2',
      title: '',
      isDivider: true,
    },
    {
      key: 'signout',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <LogoutOutlinedIcon />
          Sign out
        </FlexRow>
      ),
      onSelect: () => signOutAsync(),
    }
  )

  const handleUserTierDialogConfirm = async (tier: Tier) => {
    try {
      await DefaultService.srcHandlersv2AccountUpdateTier({
        requestBody: { tier: tier },
      })
    } catch (e) {
      log.error('Failed to update user tier:', e)
      errorToast('Failed to update user tier. Please contact support.')
      return
    }

    try {
      const billingSession = await AccountService.srcHandlersv2AccountGetBillingSession({
        returnUrl: window.location.href,
      })

      if (!billingSession || !isBillingSession(billingSession)) {
        throw new Error('Failed to load billing page')
      }

      rawNavigate(billingSession.location)
    } catch (e) {
      log.error('Failed to load billing session:', e)
      errorToast('Failed to load billing session. Please contact support.')
    }
  }

  return (
    <>
      <OptionsDropdown
        className={className}
        items={items}
        onItemSelected={(item) => item.onSelect?.(postAnalytics, navigate)}
        button={
          loading || !userInfo ? (
            <Loader />
          ) : (
            <UserAvatar
              picture={userInfo?.image}
              name={userInfo?.full_name}
              email={userInfo?.email || ''}
              hideTooltip
            />
          )
        }
        arrow={false}
      />
      <SetUserTierDialog
        isOpen={userTierDialogIsOpen}
        setOpen={setUserTierDialogIsOpen}
        onConfirm={handleUserTierDialogConfirm}
      />
    </>
  )
}
