import {
  Error as CoreError,
  RepositoryWorkspaceManipulationService,
  WorkspaceStatus as CoreWorkspaceStatus,
} from '../../api/coreapi'
import { flatten } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { useApi } from '../useApi'
import { useCallback } from 'react'
import { getStatusFromAgent, isAgentError } from '../../desktop/components/utils/getStatusFromAgent'

export const useWorkspaceStatus = (viewId: string, repoId: string, refId: string) => {
  const workspaceStatusCallback = useCallback(() => {
    return fetchWorkspaceStatus(repoId, refId)
  }, [repoId, refId])
  return useApi([viewId, 'workspaceStatus', 'repos', repoId, 'refs', undefined, refId], workspaceStatusCallback, true)
}

const getStatusFromServerOrAgent = async (
  repoId: string,
  refId: string,
  offset: number,
  limit: number,
  fetchFromServer: boolean
) => {
  if (fetchFromServer) {
    return RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetStatus({
      repoId,
      workspaceId: refId,
      limit: limit,
      skip: offset,
      allowTrim: false,
    })
  }
  return getStatusFromAgent(repoId, refId, offset, limit, () =>
    RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetStatus({
      repoId,
      workspaceId: refId,
      limit: limit,
      skip: offset,
      allowTrim: false,
    })
  )
}

const isCoreError = (data: CoreError | CoreWorkspaceStatus): data is CoreError =>
  (data as CoreError).status !== undefined
const workspaceStatusItemLimit = 8000

export type WorkspaceStatusType = Awaited<ReturnType<typeof fetchWorkspaceStatus>>

export const fetchWorkspaceStatus = async (repoId: string, refId: string) => {
  let offset = 0
  const limit = workspaceStatusItemLimit
  let cascadedChangesCount = 0
  const items = []
  const conflicts = []
  let hasMore = false
  do {
    const workspaceStatus = await getStatusFromServerOrAgent(repoId, refId, offset, limit, true)
    if (isCoreError(workspaceStatus) || isAgentError(workspaceStatus)) {
      throw workspaceStatus
    }
    cascadedChangesCount += workspaceStatus.changed_items_count
    const currentItemsFetched = flatten(Object.values(workspaceStatus.items || {}))
    items.push(...currentItemsFetched)
    conflicts.push(...(workspaceStatus.conflicts || []))
    offset += currentItemsFetched.length
    hasMore = currentItemsFetched.length === limit
  } while (hasMore)

  return {
    items,
    cascadedChangesCount,
    hasConflict: !isEmpty(conflicts),
  }
}
