import Editor, { DiffEditor, loader, Monaco, MonacoDiffEditor } from '@monaco-editor/react'
import { Styleable } from '../../theme'
import { useIsDarkTheme } from '../../hooks/useIsDarkTheme'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import type { editor } from 'monaco-editor'
import { detectLanguage, registerFileExtensions } from './EditorLanguages'
import { syncEditorLine } from './EditorUrlLineSync'

type Props = Styleable & {
  text: string
  baseText?: string
  isDiffView: boolean
  filePath: string // Used for language detection
  uniqueId: string // A unique key to prevent stale content in diff views
  readOnly: boolean
  onChange?: (text: string) => void
  editorBottomPadding?: number
}

const Filler = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.padding.m}rem;
`

export const TextFileEditor = ({
  className,
  text,
  baseText,
  isDiffView,
  filePath,
  uniqueId,
  readOnly,
  onChange,
  editorBottomPadding,
}: Props) => {
  const [monaco, setMonaco] = useState<Monaco>()

  const editorOptions: editor.IDiffEditorConstructionOptions = {
    readOnly,
    contextmenu: false,
    scrollBeyondLastLine: false,
    hideUnchangedRegions: { enabled: true },
    padding: { bottom: editorBottomPadding },
  }

  const [isDarkTheme] = useIsDarkTheme()
  const editorTheme = isDarkTheme ? 'vs-dark' : 'light'

  const OverrideStyle = css`
    :is(.monaco-editor) {
      width: 100% !important;
    }

    .line-numbers {
      cursor: pointer !important;
    }

    section {
      flex-grow: 1;
    }
  `

  // When monaco is loaded, register file extensions and store the monaco instance for later use
  useEffect(() => {
    loader.init().then((monaco: Monaco) => {
      registerFileExtensions(monaco)
      setMonaco(monaco)
    })
  }, [monaco])

  // When the editor is mounted, sync the editor line with the URL hash
  const onEditorMount = (editor: MonacoDiffEditor | editor.IStandaloneCodeEditor) => {
    syncEditorLine(editor)
  }

  return (
    <>
      <Global styles={OverrideStyle} />
      <Filler />
      {isDiffView ? (
        <DiffEditor
          key={`diff-${uniqueId}`}
          className={className}
          originalModelPath={`orig/${uniqueId}/${filePath}`}
          modifiedModelPath={`mod/${uniqueId}/${filePath}`}
          modified={text}
          original={baseText}
          options={editorOptions}
          theme={editorTheme}
          onMount={(editor, _) => onEditorMount(editor)}
          language={detectLanguage(monaco, filePath)}
          keepCurrentOriginalModel
          keepCurrentModifiedModel
        />
      ) : (
        <Editor
          key={`${uniqueId}`}
          className={className}
          value={text}
          path={filePath}
          options={editorOptions}
          onChange={onChange ? (value) => onChange(value || '') : undefined}
          theme={editorTheme}
          onMount={(editor, _) => onEditorMount(editor)}
        />
      )}
      <Filler />
    </>
  )
}
