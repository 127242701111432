import styled from '@emotion/styled'
import { TextTitle } from '../base/TextStyle'
import { useMountedState } from '../../hooks/useMountedState'
import React, { useContext } from 'react'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { useUrlState } from '../../hooks/useUrlState'
import { useApplyShelfAsync } from './useShelf'
import Dialog from '@mui/material/Dialog'
import { StyledDialogActions, StyledDialogContent } from '../dialogs/DialogStyle'
import { Loader } from '../base/Loader'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import { Branch, Shelf } from '../../api/coreapi'
import { callAsync } from '../../utils/callAsync'
import { infoToast } from '../../utils/toast'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: Branch | undefined
  shelves: Shelf[]
  onSuccess: () => void
}

const Title = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

export const ApplyShelfDialog = ({ isOpen, setOpen, branch, shelves, onSuccess }: Props) => {
  const [loading, setLoading] = useMountedState(false)
  const onApiError = useContext(PublishApiErrorContext)
  const { repoId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const applyShelfAsync = useApplyShelfAsync(repoId, workspaceId)

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={isOpen} disableEscapeKeyDown>
      <StyledDialogContent centerText={loading}>
        <Title>Your have previously shelved changes on branch {branch?.branch_name}</Title>
        {loading && <Loader />}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions column height={4 * (shelves.length + 1)}>
          {shelves.map((shelf) => (
            <PrimaryButton
              key={shelf.id}
              disabled={false}
              onClick={() =>
                callAsync(
                  async () => {
                    await applyShelfAsync(shelf.id, true)
                    infoToast(`Shelf ${shelf.name} applied successfully`)
                    onSuccess()
                  },
                  setLoading,
                  onApiError,
                  () => setOpen(false)
                )
              }
            >
              {shelf.name}
            </PrimaryButton>
          ))}
          <SecondaryButton onClick={() => setOpen(false)}>Skip shelves</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
