import { DesktopRedirectComponent } from './DesktopRedirectComponent'
import { sanitizeUrl } from '../../utils/url'
import config from '../../env/config'
import { routeToDesktopLogout, routeToSignIn } from '../../RouteDefinitions'

export const DesktopLogoutPage = () => {
  return (
    <DesktopRedirectComponent
      webAppRedirect={`${sanitizeUrl(config.DESKTOP_DEEP_LINK_DOMAIN!)}${routeToDesktopLogout()}`}
      desktopAppRedirect={routeToSignIn()}
    />
  )
}
