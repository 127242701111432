import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { ReactNode } from 'react'
import { BrowseRoute } from './components/browse/BrowseRoute'
import { WorkspaceRoute } from './components/workspace/WorkspaceRoute'
import { WorkspaceEditRoute } from './components/workspace/WorkspaceEditRoute'
import { WorkspaceFileViewerRoute } from './components/workspace/WorkspaceFileViewerRoute'
import { CommitHistoryRoute } from './components/commit/CommitHistoryRoute'
import { CommitTreeRoute } from './components/commitTree/CommitTreeRoute'
import { CommitFileViewerRoute } from './components/commit/CommitFileViewerRoute'
import { CommitCompareSidePanelRoute } from './components/commit/CommitCompareSidePanelRoute'
import { MergesRoute } from './components/merge/MergesRoute'
import { MergesSidePanelRoute } from './components/merge/MergesSidePanelRoute'
import { MergesConflictsRoute } from './components/merge/MergesConflictsRoute'
import { MergesConflictViewerRoute } from './components/merge/MergesConflictViewerRoute'
import { DetachedCommitRoute } from './components/commit/DetachedCommitRoute'
import { WelcomeRoute } from './components/welcome/WelcomeRoute'
import { PendingRoute } from './components/welcome/PendingRoute'
import { LatestCommits } from './components/commit/LatestCommits'
import { WorkspaceSettingsRoute } from './components/workspace/WorkspaceSettingsRoute'
import { DashboardRoute } from './components/dashboard/DashboardRoute'
import { RepoRoute } from './components/browse/RepoRoute'
import { CommonRootElement } from './components/CommonRootElement'
import { FileHistoryRoute } from './components/history/FileHistoryRoute'
import { SignInPage } from './components/auth/SignInPage'
import { PasswordResetPage } from './components/auth/PasswordResetPage'
import { SignupVerifyPage } from './components/auth/SignupVerifyPage'
import { SignUpPage } from './components/auth/SignUpPage'
import { PasswordResetVerifyPage } from './components/auth/PasswordResetVerifyPage'
import { IntegrationsRoute } from './components/integrations/IntegrationsRoute'
import { TokensTab } from './components/integrations/TokensTab'
import { WebhooksTab } from './components/integrations/WebhooksTab'
import BranchGraph from './components/branchVisualizer/BranchGraph'
import { FullPageLoader } from './components/base/Loader'
import { DesktopLoginPage } from './components/auth/DesktopLoginPage'
import { DesktopLogoutPage } from './components/auth/DesktopLogoutPage'
import { AuthenticatedRootElement } from './components/AuthenticatedRootElement'
import { UserProfileRoute } from './components/user/UserProfileRoute'
import { MembersRoute } from './components/settings/MembersRoute'
import { SettingsRoute } from './components/settings/SettingsRoute'
import { OrganizationReposRoute } from './components/settings/OrganizationReposRoute'
import { QueryParamCleaner } from './components/QueryParamCleaner'
import {
  AuthPaths,
  BranchSubPaths,
  CommitCompareToSubPaths,
  CommitSubPaths,
  IntegrationsPaths,
  MergeSubPath,
  MergesSubPaths,
  RefSubPaths,
  RefViewSubPaths,
  RepoSubPaths,
  RootPaths,
  SettingsSubPaths,
  WorkspaceSubPaths,
  routeToApiTokens,
} from './RouteDefinitions'

declare module 'react-router' {
  export interface PathRouteProps {
    // @ts-ignore
    path: RoutePaths | ''
    element?: ReactNode
    children?: ReactNode
  }
}

export const AppRoutes = () => (
  <BrowserRouter>
    <QueryParamCleaner />
    <Routes>
      <Route element={<CommonRootElement />}>
        <Route index element={<Navigate to={RootPaths.Dashboard} replace />} />
        <Route path={AuthPaths.DesktopLogin} element={<DesktopLoginPage />} />
        <Route path={AuthPaths.DesktopLogout} element={<DesktopLogoutPage />} />
        <Route path={AuthPaths.SignIn} element={<SignInPage />} />
        <Route path={AuthPaths.PasswordReset} element={<PasswordResetPage />} />
        <Route path={AuthPaths.PasswordResetVerify} element={<PasswordResetVerifyPage />} />
        <Route path={AuthPaths.SignUp} element={<SignUpPage />} />
        <Route path={AuthPaths.SignUpVerify} element={<SignupVerifyPage />} />
        <Route element={<AuthenticatedRootElement />}>
          <Route path={RootPaths.Welcome} element={<BrowseRoute />}>
            <Route index element={<WelcomeRoute />}></Route>
          </Route>
          <Route path={RootPaths.Settings} element={<BrowseRoute />}>
            <Route element={<SettingsRoute />}>
              <Route index path={SettingsSubPaths.Profile} element={<UserProfileRoute />} />
              <Route index path={SettingsSubPaths.Members} element={<MembersRoute />} />
              <Route index path={SettingsSubPaths.Repos} element={<OrganizationReposRoute />} />
            </Route>
          </Route>
          <Route path={RootPaths.Pending} element={<BrowseRoute />}>
            <Route index element={<PendingRoute />}></Route>
          </Route>
          <Route path={RootPaths.Dashboard} element={<BrowseRoute />}>
            <Route index element={<DashboardRoute />}></Route>
          </Route>
          <Route path={RootPaths.Support} element={<BrowseRoute />}>
            <Route index element={<DashboardRoute shouldShowSupportBundle />}></Route>
          </Route>
          <Route path={RootPaths.Billing} element={<FullPageLoader />} />
          <Route path={RootPaths.Integrations} element={<BrowseRoute />}>
            <Route element={<IntegrationsRoute />}>
              <Route index element={<Navigate to={routeToApiTokens()} replace />}></Route>
              <Route path={IntegrationsPaths.Tokens} element={<TokensTab />} />
              <Route path={IntegrationsPaths.Webhooks} element={<WebhooksTab />} />
            </Route>
          </Route>
          <Route path={RootPaths.Repo} element={<RepoRoute />}>
            <Route path={RepoSubPaths.Workspace} element={<WorkspaceRoute />}>
              <Route path="" element={<Navigate to={WorkspaceSubPaths.Edit} replace />} />
              <Route path={WorkspaceSubPaths.Edit} element={<WorkspaceEditRoute />}>
                <Route path="" element={<LatestCommits />} />
                <Route path={RefSubPaths.FilePath} element={<WorkspaceFileViewerRoute />} />
              </Route>
              <Route path={WorkspaceSubPaths.View} element={<CommitHistoryRoute />}>
                <Route path="" element={<LatestCommits />} />
                <Route path={RefViewSubPaths.Commit} element={<CommitTreeRoute />}>
                  <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
                </Route>
              </Route>
              <Route path={WorkspaceSubPaths.Settings} element={<WorkspaceSettingsRoute />} />
              <Route path={WorkspaceSubPaths.Visualize} element={<BranchGraph />} />
              <Route path={RootPaths.Repo} element={<RepoRoute />} />
            </Route>
            <Route path={RepoSubPaths.RepoCommit} element={<DetachedCommitRoute />}>
              <Route path="" element={<Navigate to={CommitSubPaths.View} replace />} />
              <Route path={CommitSubPaths.View} element={<CommitTreeRoute />}>
                <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
              </Route>
              <Route path={CommitSubPaths.Compare} element={<CommitCompareSidePanelRoute />}>
                <Route path={CommitCompareToSubPaths.CompareToCommit}>
                  <Route path="" element={<CommitTreeRoute />}>
                    <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={RepoSubPaths.RepoBranch}>
              <Route path="" element={<Navigate to={BranchSubPaths.View} replace />} />
              <Route path={BranchSubPaths.View}>
                <Route index element={<CommitHistoryRoute />}></Route>
                <Route path={RefViewSubPaths.Commit} element={<CommitHistoryRoute />}>
                  <Route path="" element={<CommitTreeRoute />}>
                    <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={RepoSubPaths.RepoMerges} element={<MergesRoute />}>
              <Route path="" element={<MergesSidePanelRoute />} />
              <Route path={MergesSubPaths.Merge} element={<MergesConflictsRoute />}>
                <Route path={MergeSubPath.Conflict} element={<MergesConflictViewerRoute />} />
              </Route>
            </Route>
            <Route path={RepoSubPaths.FileHistory}>
              <Route element={<FileHistoryRoute />}>
                <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute useFilePathFromContext />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </BrowserRouter>
)
