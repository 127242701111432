import { generatePath } from 'react-router'
import isEmpty from 'lodash/isEmpty'

export const usernameQueryParam = 'username'

export const emailQueryParam = 'email'

export const verifyCodeQueryParam = 'verifycode'

export const pageQueryParam = 'page'

export const orgIdQueryParam = 'orgId'

export enum RootPaths {
  Dashboard = 'dashboard',
  Repo = 'repo/:repoId',
  Welcome = 'welcome',
  Pending = 'pending',
  Integrations = 'integrations',
  Billing = 'billing',
  Settings = 'settings',
  Support = 'support',
}

export enum AuthPaths {
  SignIn = 'signin',
  SignUp = 'signup',
  SignUpVerify = SignUp + '/verify',
  PasswordReset = SignIn + '/forgotPassword',
  PasswordResetVerify = PasswordReset + '/verify',
  DesktopLogin = 'desktopOauth2Login',
  DesktopLogout = 'desktopOauth2Logout',
  Logout = 'logout',
}

export enum SettingsSubPaths {
  Profile = 'profile',
  Members = 'members',
  Repos = 'repositories',
}

export enum RepoSubPaths {
  RepoMerges = 'merges',
  RepoBranch = 'branch/:branchId',
  RepoCommit = 'commit/:commitId',
  Workspace = 'workspace/:workspaceId',
  FileHistory = 'files/history',
  visualize = 'visualize',
}

export enum MergesSubPaths {
  Merge = ':mergeId',
}

export enum MergeSubPath {
  Conflict = ':conflictId',
}

export enum BranchSubPaths {
  View = 'view',
}

export enum WorkspaceSubPaths {
  View = 'view',
  Edit = 'edit',
  Settings = 'settings',
  Visualize = 'visualize',
}

export enum RefViewSubPaths {
  Commit = ':commitId',
}

export enum CommitSubPaths {
  View = 'view',
  Compare = 'compare',
}

export enum CommitCompareToSubPaths {
  CompareToCommit = ':compareCommitId',
}

export enum RefSubPaths {
  FilePath = '*',
}

export enum IntegrationsPaths {
  Tokens = 'tokens',
  Webhooks = 'webhooks',
}

export type RoutePaths =
  | RootPaths
  | SettingsSubPaths
  | RepoSubPaths
  | MergesSubPaths
  | MergeSubPath
  | RefSubPaths
  | BranchSubPaths
  | RefViewSubPaths
  | CommitSubPaths
  | CommitCompareToSubPaths
  | IntegrationsPaths

export const routeToWelcome = (page?: number) =>
  generatePath(`/${RootPaths.Welcome + (page ? `?${pageQueryParam}=${page}` : '')}`)

export const routeToDashboard = () => generatePath(`/${RootPaths.Dashboard}`)

export const routeToSettings = () => generatePath(`/${RootPaths.Settings}`)

export const routeToPending = () => generatePath(`/${RootPaths.Pending}`)

export const routeToApiTokens = () => generatePath(`/${RootPaths.Integrations}/${IntegrationsPaths.Tokens}`)

export const routeToWebhooks = () => generatePath(`/${RootPaths.Integrations}/${IntegrationsPaths.Webhooks}`)

export const routeToProfile = () => generatePath(`/${RootPaths.Settings}/${SettingsSubPaths.Profile}`)

export const routeToMembers = (orgId?: string) =>
  generatePath(`/${RootPaths.Settings}/${SettingsSubPaths.Members}`, { orgId })

export const routeToOrganizationRepos = (orgId?: string) =>
  generatePath(`/${RootPaths.Settings}/${SettingsSubPaths.Repos}`, { orgId })

export const organizationRoutes = [routeToMembers(), routeToOrganizationRepos()]

export const routeToRepo = (repoId: string) => generatePath(`/${RootPaths.Repo}`, { repoId })

export const routeToWorkspaceSettings = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Settings}`, { repoId, workspaceId })

export const routeToWorkspaceEdit = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Edit}`, { repoId, workspaceId })

export const routeToWorkspaceView = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.View}`, { repoId, workspaceId })

export const routeToWorkspaceBranchGraphView = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Visualize}`, { repoId, workspaceId })

export const routeToWorkspaceCommitView = (repoId: string, workspaceId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.View}/${RefViewSubPaths.Commit}`, {
    repoId,
    workspaceId,
    commitId,
  })

export const routeToBranchView = (repoId: string, branchId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoBranch}/${BranchSubPaths.View}/${RefViewSubPaths.Commit}`, {
    repoId,
    branchId,
    commitId,
  })

export const routeToCommitView = (repoId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.View}`, {
    repoId,
    commitId,
  })

export const routeToCommitCompare = (repoId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.Compare}`, {
    repoId,
    commitId,
  })

export const routeToCommitCompareWithCommit = (repoId: string, commitId: string, compareCommitId: string) =>
  generatePath(
    `/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.Compare}/${CommitCompareToSubPaths.CompareToCommit}`,
    {
      repoId,
      commitId,
      compareCommitId,
    }
  )

export const routeToMerges = (repoId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}`, { repoId })

export const routeToMerge = (repoId: string, mergeId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}/${MergesSubPaths.Merge}`, { repoId, mergeId })

export const routeToMergeConflict = (repoId: string, mergeId: string, conflictId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}/${MergesSubPaths.Merge}/${MergeSubPath.Conflict}`, {
    repoId,
    mergeId,
    conflictId,
  })

export const routeToFileHistory = (repoId: string, filePath: string, branchId?: string, workspaceId?: string) =>
  withQueryParams(
    appendFilePath(generatePath(`/${RootPaths.Repo}/${RepoSubPaths.FileHistory}`, { repoId }), filePath),
    { ...(branchId ? { branchId } : {}), ...(workspaceId ? { workspaceId } : {}) }
  )

export const routeToSignIn = () => generatePath(`/${AuthPaths.SignIn}`)
export const routeToBilling = () => generatePath(`/${RootPaths.Billing}`)

export const routeToSignInPasswordReset = () => generatePath(`/${AuthPaths.PasswordReset}`)

export const routeToSignInPasswordResetVerify = (username: string, email: string) =>
  generatePath(
    `/${AuthPaths.PasswordResetVerify}?${usernameQueryParam}=${encodeURIComponent(
      username
    )}&${emailQueryParam}=${encodeURIComponent(email)}`
  )

export const routeToSignUp = () => generatePath(`/${AuthPaths.SignUp}`)
export const routeToDesktopLogin = () => generatePath(`/${AuthPaths.DesktopLogin}`)
export const routeToDesktopLogout = () => generatePath(`/${AuthPaths.DesktopLogout}`)
export const routeToLogout = () => generatePath(`/${AuthPaths.Logout}`)

export const routeToSupport = () => generatePath(`/${RootPaths.Support}`)

export const routeToSignUpVerify = (username: string, email: string) =>
  generatePath(
    `/${AuthPaths.SignUpVerify}?${usernameQueryParam}=${encodeURIComponent(
      username
    )}&${emailQueryParam}=${encodeURIComponent(email)}`
  )

/* The without query params functions below should only be used to determine whether the page is in AuthPage */
export const routeToSignUpVerifyWithoutQueryParams = () => generatePath(`/${AuthPaths.SignUpVerify}`)

export const routeToSignInPasswordResetVerifyWithoutQueryParams = () =>
  generatePath(`/${AuthPaths.PasswordResetVerify}`)

export const appendFilePath = (basePath: string, filePath?: string) => (filePath ? `${basePath}/${filePath}` : basePath)

export const withQueryParams = (basePath: string, queryParams: Record<string, string>) =>
  isEmpty(queryParams) ? basePath : `${basePath}?${new URLSearchParams(queryParams).toString()}`

export const isUnderFileHistory = (pathname: string) => pathname.includes(`/${RepoSubPaths.FileHistory}/`)
