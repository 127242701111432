import { useContext, useEffect } from 'react'
import { SyncStatusContext, SyncStatusType } from '../../header/syncstatus/SyncStatusContext'
import { useSyncProgress } from '../../../desktop/hooks/useSyncProgress'
import { IsDesktopApp } from '../../../desktop/components/utils/DesktopAppApi'

type WorkspaceStatusBarUpdaterProps = {
  repoId: string
  workspaceId: string
  needToForwardWorkspace?: boolean
  isUploading?: boolean
}
export const WorkspaceStatusBarUpdater = (props: WorkspaceStatusBarUpdaterProps) => {
  return IsDesktopApp() ? <DesktopWorkspaceStatusBarUpdater {...props} /> : <WebWorkspaceStatusBarUpdater {...props} />
}

const DesktopWorkspaceStatusBarUpdater = ({
  repoId,
  workspaceId,
  needToForwardWorkspace,
  isUploading,
}: WorkspaceStatusBarUpdaterProps) => {
  const { syncStatus, syncingInfo, remainingActions, blobTransferStatus, lastAppliedChanges } = useSyncProgress({
    repoId,
    workspaceId,
  })
  const { doAction: syncStatusAction } = useContext(SyncStatusContext)
  useEffect(() => {
    if (syncStatus === SyncStatusType.None && isUploading) {
      syncStatusAction({ syncStatus: SyncStatusType.Syncing })
    } else if (syncStatus === SyncStatusType.Syncing) {
      syncStatusAction({
        syncStatus,
        statusMsg: syncingInfo,
        numOfFiles: remainingActions,
        blobTransferStatus: blobTransferStatus,
      })
    } else if (needToForwardWorkspace) {
      syncStatusAction({ syncStatus: SyncStatusType.NeedsUpdate })
    } else {
      syncStatusAction({
        syncStatus,
        statusMsg: syncingInfo,
        numOfFiles: remainingActions,
        lastAppliedChanges: lastAppliedChanges,
      })
    }
  }, [
    syncStatus,
    remainingActions,
    syncingInfo,
    syncStatusAction,
    needToForwardWorkspace,
    isUploading,
    blobTransferStatus,
    lastAppliedChanges,
  ])

  return <div />
}

const WebWorkspaceStatusBarUpdater = ({ needToForwardWorkspace, isUploading }: WorkspaceStatusBarUpdaterProps) => {
  const { doAction: syncStatusAction } = useContext(SyncStatusContext)
  useEffect(() => {
    if (isUploading) {
      syncStatusAction({ syncStatus: SyncStatusType.Syncing })
    } else if (needToForwardWorkspace) {
      syncStatusAction({ syncStatus: SyncStatusType.NeedsUpdate })
    } else {
      syncStatusAction({ syncStatus: SyncStatusType.Synced })
    }
  }, [isUploading, syncStatusAction, needToForwardWorkspace])

  return <div />
}
