import { TitledGenericFileEditor } from './TitledGenericFileEditor'
import { useCommit } from '../../hooks/api/useCommit'
import { useVerifyPathOnServer } from './useVerifyPathOnServer'
import { useFileAndBaseFile } from './useFileAndBaseFile'
import isNil from 'lodash/isNil'
import { FlexColumn } from '../base/Flex'
import styled from '@emotion/styled'
import { FILE_HEADER_HEIGHT_REM } from './FileTitleLine'
import { remToPx } from '../../theme'

const Container = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

type Props = {
  repoId: string
  commitId: string
  compareCommitId?: string
  filePath: string
  showFileHistory: boolean
}

export const CommitFileViewer = ({ repoId, commitId, compareCommitId, filePath, showFileHistory }: Props) => {
  const { parentCommitId, loading: commitLoading } = useCommit(repoId, compareCommitId ? undefined : commitId)
  const {
    file,
    baseFile,
    loading: filesLoading,
  } = useFileAndBaseFile(repoId, filePath, commitId, compareCommitId ?? parentCommitId)
  useVerifyPathOnServer(file, baseFile)
  return (
    <Container>
      <TitledGenericFileEditor
        loading={commitLoading || filesLoading}
        repoId={repoId}
        file={file}
        baseFile={baseFile}
        forceDiffView={Boolean(compareCommitId)}
        hasParentRelationship={isNil(compareCommitId)}
        showFileHistory={showFileHistory}
        editorBottomPadding={remToPx(FILE_HEADER_HEIGHT_REM)}
      />
    </Container>
  )
}
