import styled from '@emotion/styled'
import { Ellipsis } from '../base/Ellipsis'
import { LinkWithDisable } from '../base/LinkWithDisable'
import { TextRegular, TextSmall } from '../base/TextStyle'
import { BannerPadding } from '../base/PaddingStyle'
import { notNil } from '../../utils/objectUtil'
import React, { ReactNode, useRef } from 'react'
import { FlexFiller, FlexRow } from '../base/Flex'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { Styleable } from '../../theme'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { HoveredDropdown, menuAction } from '../dropdown/HoveredDropdown'
import { Checkbox } from 'antd'
import { ContextMenu } from '../dropdown/ContextMenu'
import { useContextMenu } from '../../hooks/useContextMenu'

type Props = Styleable & {
  description: string
  redirectToOnClick?: string
  onClick?: () => void
  title: string | ReactNode
  subTitles?: (string | ReactNode)[]
  isSelected: boolean
  clickable: boolean
  tags?: string[]
  menuActions?: Record<string, menuAction>
  children?: ReactNode
}

type CheckableProps = Styleable & {
  description: string
  redirectToOnClick?: string
  onClick?: () => void
  title: string | ReactNode
  subTitles?: string[]
  isSelected: boolean
  tags?: string[]
  menuActions?: Record<string, menuAction>
  onCheck?: () => void
  isChecked?: boolean
}

const StyledItem = styled.div<{ isSelected: boolean }>`
  ${TextRegular};
  ${Ellipsis};
  ${BannerPadding};
  flex-grow: 1;
`

const ItemWithMenu = styled(FlexRow)<{ isSelected: boolean }>`
  position: relative;
  align-items: center;
  align-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.blue.contrastText : theme.colors.black.primary)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.blue.primary : theme.colors.white.primary)};

  :hover {
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.blue.primary : theme.colors.blue.hover)};
  }
`

const TitleRow = styled(FlexRow)`
  gap: 0.5rem;
  align-items: center;
`

const StyledCheckbox = styled(Checkbox)`
  padding-left: 1rem;
`

const SubTitleRow = styled.div<{ isSelected: boolean }>`
  ${TextSmall};
  ${FlexRow};
  gap: 1.5rem;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.blue.contrastText : theme.colors.black.secondary)};
`

const EllipsisDiv = styled.div`
  ${Ellipsis};
`

const Tag = styled.div<{ isSelected: boolean }>`
  ${TextSmall};
  border-radius: 0.5rem;
  padding: 0.1rem 0.3rem;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white.primary : theme.colors.blue.primary)};
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.black.primary : theme.colors.blue.contrastText)};
`

const CallbackClick = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`

type LinkOrCallbackProps = Styleable & {
  description: string
  link: string | undefined
  disabled: boolean
  onClick: (() => void) | undefined
  children: ReactNode
}

const LinkOrCallback = ({ className, description, link, disabled, onClick, children }: LinkOrCallbackProps) => {
  const postAnalytics = useAnalytics()
  return link ? (
    <LinkWithDisable
      className={className}
      to={link}
      disabled={disabled}
      onClick={() => postAnalytics('DropdownItemSelected', { context: description })}
    >
      {children}
    </LinkWithDisable>
  ) : (
    <CallbackClick
      className={className}
      disabled={disabled}
      onClick={() => {
        if (disabled) {
          return
        }
        postAnalytics('DropdownItemSelected', { context: description })
        onClick?.()
      }}
    >
      {children}
    </CallbackClick>
  )
}

export const CheckableSelectItem = ({
  className,
  description,
  title,
  subTitles,
  redirectToOnClick,
  onClick,
  isSelected,
  tags,
  menuActions,
  onCheck,
  isChecked,
}: CheckableProps) => {
  return (
    <SelectItem
      className={className}
      description={description}
      title={title}
      subTitles={subTitles}
      redirectToOnClick={redirectToOnClick}
      onClick={onClick}
      isSelected={isSelected}
      clickable={true}
      tags={tags}
      menuActions={menuActions}
    >
      <StyledCheckbox checked={isChecked} onClick={onCheck} />
    </SelectItem>
  )
}

export const SelectItem = ({
  className,
  description,
  title,
  subTitles,
  redirectToOnClick,
  onClick,
  isSelected,
  clickable,
  tags,
  menuActions,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { menuPosition, setMenuPosition, handleContextMenu } = useContextMenu()

  return (
    <LinkOrCallback
      className={className}
      description={description}
      link={redirectToOnClick}
      disabled={!clickable}
      onClick={onClick}
    >
      <ItemWithMenu
        ref={ref}
        isSelected={isSelected}
        onContextMenu={!isEmpty(menuActions) ? handleContextMenu : undefined}
      >
        {children}
        <StyledItem ref={ref} isSelected={isSelected} title={isString(title) ? title : undefined}>
          <TitleRow>
            {isString(title) ? <EllipsisDiv>{title.trim()}</EllipsisDiv> : title}
            <FlexFiller />
            {!isEmpty(tags) &&
              tags?.map((tag) => (
                <Tag key={tag} isSelected={isSelected} title="Tag">
                  {tag}
                </Tag>
              ))}
          </TitleRow>

          {notNil(subTitles) && (
            <SubTitleRow isSelected={isSelected}>
              {subTitles?.map((subTitle) =>
                typeof subTitle === 'string' ? <EllipsisDiv key={subTitle}>{subTitle}</EllipsisDiv> : subTitle
              )}
            </SubTitleRow>
          )}
        </StyledItem>
        <HoveredDropdown parentRef={ref} enabled={!isEmpty(menuActions)} actions={menuActions || {}} />
        <ContextMenu actions={menuActions || {}} position={menuPosition} onClose={() => setMenuPosition(null)} />
      </ItemWithMenu>
    </LinkOrCallback>
  )
}
