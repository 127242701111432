import { CodeRef } from '../../components/base/CodeRef'
import { DefaultService, ApiError, UserErrors } from '../../api/agentapi'
import { useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { errorToast, infoToast } from '../../utils/toast'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocalDirSelectionDialog } from './LocalDirSelectionDialog'
import { getPathSeparatorFromPath } from '../../utils/pathUtils'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { Divider, Tooltip } from '@mui/material'
import { FlexColumn, FlexRow } from '../../components/base/Flex'
import { TextBold, TextRegular, TextSmall } from '../../components/base/TextStyle'
import styled from '@emotion/styled'
import { InfoOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../RouteDefinitions'
import { useLocalWorkspaces } from '../hooks/useLocalWorkspaces'
import { useRepo } from '../../hooks/api/useRepo'
import { CustomSelect, SelectOption, OptionGroup } from '../../components/base/CustomSelect'

const Subheader = styled.div`
  ${TextBold}
  ${TextRegular}
`

const StyledFlexRow = styled(FlexRow)`
  justify-content: space-between;
`

const StyledInfoOutlined = styled(InfoOutlined)`
  font-size: 0.8rem;
  cursor: help;
  vertical-align: top;
  margin-left: 0.5rem;
`

const StyledDivider = styled(Divider)`
  ${TextSmall}
  margin: 1rem 0;
`

interface CloneRepoDialogProps {
  repoId: string
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  workspaceId?: string
}

function getPath(path: string, repoName: string) {
  const separator = getPathSeparatorFromPath(path)
  return `${path}${separator}${repoName}`
}

export const CloneRepoDialog = ({ repoId, workspaceId, isOpen, setOpen }: CloneRepoDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { repo, repoLoading: isRepoLoading } = useRepo(repoId)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>(workspaceId ?? 'create_new_workspace')
  const { workspacesById, workspacesLoading: isWorkspacesLoading } = useWorkspaces(repoId)
  const { data: localWorkspaces, loading: isLocalWorkspacesLoading } = useLocalWorkspaces()

  const postAnalytics = useAnalytics()
  const navigate = useNavigate()

  const workspaceByIdOptions =
    Object.values(workspacesById)
      .filter((ws) => !localWorkspaces?.data?.some((lw) => lw.WorkspaceID === ws.workspace_id))
      .map((ws) => ({
        value: ws.workspace_id,
        label: ws.name,
      })) ?? []

  const repoName = repo?.repo_name ?? ''

  const onClone = useCallback(
    async (path: string) => {
      const local_path = getPath(path, repoName)
      postAnalytics('CloneRepositoryClicked', {
        repo_id: repoId,
        local_path: local_path,
        workspace_id: selectedWorkspaceId,
      })
      setIsLoading(true)
      try {
        const response = await DefaultService.cloneRepository({
          repoId: repoId,
          requestBody: {
            Path: local_path,
            OverridePath: true,
            WorkspaceID: selectedWorkspaceId === 'create_new_workspace' ? undefined : selectedWorkspaceId,
          },
        })
        setOpen(false)
        infoToast(`Clone started for ${repoName}`)
        navigate(routeToWorkspaceEdit(response.RepoID, response.WorkspaceID))
      } catch (e) {
        if (e instanceof ApiError && e.status === 400) {
          const userErrors = e.body as UserErrors
          const msg = userErrors.errors?.join('\n')
          errorToast(msg)
          log.warn(msg, e)
        } else {
          errorToast()
          log.error('Unexpected agent api error on clone repo', e)
        }
      }
      setIsLoading(false)
    },
    [repoName, postAnalytics, repoId, selectedWorkspaceId, setOpen, navigate]
  )

  const getSelectOptions = () => {
    const createNewWorkspaceOption: SelectOption = {
      value: 'create_new_workspace',
      label: 'Create new workspace',
    }

    if (workspaceByIdOptions.length > 0) {
      const options: Array<SelectOption | OptionGroup> = [
        createNewWorkspaceOption,
        {
          label: 'Available workspaces',
          options: workspaceByIdOptions,
        },
      ]
      return options
    }

    return [createNewWorkspaceOption]
  }

  const renderCloneOptions = () => {
    return (
      <FlexColumn style={{ width: '100%' }}>
        <FlexColumn>
          <StyledFlexRow centered>
            <Subheader>
              {!!workspaceId ? (
                <>
                  Selected workspace: <CodeRef>{workspacesById[workspaceId]?.name}</CodeRef>
                </>
              ) : (
                'Select a workspace'
              )}
              {!workspaceId && (
                <Tooltip
                  title="Workspaces keep your changes synced with the cloud, even before committing."
                  placement="right"
                  arrow
                >
                  <StyledInfoOutlined />
                </Tooltip>
              )}
            </Subheader>
            {!workspaceId && (
              <CustomSelect
                value={selectedWorkspaceId}
                onChange={(event) => setSelectedWorkspaceId(event.target.value as string)}
                placeholder="Select workspace"
                disabled={!!workspaceId}
                options={getSelectOptions()}
                hasGroups={workspaceByIdOptions.length > 0}
                sx={{
                  width: '50%',
                  height: '2rem',
                  '& .MuiSelect-select': {
                    fontSize: '0.95rem',
                    padding: '0.4rem 0.8rem',
                  },
                }}
                size="small"
              />
            )}
          </StyledFlexRow>
        </FlexColumn>
        <StyledDivider />
        <Subheader>
          Choose a local path to clone <CodeRef>{repoName}</CodeRef> into it
        </Subheader>
      </FlexColumn>
    )
  }

  return (
    <LocalDirSelectionDialog
      isOpen={isOpen}
      setOpen={setOpen}
      title={'Clone repository'}
      subTitle={renderCloneOptions()}
      buttonLabel={'Clone'}
      treeId={`${repoId}-clone-repo`}
      onSelected={onClone}
      loading={isLoading || isWorkspacesLoading || isLocalWorkspacesLoading || isRepoLoading}
    />
  )
}
