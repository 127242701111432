import { useNavigate } from 'react-router'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import React, { useCallback } from 'react'
import { ApiError, Commit, RepositoryCommitManipulationService } from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { routeToWorkspaceEdit } from '../../RouteDefinitions'
import { log } from '../../utils/log'
import { useUrlState } from '../../hooks/useUrlState'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { ActionDialog } from '../dialogs/ActionDialog'
import { truncateCommitMessage } from '../../utils/textUtils'
import { TriggerRefreshAgent } from '../../desktop/components/utils/refreshAgent'

const useRevertToCommitAsync = () => {
  const navigate = useNavigate()
  return useCallback(
    async (repoId: string, commitId: string, workspaceId: string) => {
      try {
        await RepositoryCommitManipulationService.srcHandlersv2WorkspaceRevertToCommit({
          repoId,
          workspaceId: workspaceId,
          commitId: commitId,
        })
        infoToast('Workspace reverted successfully to commit', true)
        navigate(routeToWorkspaceEdit(repoId, workspaceId))
      } catch (e) {
        if (e instanceof ApiError && e.status === 409) {
          errorToast('Cannot revert as workspace has uncommitted changes')
        } else {
          errorToast('Error occurred during revert to commit')
          log.error('Unexpected error in revert to commit', e)
        }
      }
    },
    [navigate]
  )
}

interface RevertToCommitDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  commit: Commit
  repoId?: string
}

export const RevertToCommitDialog: React.FC<RevertToCommitDialogProps> = ({ isOpen, setOpen, commit, repoId }) => {
  const { workspaceId } = useUrlState()
  const { workspace, refresh: refreshWorkspace } = useWorkspace(repoId, workspaceId)
  const postAnalytics = useAnalytics()

  const revertToCommitAsync = useRevertToCommitAsync()
  return (
    <ActionDialog
      title={`Revert to commit '${truncateCommitMessage(commit)}'?`}
      message={`This action will bring all the files in the selected commit to the workspace. Do you want to proceed?`}
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={async () => {
        if (!commit) {
          return
        }
        postAnalytics('RevertToCommitDialogConfirmed', { commit_id: commit.commit_id })
        const repoId = workspace?.repo_id!
        const workspaceId = workspace?.workspace_id!
        await revertToCommitAsync(repoId, commit.commit_id, workspaceId)
        TriggerRefreshAgent(workspaceId, repoId)
        refreshWorkspace()
      }}
      confirmButtonLabel={'Revert to commit'}
      loadingMessage={'Creating revert to commit changes...'}
    />
  )
}
