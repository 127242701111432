import Dialog from '@mui/material/Dialog'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { TextBold, TextRegular, TextSmall, TextTitle } from '../base/TextStyle'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Clear'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import isEmpty from 'lodash/isEmpty'
import * as EmailValidator from 'email-validator'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { StyledDialogActions } from './DialogStyle'
import { UserCard } from '../base/UserCard'
import { StyledTextarea } from '../base/Input'
import { RecommendationsService } from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { Loader } from '../base/Loader'
import { useKeyPress } from '../../hooks/useKeyPress'
import * as KeyCode from 'keycode-js'
import { isFocused } from '../../utils/focus'
import { useFocusedButtons } from './useFocusedButtons'
import { log } from '../../utils/log'

type RecommendationModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  userName: string
}

const Title = styled.div`
  ${TextTitle};
  ${TextBold};
`

const Text = styled.div`
  ${TextBold};
`

const NoticeText = styled.div(TextRegular)

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.black.primary};
  cursor: pointer;
`

const DialogContent = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px;
  width: 35vw;
  max-height: 50vh;
`

const StyledInput = styled.input<{ width?: string }>`
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: ${({ width }) => (width ? width : 'auto')};
  border-radius: 1rem;
`
const ValidationError = styled.div`
  ${TextSmall};
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.red.primary};
  white-space: nowrap;
  height: 0.8rem;
`

const ActionButton = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (!!disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blue.hover};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.padding.m}rem ${({ theme }) => theme.padding.l}rem;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.colors.blue.hover};

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.black.primary};
  }
`

const StyledUserCard = styled(UserCard)`
  padding: 0;
  height: 1rem;
  width: 1rem;
`

const RecommendationMessage = styled(StyledTextarea)`
  margin: 0;
  width: calc(100% - 1rem);
  border-radius: 1rem;
`

const AddInviteeSection = styled(FlexRow)`
  margin-right: 1rem;
`

export const RecommendationModal = ({ isOpen, setIsOpen, userName }: RecommendationModalProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [displayName, setDisplayName] = useState<string>(userName)
  const [message, setMessage] = useState<string>('')
  const [invitedEmail, setInvitedEmail] = useState<string>()
  const [emailValidationError, setEmailValidationError] = useState<string>()
  const [invitees, setInvitees] = useState<string[]>([])
  const emailInputRef = useRef<HTMLInputElement>(null)
  const addEmailRef = useRef<HTMLDivElement>(null)
  const { sendRecommendation } = useRecommendationApi(setLoading)
  const [primaryButtonRef, secondaryButtonRef] = useFocusedButtons(isOpen, false)

  useEffect(() => {
    setDisplayName(userName)
    setInvitedEmail('')
    setMessage('')
    setInvitees([])
    setLoading(false)
  }, [isOpen, userName])
  useEffect(() => {
    const valid = isEmpty(invitedEmail) || EmailValidator.validate(invitedEmail!)
    setEmailValidationError(valid ? undefined : 'Invalid email')
  }, [invitedEmail])

  const addInvitee = useCallback(() => {
    if (isEmpty(invitedEmail) || !isEmpty(emailValidationError)) return

    setInvitees((invitees) => [invitedEmail!, ...invitees.filter((e) => e !== invitedEmail)])
    setInvitedEmail('')
  }, [emailValidationError, invitedEmail])
  useKeyPress(
    (event: KeyboardEvent) =>
      event.code === KeyCode.CODE_ENTER && (isFocused(emailInputRef.current) || isFocused(addEmailRef.current)),
    addInvitee,
    isOpen
  )

  const removeInvitee = useCallback((email: string) => {
    setInvitees((invitees) => invitees.filter((e) => e !== email))
  }, [])
  const onClose = () => setIsOpen(false)
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogContent gap={1}>
        <FlexRow centered={true}>
          <Title>Recommend Diversion to Friends!</Title>
          <FlexFiller />
          <StyledCloseIcon onClick={onClose} />
        </FlexRow>
        {loading ? (
          <Loader />
        ) : (
          <>
            <FlexColumn>
              <AddInviteeSection gap={1} centered={true}>
                <StyledInput
                  ref={emailInputRef}
                  autoFocus
                  type="email"
                  placeholder="Email"
                  onChange={(event) => setInvitedEmail(event.target.value || '')}
                  value={invitedEmail}
                  width={'100%'}
                />
                <ActionButton
                  ref={addEmailRef}
                  tabIndex={0}
                  disabled={isEmpty(invitedEmail) || !isEmpty(emailValidationError)}
                  onClick={addInvitee}
                >
                  Add
                </ActionButton>
              </AddInviteeSection>
              {<ValidationError>{emailValidationError}</ValidationError>}
            </FlexColumn>
            <TableContainer>
              <Table aria-label="Invitees"></Table>
              <TableBody>
                {invitees.length > 0 ? (
                  invitees.map((email) => <InviteeRow email={email} removeInvitee={removeInvitee} />)
                ) : (
                  <AddSomeEmailsRow />
                )}
              </TableBody>
            </TableContainer>
            <FlexRow gap={1} centered={true}>
              <Text>Your name: </Text>
              <StyledInput value={displayName} onChange={(event) => setDisplayName(event.target.value)} />
            </FlexRow>
            <FlexColumn gap={1}>
              <RecommendationMessage
                placeholder={'A short message from you (Optional)'}
                rows={3}
                onChange={(event) => setMessage(event.target.value)}
              />
            </FlexColumn>
          </>
        )}
      </DialogContent>
      {!loading && (
        <StyledDialogActions>
          <PrimaryButton
            ref={primaryButtonRef}
            tabIndex={0}
            disabled={isEmpty(invitees)}
            onClick={async () => {
              await sendRecommendation(invitees, message, displayName)
              setIsOpen(false)
            }}
          >
            Send
          </PrimaryButton>
          <SecondaryButton ref={secondaryButtonRef} onClick={onClose} tabIndex={1}>
            Close
          </SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}

const InviteeRow = ({ email, removeInvitee }: { email: string; removeInvitee: (email: string) => void }) => (
  <TableRow key={email} sx={{ 'td, th': { border: 0, padding: '0.8rem' } }}>
    <TableCell sx={{ width: '100%' }} align="left">
      <StyledUserCard email={email} />
    </TableCell>
    <TableCell align={'right'}>
      <ActionButton onClick={() => removeInvitee(email)} disabled={false}>
        Remove
      </ActionButton>
    </TableCell>
  </TableRow>
)

const AddSomeEmailsRow = () => (
  <TableRow sx={{ 'td, th': { border: 0, padding: '0.8rem' } }}>
    <TableCell sx={{ width: '100%' }}>
      <NoticeText>Add some emails...</NoticeText>
    </TableCell>
  </TableRow>
)

const useRecommendationApi = (setLoading: (isLoading: boolean) => void) => {
  const postAnalytics = useAnalytics()
  const sendRecommendation = useCallback(
    async (emails: string[], message?: string, userDisplayName?: string) => {
      try {
        setLoading(true)
        await RecommendationsService.srcHandlersv2RecommendationSendRecommendation({
          requestBody: { emails, user_message: message, user_display_name: userDisplayName },
        })
        const inviteeEmails = emails.join(',')
        postAnalytics('SendRecommendationClicked', {
          num_invitees: emails.length.toString(),
          invitee_email: inviteeEmails,
        })
        infoToast('You’re a hero! 🦸')
      } catch (e) {
        log.error('Failed to send recommendation', e)
        errorToast('Failed to send recommendation')
      } finally {
        setLoading(false)
      }
    },
    [postAnalytics, setLoading]
  )
  return { sendRecommendation }
}
