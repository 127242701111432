import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { organizationRoutes, orgIdQueryParam } from '../RouteDefinitions'

export const QueryParamCleaner = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const pathname = location.pathname
    const isOrganizationSettingsRoute = organizationRoutes.some((route) => pathname.includes(route))

    if (!isOrganizationSettingsRoute && searchParams.has(orgIdQueryParam)) {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete(orgIdQueryParam)

      if (newParams.toString()) {
        setSearchParams(newParams)
      } else {
        setSearchParams('')
      }
    }
  }, [location.pathname, searchParams, setSearchParams])

  return null
}
