import { useMountedState } from '../../hooks/useMountedState'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { callAsync } from '../../utils/callAsync'
import { ApiError, Repo, RepositoryManagementService, RepositoryManipulationService } from '../../api/coreapi'
import last from 'lodash/last'
import { InputActionDialog } from './InputActionDialog'
import isEmpty from 'lodash/isEmpty'
import trimEnd from 'lodash/trimEnd'
import config from '../../env/config'
import { Anchor } from '../base/Anchor'
import styled from '@emotion/styled'
import { Checkbox } from '../base/Checkbox'
import { FlexColumn, FlexRow } from '../base/Flex'
import { FormHelperText, Radio, Theme } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { TextBold, TextSmall } from '../base/TextStyle'
import { GitSyncLevel } from '../../models/GitSyncLevel'
import { Popover } from 'antd'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onCreated: (repoId: string, repoName: string) => void
}

const getImportLevel = (withSync: boolean, withHistory: boolean) => {
  if (withSync) {
    return GitSyncLevel.FullHistoryWithSync
  }
  if (withHistory) {
    return GitSyncLevel.FullHistory
  }
  return GitSyncLevel.DefaultBranchLatestCommit
}

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const RadioRow = styled(FlexRow)`
  align-items: center;
`

const RadioRowNested = styled(FlexRow)`
  align-items: center;
  margin-left: 2rem;
`

const RadioStyle = (theme: Theme) => ({
  '&.Mui-checked': {
    color: theme.colors.blue.primary,
  },
  '&.Mui-checked.Mui-disabled': {
    color: theme.colors.blue.hover,
  },
})

const SyncCheckboxRow = styled(FlexRow)`
  margin-left: 2rem;
`

const StyledOpenInIcon = styled(OpenInNewIcon)`
  margin-left: 0.5rem;
  font-size: 1.2rem;
`

const WarnLabel = styled.span`
  ${TextSmall};
`

const StyledInput = styled.input<{ disabled: boolean }>`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  border-radius: 1rem;
  margin-left: 0.5rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.black.secondary : theme.colors.black.primary)};
  :disabled {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

const LfsCheckboxRow = styled(FlexRow)`
  margin-top: 1rem;
`

const ErrorHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.colors.red.primary};
  margin-left: 2rem;
`

export const ImportRepoDialog = ({ isOpen, setOpen, onCreated }: Props) => {
  const [saveLoading, setLoading] = useMountedState(false)
  const [gitUrl, setGitUrl] = useState<string>('')
  const [withSync, setWithSync] = useState<boolean>(false)
  const [withHistory, setWithHistory] = useState<boolean>(false)
  const [withDefaultBranch, setWithDefaultBranch] = useState<boolean>(true)
  const [branchName, setBranchName] = useState<string>('')
  const [inputError, setInputError] = useState<string>()
  const [noLfsConfirmed, setNoLfsConfirmed] = useState<boolean>(false)
  const onApiError = useContext(PublishApiErrorContext)
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleConfirm = useCallback(async () => {
    await callAsync(
      async () => {
        setInputError(undefined)
        try {
          await RepositoryManipulationService.srcHandlersIntegrationsGitImportImportVerifyUrl({
            requestBody: {
              git_url: gitUrl,
            },
          })
        } catch (e: any) {
          setInputError((e as ApiError)?.body?.detail || 'Invalid git url')
          return
        }
        const name = last(trimEnd(gitUrl, '/').split('/'))!.replace('.git', '')
        const repo = (await RepositoryManagementService.srcHandlersv2RepoPost({
          requestBody: {
            repo_name: name,
            branchless: true,
          },
        })) as Repo
        await RepositoryManipulationService.srcHandlersIntegrationsGitImportImportInBackground({
          repoId: repo.repo_id,
          requestBody: {
            git_url: gitUrl,
            import_level: getImportLevel(withSync, withHistory),
            git_branch: (!withHistory && (withDefaultBranch ? undefined : branchName)) || undefined,
          },
        })
        onCreated(name, repo.repo_id)
        handleClose()
      },
      setLoading,
      onApiError,
      () => {}
    )
  }, [setLoading, onApiError, gitUrl, withSync, withHistory, withDefaultBranch, branchName, onCreated, handleClose])

  useEffect(() => {
    if (!withHistory) {
      setWithSync(false)
    }
  }, [withHistory])

  const isGithubUrl = gitUrl.toLowerCase().includes('github.com')

  const isUrlEmpty = isEmpty(gitUrl)
  const isCustomBranchInvalid = !withHistory && !withDefaultBranch && isEmpty(branchName)
  const isLfsCheckRequired = !isGithubUrl && !noLfsConfirmed

  return (
    <InputActionDialog
      height="100%"
      title="Import Git Repository"
      description={
        <Paragraph>
          <div>Create a new Diversion repo from a GitHub repo.</div>
          <div>
            <Anchor target={`https://github.com/apps/${config.GITHUB_APP_SLUG}`} description="Diversion Github App">
              Enable Diversion Sync on GitHub
              <StyledOpenInIcon />
            </Anchor>
          </div>

          <RadioRow>
            <Radio checked={!withHistory} onChange={() => setWithHistory(false)} sx={RadioStyle} />
            <div>Import only latest commit</div>
          </RadioRow>

          <div>
            <RadioRowNested>
              <Radio
                disabled={withHistory}
                checked={withDefaultBranch}
                onChange={() => setWithDefaultBranch(true)}
                sx={RadioStyle}
              />
              <div>Default branch</div>
            </RadioRowNested>
            <RadioRowNested>
              <Radio
                disabled={withHistory}
                checked={!withDefaultBranch}
                onChange={() => setWithDefaultBranch(false)}
                sx={RadioStyle}
              />
              <div>Custom branch:</div>
              <StyledInput
                autoFocus
                type="text"
                disabled={withHistory || withDefaultBranch}
                placeholder="Branch name"
                onChange={(event) => setBranchName(event.target.value || '')}
              />
            </RadioRowNested>
          </div>

          <RadioRow>
            <Radio checked={withHistory} onChange={() => setWithHistory(true)} sx={RadioStyle} />
            <div>Import all branches with history</div>
          </RadioRow>

          <Popover
            content={
              withHistory && (
                <PopoverText>
                  Contact us at{' '}
                  <AnchorStyled target={`mailto:${config.SUPPORT_EMAIL}`} description="support email">
                    {`${config.SUPPORT_EMAIL}`}
                  </AnchorStyled>{' '}
                  to use this feature
                </PopoverText>
              )
            }
            placement={'bottom'}
            zIndex={9999}
          >
            <SyncCheckboxRow gap={1}>
              <Checkbox title="Enable sync" checked={withSync} setChecked={setWithSync} disabled={true} />
              <FlexColumn>
                <div>Enable continuous bi-directional sync</div>
                <WarnLabel>(Impacting performance)</WarnLabel>
              </FlexColumn>
            </SyncCheckboxRow>
          </Popover>

          {
            <LfsCheckboxRow gap={1}>
              <FlexColumn>
                <FlexRow gap={1}>
                  <Checkbox
                    title="Confirm no Git LFS"
                    checked={noLfsConfirmed}
                    setChecked={setNoLfsConfirmed}
                    disabled={isGithubUrl}
                  />
                  <FlexColumn>
                    <div>I confirm this repository does not use Git LFS</div>
                    <WarnLabel>(Git LFS is only supported for GitHub repositories)</WarnLabel>
                  </FlexColumn>
                </FlexRow>
                {!isGithubUrl && !noLfsConfirmed && !isEmpty(gitUrl) && (
                  <ErrorHelperText>
                    Please confirm that this repository doesn't use Git LFS before importing
                  </ErrorHelperText>
                )}
              </FlexColumn>
            </LfsCheckboxRow>
          }
        </Paragraph>
      }
      onClose={handleClose}
      isOpen={isOpen}
      inputPlaceholder="Repository URL (https://github.com/org/repo)"
      input={gitUrl}
      setInput={(input) => {
        setGitUrl(input)
        setInputError(undefined)
      }}
      saveLoading={saveLoading}
      saveLoadingMessage="Validating URL..."
      createActionName="Import"
      createDisabled={isUrlEmpty || isCustomBranchInvalid || isLfsCheckRequired}
      handleCreate={handleConfirm}
      inputError={inputError}
    />
  )
}

const PopoverText = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.white.primary};

  a {
    cursor: pointer;
  }
`

const AnchorStyled = styled(Anchor)`
  ${TextBold};
`
