import { Loader } from '../base/Loader'
import styled from '@emotion/styled'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BannerPadding } from '../base/PaddingStyle'
import { threeWayMerge } from '../../utils/threeWayMerge'
import isNil from 'lodash/isNil'
import { SmallButton } from '../base/PrimaryButton'
import { useMergeFiles } from './useMergeFiles'
import { useResolveConflictContentAsync, useResolveConflictSideAsync } from './useMergeResolve'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { useNavigate } from 'react-router'
import { routeToMerge } from '../../RouteDefinitions'
import { TextFileEditor } from '../file/TextFileEditor'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { FileTitleLine } from '../file/FileTitleLine'
import { GenericFileEditor } from '../file/GenericFileEditor'
import { log } from '../../utils/log'
import { notNil } from '../../utils/objectUtil'
import { BASE_SIDE, getConflictTypes, OTHER_SIDE } from '../../utils/conflictUtils'
import { FileState } from '../../models/fileState'
import { useMerge } from '../../hooks/api/useMerge'
import { useSessionStorage } from 'usehooks-ts'
import { capitalize, INTERPUNCT } from '../../utils/textUtils'
import { TextSmall } from '../base/TextStyle'
import { css } from '@emotion/react'
import { fromFileEntryStatus } from '../../models/ChangeType'
import { useMergeTitle } from '../../hooks/api/useMergeTitle'
import { CodeRef } from '../base/CodeRef'

type Props = {
  repoId: string
  mergeId: string
  conflictId: string
}

const Container = styled(FlexColumn)`
  position: relative;
  width: 100%;
  overflow: auto;
`

const ResultSection = styled(FlexColumn)`
  min-height: 50%;
  flex-grow: 1;
`

const StyledFileEditor = styled(TextFileEditor)`
  height: 100%;
`

const ActionLine = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  ${BannerPadding};
  align-items: center;
  gap: 1rem;
`

const ConflictTypeListed = styled.div<{ isLast: boolean }>`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.red.primary};
  ${({ isLast }) =>
    !isLast &&
    css`
      ::after {
        font-size: 1rem;
        margin-left: 1rem;
        content: '${INTERPUNCT}';
      }
    `}
`

const ConflictTypeHint = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const useConflict = (repoId: string, mergeId: string, conflictId: string) => {
  const { merge, loading, refresh } = useMerge(repoId, mergeId)
  const conflict = merge?.conflicts.find((conflict) => conflict.conflict_id === conflictId)
  if (merge && !conflict) {
    log.error('No matching conflict was found', { repoId, mergeId, conflictId })
  }
  return { conflict, loading, refresh }
}

const useInitialMergedContent = (
  conflictId: string,
  isRelevant: boolean,
  setMergedText: (text?: string) => void,
  ancestorFile: FileState | undefined | null,
  baseFile?: FileState,
  otherFile?: FileState,
  resultFile?: FileState
) =>
  useEffect(() => {
    if (
      !isRelevant ||
      (isNil(ancestorFile?.content) && ancestorFile !== null) ||
      isNil(baseFile?.content) ||
      isNil(otherFile?.content)
    ) {
      setMergedText(undefined)
      return
    }
    setMergedText(
      resultFile?.content ?? threeWayMerge(baseFile!.content, otherFile!.content, ancestorFile?.content || '')
    )
  }, [resultFile, ancestorFile, baseFile, isRelevant, otherFile, setMergedText, conflictId])

export const MergesConflictViewer = ({ repoId, mergeId, conflictId }: Props) => {
  const navigate = useNavigate()
  const onApiError = useContext(PublishApiErrorContext)

  const [mergedText, setMergedText] = useSessionStorage<string | undefined>(conflictId, undefined)
  const [conflictResolveLoading, setConflictResolveLoading] = useState(false)
  const { merge, loading: mergeLoading } = useMerge(repoId, mergeId)
  const { conflict, loading: conflictLoading, refresh } = useConflict(repoId, mergeId, conflictId)
  const { getTitle, loading: getTitleLoading } = useMergeTitle(repoId, merge ? [merge] : [])
  const title = useMemo(() => (merge && getTitle ? getTitle(merge) : undefined), [getTitle, merge])

  const postConflictResolve = useCallback(() => {
    refresh()
    navigate(routeToMerge(repoId, mergeId))
  }, [mergeId, navigate, refresh, repoId])

  const resolveConflictContentAsync = useResolveConflictContentAsync(
    repoId,
    mergeId,
    conflictId,
    postConflictResolve,
    setConflictResolveLoading,
    onApiError
  )
  const resolveConflictSideAsync = useResolveConflictSideAsync(
    repoId,
    mergeId,
    postConflictResolve,
    setConflictResolveLoading,
    onApiError
  )

  const {
    ancestorFile,
    ancestorFilePath,
    baseFile,
    otherFile,
    resultFile,
    canMerge,
    loading: filesLoading,
  } = useMergeFiles(repoId, merge, conflict)

  useInitialMergedContent(conflictId, canMerge, setMergedText, ancestorFile, baseFile, otherFile, resultFile)
  const loading = conflictLoading || mergeLoading || filesLoading || conflictResolveLoading || getTitleLoading
  const conflictTypes = conflict ? getConflictTypes(conflict) : []

  return (
    <>
      {loading || !baseFile || !conflict ? (
        <Loader addPadding />
      ) : (
        <Container>
          <FileTitleLine
            file={baseFile}
            baseFile={otherFile}
            allowDiffSwitch={false}
            hasParentRelationship={false}
            showFileHistory={false}
          />
          <ActionLine>
            <ConflictTypeHint>{capitalize(fromFileEntryStatus(conflict.other.type) || '')}</ConflictTypeHint>
            <div>
              {capitalize(OTHER_SIDE)} · <CodeRef>{title?.other}</CodeRef>
            </div>
            <SmallButton
              onClick={() => resolveConflictSideAsync(conflict.conflict_id, conflict.other.conflict_index_id)}
              disabled={false}
            >
              Choose {OTHER_SIDE}
            </SmallButton>
            <FlexFiller />
            {conflictTypes.map(capitalize).map((conflictType, index) => (
              <ConflictTypeListed key={conflictType} isLast={index === conflictTypes.length - 1} title={conflictType}>
                {conflictType}
              </ConflictTypeListed>
            ))}
            <FlexFiller />
            <ConflictTypeHint>{capitalize(fromFileEntryStatus(conflict.base.type) || '')}</ConflictTypeHint>
            <div>
              {capitalize(BASE_SIDE)} · <CodeRef>{title?.base}</CodeRef>
            </div>
            <SmallButton
              onClick={() => resolveConflictSideAsync(conflict.conflict_id, conflict.base.conflict_index_id)}
              disabled={false}
            >
              Choose {BASE_SIDE}
            </SmallButton>
          </ActionLine>
          <GenericFileEditor file={baseFile} baseFile={otherFile} isDiffView readOnly />
          {notNil(mergedText) && (
            <ResultSection>
              <ActionLine>
                <div>Result</div>
                <SmallButton
                  onClick={() =>
                    resolveConflictContentAsync(
                      mergedText!,
                      conflict.base.path, // TODO - user should resolve path/mode conflicts
                      conflict.base.file_mode
                    )
                  }
                  disabled={false}
                >
                  Choose combined
                </SmallButton>
              </ActionLine>
              <StyledFileEditor
                text={mergedText!}
                uniqueId={conflictId}
                filePath={ancestorFilePath || ''}
                isDiffView={false}
                onChange={(value) => setMergedText(value || '')}
                readOnly={false}
              />
            </ResultSection>
          )}
        </Container>
      )}
    </>
  )
}
