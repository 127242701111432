import { useApi } from '../useApi'
import { ComparisonItem, FileEntry, RepositoryContentComparisonService } from '../../api/coreapi'
import isNil from 'lodash/isNil'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { buildTree, BuildTreeOptions } from '../../utils/buildTree'
import { Tree, TreeNode } from '../../models/Tree'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { log } from '../../utils/log'
import { getChangesByPath } from '../../utils/comparisonItemUtils'
import isEmpty from 'lodash/isEmpty'
import { isFileNotFoundError, isPreconditionFailedError } from '../../utils/errorClassify'
import { PLACEHOLDER_FOR_NO_PARENT } from '../../utils/commitParent'
import { throttle } from 'lodash'
import { isWorkspaceId } from '../../utils/idUtils'
import { LoadKeyResult } from '../../components/tree/DirStructureTreeView'
import { getPathSeparatorFromPath, sanitizePath } from '../../utils/pathUtils'
import { fetchWorkspaceStatus } from './useWorkspaceStatus'
import { getTreePage } from './getTreePage'
import { useTreePages } from './useTreePages'
import { MutatorOptions } from 'swr'

const THROTTLE_MILLISEC = 10 * 1000

export const useChangedInRef = (viewId: string, repoId: string, refId: string, baseRefId?: string) => {
  const treeCompareCallback = useCallback(async () => {
    const res = await RepositoryContentComparisonService.srcHandlersv2TreeCompare({
      repoId,
      baseId: baseRefId === PLACEHOLDER_FOR_NO_PARENT ? '' : baseRefId,
      otherId: refId,
    })

    function getItemWithComparedStatus(comparisonItem: ComparisonItem) {
      const item = comparisonItem.other_item || comparisonItem.base_item
      //Override the status of the selected commit with the status between the compared items
      if (item) {
        item.status = comparisonItem.status
      }
      return item
    }

    const items: FileEntry[] = res.items.map((i) => getItemWithComparedStatus(i)).filter((i): i is FileEntry => !!i)
    //We don't need the cascaded and has conflict here, but we want to enjoy the caching benefits on the workspace status result
    return {
      items,
      cascadedChangesCount: 0,
      hasConflict: false,
    }
  }, [baseRefId, refId, repoId])

  const workspaceStatusCallback = useCallback(async () => {
    const workspaceStatus = await fetchWorkspaceStatus(repoId, refId)
    //We don't need the cascaded and has conflict here, but we want to enjoy the caching benefits on the workspace status result
    return {
      items: workspaceStatus.items,
      cascadedChangesCount: workspaceStatus.cascadedChangesCount,
      hasConflict: workspaceStatus.hasConflict,
    }
  }, [repoId, refId])

  const { fetcher, methodKey } = useMemo(() => {
    if (isWorkspaceId(refId) && isEmpty(baseRefId)) {
      return { fetcher: workspaceStatusCallback, methodKey: 'workspaceStatus' }
    }

    if (isWorkspaceId(refId) || !isEmpty(baseRefId)) {
      return { fetcher: treeCompareCallback, methodKey: 'compareRefs' }
    }
    return { fetcher: null, methodKey: 'null' }
  }, [refId, baseRefId, workspaceStatusCallback, treeCompareCallback])

  // Keep the requestKey the same as we have in useWorkspaceStatus in order to reuse the swr cache
  return useApi<{ items: FileEntry[] }>([viewId, methodKey, 'repos', repoId, 'refs', baseRefId, refId], fetcher, true)
}

const getTreeStateKey = (repoId: string, refId: string, compareRefId?: string, extraTreeStateKey?: string) =>
  `${repoId}_${refId}_${compareRefId}_${extraTreeStateKey}`

export const useTreeData = (
  repoId: string,
  refId: string,
  compareRefId?: string,
  options?: BuildTreeOptions,
  maxDepthToFetch: number = 1,
  extraTreeStateKey?: string,
  isImmutable?: boolean
) => {
  const onApiError = useContext(PublishApiErrorContext)
  const [treeData, setTreeData] = useState<Tree>()
  const [treeStateKey, setTreeStateKey] = useState<string>()
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [isNewTree, setIsNewTree] = useState<boolean>(true)
  const [frequentUploadMode, setFrequentUploadMode] = useState<boolean>(false)
  const optionsRef = useRef<BuildTreeOptions>(options || {})
  const {
    data: changesData,
    loading: changesLoading,
    refresh: refreshChanges,
  } = useChangedInRef('TreeData', repoId, refId, compareRefId)
  const {
    data: firstLevelTreePages,
    loading: treePagesLoading,
    refresh: refreshTreePages,
  } = useTreePages(
    repoId,
    refId,
    false,
    '',
    undefined,
    optionsRef.current.dirsOnly,
    optionsRef.current.useSelectiveSync,
    onApiError,
    maxDepthToFetch
  )
  useEffect(() => {
    if (!treePagesLoading && firstLevelTreePages) {
      setFrequentUploadMode(firstLevelTreePages.finishedWithOrdinalError)
    }
  }, [firstLevelTreePages, treePagesLoading])

  const loading = changesLoading || isNil(treeData)

  const changesByPath = useMemo(() => getChangesByPath(changesData?.items), [changesData])

  // This hook is used for refreshing the tree when the tree identifies changes like in the case of switching workspaces or branches
  // Note: If the tree state is immutable like in the commit  / commit compare view we don't want to refresh the tree
  useEffect(() => {
    setTreeStateKey((treeStateKey) => {
      const calculatedTreeState = getTreeStateKey(repoId, refId, compareRefId, extraTreeStateKey)
      const differentTreeStateExists = treeStateKey && calculatedTreeState !== treeStateKey
      if (differentTreeStateExists && !isImmutable) {
        setTreeData(undefined)
        refreshTreePages({ optimisticData: false, revalidate: true })
        refreshChanges({ optimisticData: false, revalidate: true })
        return calculatedTreeState
      } else {
        return treeStateKey
      }
    })
  }, [compareRefId, refId, repoId, extraTreeStateKey, refreshTreePages, refreshChanges, isImmutable])

  //This hook is used for refreshing the tree when the tree when we previously loaded the tree with the old data switch between views and the tree data has updated in the background
  // e.x Switching from workspace view to commit history view and back to workspace view
  useEffect(() => {
    if (!isFirstRender) {
      return
    }

    const noTreeStateWithOldTree =
      !treeStateKey &&
      firstLevelTreePages !== undefined &&
      changesData !== undefined &&
      !changesLoading &&
      !treePagesLoading
    if (noTreeStateWithOldTree) {
      setIsFirstRender(false)
      !treePagesLoading && refreshTreePages()
      !changesLoading && refreshChanges()
    }
  }, [
    changesData,
    changesLoading,
    firstLevelTreePages,
    isFirstRender,
    refreshChanges,
    refreshTreePages,
    treePagesLoading,
    treeStateKey,
  ])

  useEffect(() => {
    if (treePagesLoading || changesLoading || !firstLevelTreePages) {
      return
    }
    log.info('building tree from root items', {
      size: firstLevelTreePages.items.length,
      wsJournalId: firstLevelTreePages.workspace_journal_ordinal_id,
      changes: Object.keys(changesByPath || {}).length,
    })
    const nextTree = buildTree(
      firstLevelTreePages.items,
      firstLevelTreePages.workspace_journal_ordinal_id,
      changesByPath,
      new Set(['']),
      {
        ...optionsRef.current,
        hasOrdinalError: firstLevelTreePages.finishedWithOrdinalError,
      }
    )
    setTreeData(nextTree)
    const treeStateKeyForNewTree = getTreeStateKey(repoId, refId, compareRefId, extraTreeStateKey)
    setTreeStateKey((currentStateKey) => {
      return currentStateKey === treeStateKeyForNewTree ? currentStateKey : treeStateKeyForNewTree
    })
    setIsNewTree(true)
  }, [
    firstLevelTreePages,
    treePagesLoading,
    changesByPath,
    changesLoading,
    repoId,
    refId,
    compareRefId,
    extraTreeStateKey,
  ])

  const refreshTree = useCallback(
    (opts?: MutatorOptions<any>) => {
      log.info('refreshing tree', {
        repoId,
        refId,
        compareRefId,
        changesCount: Object.keys(changesByPath || {}).length,
        hasOptions: !!opts,
      })
      refreshTreePages(opts)
      return refreshChanges(opts)
    },
    [repoId, refId, compareRefId, changesByPath, refreshTreePages, refreshChanges]
  )

  const throttledRefreshTree = useMemo(
    () => throttle(() => refreshTree(), THROTTLE_MILLISEC, { leading: true }),
    [refreshTree]
  )

  useEffect(() => {
    // Add another refresh in case the get pages took more time than the revision trigger to take effect
    frequentUploadMode &&
      setTimeout(() => {
        log.info('refreshing tree - frequent upload mode trigger')
        setFrequentUploadMode(false)
        return throttledRefreshTree()
      }, 2 * THROTTLE_MILLISEC)
  }, [frequentUploadMode, throttledRefreshTree])

  const onTreeNodeError = useCallback(
    (error: any, key: string) => {
      if (isPreconditionFailedError(error)) {
        throttledRefreshTree()
        return false
      } else if (isFileNotFoundError(error)) {
        return false
      } else {
        onApiError(error, key)
        return true
      }
    },
    [onApiError, throttledRefreshTree]
  )

  const workspaceJournalOrdinalId = treeData?.workspaceJournalOrdinalId
  const onExpandNodeAsync: (p: string) => Promise<LoadKeyResult> = useCallback(
    async (expandedPath: string) => {
      const filesPage = await getTreePage(
        repoId,
        refId,
        false,
        expandedPath,
        workspaceJournalOrdinalId,
        optionsRef.current.dirsOnly,
        optionsRef.current.useSelectiveSync,
        onTreeNodeError,
        maxDepthToFetch
      )
      setFrequentUploadMode((prevUploadMode) => prevUploadMode || filesPage.finishedWithOrdinalError)
      const pageItems = filesPage.items
      log.info('expanding node', { repoId, refId, expandedPath, children: pageItems.length })
      if (isEmpty(pageItems) && isNil(changesByPath?.[expandedPath])) {
        return { nodes: [], loadedKeys: [expandedPath] }
      }
      let nextTree: Tree | undefined = undefined
      setTreeData((tree) => {
        if (!tree || !tree.nodeByKey[expandedPath]) {
          // setTreeData race condition
          return tree
        }
        const loadedDirPaths = new Set(tree.loadedDirPaths)
        loadedDirPaths.add(expandedPath)
        nextTree = buildTree([...tree.files, ...pageItems], workspaceJournalOrdinalId, changesByPath, loadedDirPaths, {
          ...optionsRef.current,
          hasOrdinalError: filesPage.finishedWithOrdinalError,
        })
        return nextTree
      })
      setIsNewTree(false)
      const loadedNodes = (nextTree as Tree | undefined)?.nodeByKey[expandedPath]?.children || []
      const loadedKeys = getLoadedKeys(expandedPath, maxDepthToFetch, loadedNodes)
      return { nodes: loadedNodes, loadedKeys: loadedKeys }
    },
    [changesByPath, onTreeNodeError, refId, repoId, workspaceJournalOrdinalId, optionsRef, maxDepthToFetch]
  )

  return {
    treeData,
    loading: loading || isNil(treeData),
    refresh: throttledRefreshTree,
    refreshWithOptions: refreshTree,
    onExpandNodeAsync,
    isNewTree,
    frequentUploadMode,
  }
}

const getLoadedKeys = (expandedPath: string, maxDepthToFetch: number, loadedNodes: TreeNode[]) => {
  const loadedPaths = [expandedPath]
  const potentialLoadedPaths = loadedNodes.filter((n) => n.isDirectory).map((n) => n.path)
  if (potentialLoadedPaths.length > 0) {
    //Taking the path of one of the childs to make sure we'll have a path separator
    const pathSeparator = getPathSeparatorFromPath(potentialLoadedPaths[0]!)
    const inputPathSepCount = countOccurrence(sanitizePath(expandedPath), pathSeparator)
    const moreLoadedPaths = potentialLoadedPaths.filter(
      (key) => countOccurrence(sanitizePath(key), pathSeparator) - inputPathSepCount < maxDepthToFetch
    )
    loadedPaths.push(...moreLoadedPaths)
  }

  return loadedPaths
}

const countOccurrence = (sentence: string, char: string): number => Math.max(sentence.split(char).length - 1, 0)
