import { ActionDialog } from '../dialogs/ActionDialog'
import { BranchEx } from '../../models/BranchEx'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import React, { useCallback, useEffect } from 'react'
import { MergeId, RepositoryMergeManipulationService } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { useUrlState } from '../../hooks/useUrlState'
import isEmpty from 'lodash/isEmpty'
import { useBranch } from '../../hooks/api/useBranch'
import { useNavigate } from 'react-router'
import { routeToMerge } from '../../RouteDefinitions'
import { log } from '../../utils/log'
import { validateWorkSpace } from '../../hooks/useWorkspaceValidation'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'
import { isOperationFailedWithNewerChanges } from '../../utils/errorClassify'
import { errorToast } from '../../utils/toast'
import { TriggerRefreshAgent } from '../../desktop/components/utils/refreshAgent'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: BranchEx | undefined
}

export const useMergeAsync = () => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  return useCallback(
    async (repoId: string, branchId: string, targetBranchId: string, workspaceId: string | undefined) => {
      let result
      try {
        result = await RepositoryMergeManipulationService.srcHandlersv2MergePost({
          repoId,
          baseId: targetBranchId,
          otherId: branchId,
        })
      } catch (e) {
        if (isOperationFailedWithNewerChanges(e)) {
          errorToast('New changes received since merge started. Try again.')
          return
        } else {
          errorToast('An error occurred during merge.')
          log.error('Merge error', { repoId, branchId, targetBranchId, error: e })
          return
        }
      }

      const mergeId = (result as MergeId)?.merge_id
      const hasConflicts = !isEmpty(mergeId)
      if (hasConflicts) {
        log.info('Merge had conflicts', { repoId, branchId, targetBranchId, mergeId })
        infoToast('Conflicts discovered in merge', true)
        navigate(routeToMerge(repoId, mergeId))
      } else {
        infoToast('Branch merged successfully', true)
      }
      TriggerRefreshAgent(workspaceId, repoId)
      postAnalytics('BranchMerge', {
        repo_id: repoId,
        branch_id: branchId,
        target_branch_id: targetBranchId,
        has_conflicts: hasConflicts.toString(),
      })
    },
    [navigate, postAnalytics]
  )
}

export const MergeDialog = ({ isOpen, setOpen, branch }: Props) => {
  const { repoId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const { branch: targetBranch, refresh: targetBranchRefresh } = useBranch(repoId, workspace?.branch_id)
  const mergeAsync = useMergeAsync()

  useEffect(() => validateWorkSpace(isOpen, workspaceId, setOpen, workspace), [isOpen, setOpen, workspace, workspaceId])
  return (
    <ActionDialog
      title="Merge"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        await mergeAsync(repoId!, branch?.branch_id!, targetBranch?.branch_id!, workspaceId)
        await targetBranchRefresh()
      }}
      message={`About to merge branch ${branch?.branch_name} into ${targetBranch?.branch_name}`}
      setOpen={setOpen}
      confirmButtonLabel="Merge"
      loadingMessage="Merging branches..."
    />
  )
}
