import { WorkspaceFileViewer } from '../file/WorkspaceFileViewer'
import { useUrlState } from '../../hooks/useUrlState'
import { FileOpsActionContext } from '../sidepanel/workspace/fileOpsTypes'
import { useFileOpsActions } from '../sidepanel/workspace/useFileOpsActions'

export const WorkspaceFileViewerRoute = () => {
  const { repoId, workspaceId, '*': filePath } = useUrlState()
  const { doAction, dialogs: ActionDialogs } = useFileOpsActions(repoId!, workspaceId!)
  return (
    <FileOpsActionContext.Provider value={{ doAction }}>
      <ActionDialogs />
      <WorkspaceFileViewer repoId={repoId!} filePath={filePath!} workspaceId={workspaceId!} />
    </FileOpsActionContext.Provider>
  )
}
