import styled from '@emotion/styled'
import React, { useContext, useEffect, useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ActionDialog } from './ActionDialog'
import { RepositoryManipulationService } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { FlexColumn } from '../base/Flex'
import { CodeRef } from '../base/CodeRef'
import { StyledTextarea } from '../base/Input'
import { callAsync } from '../../utils/callAsync'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'

type DeleteRepoDialogProps = {
  repoId: string
  repoName: string
  afterDelete: () => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const Bold = styled.div`
  font-weight: 500;
`

const ConfirmTextarea = styled(StyledTextarea.withComponent('input'))`
  min-height: 2.5rem;
  margin: 0.5rem 0;
  width: 100%;
`

export const DeleteRepoDialog = ({ repoId, repoName, afterDelete, isOpen, setIsOpen }: DeleteRepoDialogProps) => {
  const postAnalytics = useAnalytics()
  const [confirmText, setConfirmText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const onApiError = useContext(PublishApiErrorContext)

  useEffect(() => {
    if (isOpen) {
      setConfirmText('')
      postAnalytics('DeleteRepoDialogOpened', { repo_id: repoId })
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [isOpen, postAnalytics, repoId])

  const isConfirmValid = confirmText.trim() === repoName.trim()

  const confirmDelete = () => {
    if (!isConfirmValid || isDeleting) {
      return Promise.resolve()
    }
    return callAsync(
      async () => {
        await RepositoryManipulationService.srcHandlersv2RepoDelete({ repoId })
        infoToast(`Repository ${repoName} deleted`, true)
        afterDelete()
        setIsOpen(false)
      },
      setIsDeleting,
      onApiError
    )
  }

  return (
    <ActionDialog
      title="Delete Repository"
      isOpen={isOpen}
      setOpen={setIsOpen}
      onConfirmAsync={confirmDelete}
      message={
        <FlexColumn gap={1}>
          <div>
            Are you sure you want to delete the repository <CodeRef>{repoName}</CodeRef>
          </div>
          <Bold>Warning! Once you delete a repository, it's really gone - no respawns.</Bold>
          <div>
            Please type <CodeRef>{repoName}</CodeRef> to confirm deletion:
          </div>
          <ConfirmTextarea
            ref={inputRef}
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder={`Type ${repoName} to confirm`}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                await confirmDelete()
              }
            }}
            disabled={isDeleting}
          />
        </FlexColumn>
      }
      confirmButtonLabel="Delete"
      loadingMessage="Deleting repository..."
      isDanger
      disabled={!isConfirmValid}
    />
  )
}
