import styled from '@emotion/styled'
import { FileEntry } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'
import { getChangesByPath, isImplicit } from '../../utils/comparisonItemUtils'
import { useMemo, useRef, useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { StyledAntTree } from '../tree/StyledAntTree'
import { TreeNode } from '../../models/Tree'
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos'
import { AntTreeNodeProps } from 'antd/lib/tree'
import { buildTree } from '../../utils/buildTree'
import { getUniqueParentDirectories } from '../../utils/pathUtils'
import { FILE_MODE_DIRECTORY } from '../../models/fileMode'
import { ObjectStatusValues } from '../../models/ChangeType'
import { NodeTitle } from '../tree/NodeTitle'

const ArrowDownIcon = styled(ArrowRightIcon)`
  transform: rotate(90deg);
`

const Container = styled(FlexColumn)`
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 4px;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white.primary};
`

type Props = {
  checkedChangedItems: FileEntry[] | undefined
}

export const CommitChangesTree = ({ checkedChangedItems }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])

  const changesByPath = useMemo(() => getChangesByPath(checkedChangedItems), [checkedChangedItems])

  const treeData = useMemo(() => {
    if (!changesByPath || !checkedChangedItems?.length) return []

    // Filter to only include checked items that aren't implicit
    const filteredItems = checkedChangedItems.filter((item) => !isImplicit(changesByPath[item.path]!))

    if (isEmpty(filteredItems)) return []

    const parentDirs = getUniqueParentDirectories(filteredItems.map((item) => item.path))
    const fileEntries: FileEntry[] = [
      // Add parent directories first
      ...Array.from(parentDirs).map((dirPath) => ({
        path: dirPath,
        mode: FILE_MODE_DIRECTORY,
        status: ObjectStatusValues.Modified,
        size: 0,
      })),
      // Then add the actual files with their original status
      ...filteredItems,
    ]

    const tree = buildTree(
      fileEntries,
      undefined, // No journal ordinal ID needed for commit view
      changesByPath,
      new Set(['']), // Start with root loaded
      {
        staticView: false,
        skipMergeSingleChildren: false,
      }
    )

    return tree.root.children || []
  }, [changesByPath, checkedChangedItems])

  // Auto-expand all directories (defaultExpandAll doesn't do it)
  useEffect(() => {
    // Force expand all keys
    const getAllKeys = (nodes: TreeNode[]): string[] => {
      return nodes.reduce((acc: string[], node) => {
        if (node.children) {
          return [...acc, node.key, ...getAllKeys(node.children)]
        }
        return [...acc, node.key]
      }, [])
    }

    const allNestedKeys = getAllKeys(treeData)
    setExpandedKeys(allNestedKeys)
  }, [treeData])

  if (isEmpty(treeData)) {
    return null
  }

  return (
    <Container ref={containerRef}>
      <StyledAntTree
        height={280}
        checkable={false}
        expandedKeys={expandedKeys}
        defaultExpandAll
        onExpand={(expandedKeys) => {
          setExpandedKeys(expandedKeys as string[])
        }}
        treeData={treeData}
        showLine
        blockNode
        switcherIcon={({ expanded }: AntTreeNodeProps) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon />)}
        titleRender={(node: TreeNode) => (
          <NodeTitle
            {...node}
            isSelected={false}
            treeCheckable={false}
            checked={false}
            isSearchResult={false}
            enableWorkspaceActions={false}
            changedOnly
            disableCheckbox
          />
        )}
      />
    </Container>
  )
}
