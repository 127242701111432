import { ActionDialog } from '../dialogs/ActionDialog'
import { BranchEx } from '../../models/BranchEx'
import React from 'react'
import { useUrlState } from '../../hooks/useUrlState'
import { useCheckoutAsync } from './useCheckout'
import { RepositoryShelvesManipulationService, Shelf } from '../../api/coreapi'
import isEmpty from 'lodash/isEmpty'
import { useUploadingAgents } from '../../hooks/api/useUploadingAgents'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../RouteDefinitions'
import { TriggerRefreshAgent } from '../../desktop/components/utils/refreshAgent'
import { useWorkspaces } from '../../hooks/api/useWorkspaces'
import { useWorkspace } from '../../hooks/api/useWorkspace'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: BranchEx | undefined
  onHasPendingChanges: (branchId: string, workspaceId?: string) => void
  onHasShelvedChanges: (shelves: Shelf[], workspaceId?: string) => void
}

export const CheckoutDialog = ({ isOpen, setOpen, branch, onHasPendingChanges, onHasShelvedChanges }: Props) => {
  const navigate = useNavigate()
  const { repoId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const checkoutAsync = useCheckoutAsync(repoId, workspaceId, onHasPendingChanges)
  const { data: uploadingAgents } = useUploadingAgents(repoId, workspaceId)
  const uploaders = uploadingAgents?.uploaders
  const isUploading = uploaders && uploaders.length > 0
  const { refresh: refreshWorkspaces } = useWorkspaces(repoId)
  const { refresh: refreshWorkspace } = useWorkspace(repoId, workspaceId)

  const message = isUploading
    ? `There is still an upload in progress by ${uploaders.join(', ')}. Please let it finish before switching.`
    : `About to switch your workspace to branch ${branch?.branch_name}`
  return (
    <ActionDialog
      title="Switch branch"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        if (!branch) {
          return
        }
        const hasPendingChanges = await checkoutAsync(branch.branch_id)
        const shelves = (
          (await RepositoryShelvesManipulationService.srcHandlersv2ShelfListAll({
            repoId: repoId!,
            branchId: branch.branch_id,
          })) as { items: Shelf[] }
        ).items
        if (!isEmpty(shelves)) {
          onHasShelvedChanges(shelves, workspaceId)
        } else if (workspaceId && !hasPendingChanges) {
          await refreshWorkspace()
          await refreshWorkspaces()
          TriggerRefreshAgent(workspaceId, repoId)
          navigate(routeToWorkspaceEdit(repoId!, workspaceId))
        }
      }}
      message={message}
      setOpen={setOpen}
      confirmButtonLabel="Switch"
      loadingMessage="Switching branch..."
      disabled={isUploading}
    />
  )
}
