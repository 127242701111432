import { useState } from 'react'

export const useContextMenu = () => {
  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number } | null>(null)

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const rect = event.currentTarget.getBoundingClientRect()
    setMenuPosition({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    })
  }

  return {
    menuPosition,
    setMenuPosition,
    handleContextMenu,
  }
}
