import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Tier } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'
import { Checkbox } from '../base/Checkbox'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`
const InputFreetextField = styled(TextField)`
  width: 100%;
  text-align: left;
`

const MessageContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
`

const CheckboxContainer = styled.div`
  padding: 12px;
`

const TopAlignedFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;

  .MuiFormControlLabel-label {
    padding: 0 12px;
  }
`

const UserTierContainer = styled.div`
  color: ${({ theme }) => theme.colors.black.primary};
`

type UserTierChooserProps = {
  selectedTier: Tier
  setSelectedTier: (value: ((prevState: Tier) => Tier) | Tier) => void
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  contactMeChecked: boolean
  setContactMeChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  tierInfo: TierInfo
  setTierInfo: (value: ((prevState: TierInfo) => TierInfo) | TierInfo) => void
}

export type TierInfo = {
  userProfession?: string
  companyField?: string
  projectStage?: string
  referredBy?: string
  extraInfo?: string
}

type MoreInfoFormProps = {
  tierType: Tier
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  contactMeChecked: boolean
  setContactMeChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  tierInfo: TierInfo
  setTierInfo: (value: ((prevState: TierInfo) => TierInfo) | TierInfo) => void
}

export const MoreInfoForm = ({
  tierType,
  checkboxChecked,
  setCheckboxChecked,
  contactMeChecked,
  setContactMeChecked,
  tierInfo,
  setTierInfo,
}: MoreInfoFormProps) => {
  const tierTexts = {
    [Tier.INDIE]: {
      tierIntroText: `We're excited to offer you a generous FREE tier of our product! To help us serve you better, we'd greatly appreciate if you could answer a few quick questions. Your insights will directly shape product improvements.`,
      companyFieldText: 'In my day job, the company I work for is in the field of...',
      contactMeText: "(optional) I'm happy to elaborate over a call / email",
      qualificationCheckboxText:
        'I certify my organization qualifies for the Indie tier: game development with under $100K revenue, less than $1M funding, and fewer than 20 people.',
      inputFieldText: 'If marked Other for any question, please elaborate',
    },
    [Tier.PROFESSIONAL]: {
      tierIntroText: `Welcome to our Pro tier! To help you get the most out of Diversion, we offer a customized onboarding call tailored to your needs and workflow. Check the box below to schedule your call.`,
      companyFieldText: 'We will be using Diversion for...',
      contactMeText: '(optional) Please contact me to schedule an onboarding call',
      qualificationCheckboxText: '',
      inputFieldText: 'If marked Other for any question, please elaborate',
    },
    [Tier.EDUCATION]: {
      tierIntroText: '',
      companyFieldText: '',
      contactMeText: '',
      qualificationCheckboxText:
        'I certify that my use of Diversion is for studies at a recognized academic institution.',
      inputFieldText: 'Institution and course name',
    },
    [Tier.ENTERPRISE]: {
      tierIntroText: '',
      companyFieldText: '',
      contactMeText: '',
      qualificationCheckboxText: '',
      inputFieldText: '',
    },
    [Tier.UNKNOWN]: {
      tierIntroText: '',
      companyFieldText: '',
      contactMeText: '',
      qualificationCheckboxText: '',
      inputFieldText: '',
    },
  }

  const { tierIntroText, companyFieldText, contactMeText, qualificationCheckboxText, inputFieldText } =
    tierTexts[tierType]
  return (
    <UserTierContainer>
      <FlexColumn gap={1} style={{ marginTop: '2rem' }}>
        <div>{tierIntroText}</div>
        {tierType === Tier.INDIE && (
          <FormControl>
            <InputLabel id="user-industry-label">I am a...</InputLabel>
            <Select
              aria-label="user-profession"
              name="user-profession"
              value={tierInfo.userProfession}
              label="I am a"
              sx={{
                width: '100%',
              }}
              onChange={(e) => setTierInfo({ ...tierInfo, userProfession: e.target.value })}
            >
              <MenuItem value="Software developer">Software developer</MenuItem>
              <MenuItem value="Artist/Designer">Artist/Designer</MenuItem>
              <MenuItem value="Producer">Producer</MenuItem>
              <MenuItem value="IT expert">IT expert</MenuItem>
              <MenuItem value="Engineering manager">Engineering manager</MenuItem>
              <MenuItem value="Art director">Art director</MenuItem>
              <MenuItem value="Game designer">Game designer</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        )}
        {(tierType === Tier.INDIE || tierType === Tier.PROFESSIONAL) && (
          <FormControl>
            <InputLabel id="user-industry-label">{companyFieldText}</InputLabel>
            <Select
              aria-label="user-industry"
              name="user-industry"
              value={tierInfo.companyField}
              label="My day job is in the field of"
              onChange={(e) => setTierInfo({ ...tierInfo, companyField: e.target.value })}
            >
              {tierType === Tier.INDIE && (
                <MenuItem value="This is my day job">I'm using Diversion for my day job</MenuItem>
              )}
              <MenuItem value="Game development">Game development</MenuItem>
              <MenuItem value="Media and entertainment">Media and entertainment</MenuItem>
              <MenuItem value="Virtual production">Virtual production</MenuItem>
              <MenuItem value="Architecture / interior design">Architecture / interior design</MenuItem>
              <MenuItem value="Games infrastructure">Games infrastructure</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        )}
        {tierType === Tier.INDIE && (
          <FormControl>
            <InputLabel id="user-industry-label">My game is in the stage of...</InputLabel>
            <Select
              aria-label="game-stage"
              name="game-stage"
              value={tierInfo.projectStage}
              label="My game is in the stage of"
              sx={{
                width: '100%',
              }}
              onChange={(e) => setTierInfo({ ...tierInfo, projectStage: e.target.value })}
            >
              <MenuItem value="Concept / pre-production">Just tinkering / concept / pre-production</MenuItem>
              <MenuItem value="Production">Production</MenuItem>
              <MenuItem value="Vertical slice / playable demo">Vertical slice / playable demo</MenuItem>
              <MenuItem value="Alpha / beta testing">Alpha / beta testing</MenuItem>
              <MenuItem value="Launch / post-launch">Launch / post-launch</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl>
          <InputLabel id="user-industry-label">I found out about Diversion through...</InputLabel>
          <Select
            aria-label="referrer"
            name="referrer"
            value={tierInfo.referredBy}
            label="I found out about Diversion through"
            sx={{
              width: '100%',
            }}
            onChange={(e) => setTierInfo({ ...tierInfo, referredBy: e.target.value })}
          >
            <MenuItem value="AI assistant">AI assistant (ChatGPT, Gemini etc.)</MenuItem>
            <MenuItem value="YouTube">YouTube</MenuItem>
            <MenuItem value="Conference">Conference (GDC / gamescom etc.)</MenuItem>
            <MenuItem value="Game jam">Game jam (GGJ, Brackeys etc.)</MenuItem>
            <MenuItem value="UE docs">Official Unreal Engine documentation</MenuItem>
            <MenuItem value="Reddit">Reddit</MenuItem>
            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
            <MenuItem value="Discord">Discord</MenuItem>
            <MenuItem value="Email">Email outreach</MenuItem>
            <MenuItem value="Colleague or friend">A colleague or a friend</MenuItem>
            <MenuItem value="Google search">Google search</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        {tierInfo.referredBy === 'Other' ||
        tierInfo.projectStage === 'Other' ||
        tierInfo.companyField === 'Other' ||
        tierInfo.userProfession === 'Other' ||
        tierType === Tier.EDUCATION ? (
          <InputFreetextField
            required={tierType === Tier.EDUCATION}
            label={inputFieldText}
            variant="outlined"
            size="small"
            onChange={(e) => setTierInfo({ ...tierInfo, extraInfo: e.target.value })}
          />
        ) : (
          <></>
        )}
        {(tierType === Tier.INDIE || tierType === Tier.PROFESSIONAL) && (
          <TierCheckbox
            checked={contactMeChecked}
            setChecked={setContactMeChecked}
            tooltip={''}
            label={contactMeText}
          />
        )}
        {(tierType === Tier.INDIE || tierType === Tier.EDUCATION) && (
          <TierCheckbox
            checked={checkboxChecked}
            setChecked={setCheckboxChecked}
            tooltip={'I am qualified for this tier'}
            label={qualificationCheckboxText}
          />
        )}
      </FlexColumn>
    </UserTierContainer>
  )
}

export const UserTierChooser = ({
  selectedTier,
  setSelectedTier,
  checkboxChecked,
  setCheckboxChecked,
  contactMeChecked,
  setContactMeChecked,
  tierInfo,
  setTierInfo,
}: UserTierChooserProps) => {
  useEffect(() => {
    setCheckboxChecked(false)
  }, [selectedTier, setCheckboxChecked])
  return (
    <UserTierContainer>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="user-tier"
          name="user-tier"
          value={selectedTier}
          onChange={(e) => setSelectedTier(e.target.value as Tier)}
        >
          <FormControlLabel
            label="Professional ($25/user, 21 day free trial, no credit card required)"
            control={<Radio />}
            value={Tier.PROFESSIONAL}
          />
          <FormControlLabel
            label="Indie game development (starting at $0/user)"
            control={<Radio />}
            value={Tier.INDIE}
          />
          <FormControlLabel label={'Educational'} control={<Radio />} value={Tier.EDUCATION} />
        </RadioGroup>
      </FormControl>
      <MessageContainer>
        Not sure which tier?{' '}
        <StyledLink href={`https://www.diversion.dev/pricing`} target="_blank" rel="noopener noreferrer">
          Compare tiers
        </StyledLink>
      </MessageContainer>
      {(selectedTier === Tier.INDIE || selectedTier === Tier.PROFESSIONAL || selectedTier === Tier.EDUCATION) && (
        <MoreInfoForm
          tierType={selectedTier}
          tierInfo={tierInfo}
          setTierInfo={setTierInfo}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          contactMeChecked={contactMeChecked}
          setContactMeChecked={setContactMeChecked}
        />
      )}
    </UserTierContainer>
  )
}

const TierCheckbox = ({
  checked,
  setChecked,
  tooltip,
  label,
}: {
  checked: boolean
  setChecked: (checked: boolean) => void
  tooltip: string
  label: string
}) => (
  <FlexColumn>
    <CheckboxContainer>
      <TopAlignedFormControlLabel
        control={<Checkbox title={tooltip} checked={checked} setChecked={setChecked} />}
        label={label}
      />
    </CheckboxContainer>
  </FlexColumn>
)
