import { ActionDialog } from '../../../dialogs/ActionDialog'
import { Checkbox } from '../../../base/Checkbox'
import { useContext, useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../../base/Flex'
import { usePostResetAsync } from './usePostResetAsync'
import { useUrlState } from '../../../../hooks/useUrlState'
import { ActionValidationContext } from '../actionValidationContext'
import { pluralize } from '../../../../utils/textUtils'
import { ObjectStatusValues } from '../../../../models/ChangeType'
import { WorkspaceStatusType } from '../../../../hooks/api/useWorkspaceStatus'
import { FilesPage } from '../../../../hooks/api/getTreePage'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  filePaths?: string[]
  resetAll?: boolean
}

export const ResetDialog = ({ isOpen, setOpen, resetAll, filePaths }: Props) => {
  const [deleteAdded, setDeleteAdded] = useState(false)
  const { repoId, workspaceId } = useUrlState()
  const { revalidateState } = useContext(ActionValidationContext)

  // Create optimistic data updates
  const optimisticTreeData = (currentData?: FilesPage) => {
    if (!currentData) return currentData

    const pathsToReset = resetAll ? currentData.items.map((item) => item.path) : filePaths || []

    return {
      ...currentData,
      items: currentData.items
        .filter(
          (item) => !(deleteAdded && item.status === ObjectStatusValues.Added && pathsToReset.includes(item.path))
        )
        .map((item) => {
          if (!deleteAdded && item.status === ObjectStatusValues.Added) {
            return item
          }
          if (pathsToReset.includes(item.path)) {
            return { ...item, status: ObjectStatusValues.Intact }
          }
          return item
        }),
    }
  }

  const optimisticChangesData = (currentData?: WorkspaceStatusType) => {
    if (!currentData) return currentData

    const pathsToReset = resetAll ? currentData.items.map((item) => item.path) : filePaths || []

    const remainingItems = currentData.items.filter((item) => {
      // Keep items that are not being reset
      if (!pathsToReset.includes(item.path)) return true
      // If deleteAdded is true and item is new, remove it
      if (deleteAdded) {
        if (item.status === ObjectStatusValues.Added) {
          return false
        } else {
          return true
        }
      }

      // Otherwise, keep it (it will be updated to intact)
      return false
    })

    return {
      ...currentData,
      items: remainingItems,
      cascadedChangesCount: remainingItems.length,
    }
  }

  const onResetConfirm = usePostResetAsync(repoId, workspaceId, !!resetAll, filePaths, async () =>
    revalidateState(false, optimisticTreeData, optimisticChangesData)
  )
  useEffect(() => {
    setDeleteAdded(false)
  }, [isOpen])

  return (
    <ActionDialog
      title="Discard"
      isOpen={isOpen}
      onConfirmAsync={async () => await onResetConfirm(deleteAdded)}
      message={
        <FlexColumn gap={1}>
          {resetAll && <div>You are about to discard all changes, do you want to proceed?</div>}
          {!resetAll && (
            <div>
              You are about to discard changes on {pluralize(filePaths?.length!, 'selected path')}, do you want to
              proceed?
            </div>
          )}
          <FlexRow gap={1}>
            <Checkbox title="Delete new files" checked={deleteAdded} setChecked={setDeleteAdded} />
            <div onClick={() => setDeleteAdded((b) => !b)}>Delete new files (this will also delete them locally) </div>
          </FlexRow>
        </FlexColumn>
      }
      setOpen={setOpen}
      confirmButtonLabel="Discard"
      loadingMessage="Discarding your changes..."
      isDanger
    />
  )
}
