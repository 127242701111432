import { useSessionStorage } from 'usehooks-ts'
import { usePostCommitAsync } from './usePostCommitAsync'
import { CommitMessage } from '../../commitAction/CommitMessage'
import * as React from 'react'
import { FlexColumn } from '../../base/Flex'
import styled from '@emotion/styled'
import { CommitButton } from '../../commitAction/CommitButton'
import { FileEntry } from '../../../api/coreapi'
const CommitMessageWrapper = styled(FlexColumn)`
  backdrop-filter: ${({ theme }) => theme.colors.background};
  height: calc(100% - 2rem);
  margin-bottom: 2rem;
`
const StyledCommitButton = styled(CommitButton)`
  margin: 0 1rem;
`

export const CommitMessageSection = ({
  workspaceId,
  repoId,
  checkedKeys,
  allChecked,
  afterCommit,
  disabled,
  shouldUseCountdownTimer = false,
  changedItems,
}: {
  workspaceId: string
  repoId: string
  checkedKeys: string[]
  allChecked: boolean | null
  afterCommit: () => void
  disabled: boolean
  shouldUseCountdownTimer?: boolean
  changedItems?: FileEntry[]
}) => {
  const [commitMessage, setCommitMessage] = useSessionStorage<string | null>(
    `workspace.${workspaceId}.commitMessage`,
    null
  )
  const postCommitAsync = usePostCommitAsync(
    repoId,
    workspaceId,
    commitMessage || '',
    allChecked === true ? undefined : checkedKeys,
    () => {
      setCommitMessage(null)
      afterCommit()
    }
  )

  const checkedChangedItems = changedItems?.filter((item) => checkedKeys.includes(item.path))

  return (
    <CommitMessageWrapper>
      <CommitMessage value={commitMessage} setCommitMessage={setCommitMessage} />
      <StyledCommitButton
        enabled={Boolean(commitMessage) && checkedKeys.length > 0 && !disabled}
        postCommitAsync={postCommitAsync}
        shouldUseCountdownTimer={shouldUseCountdownTimer}
        checkedChangedItems={checkedChangedItems}
      />
    </CommitMessageWrapper>
  )
}
