import { ReactNode, useCallback, useState } from 'react'
import { DeleteDialog } from './delete/DeleteDialog'
import { MoveDialog } from './move/MoveDialog'
import { ResetDialog } from './reset/ResetDialog'
import { AgentDownDialog } from '../../../desktop/components/AgentDownDialog'
import { useDeleteAsync } from './delete/useDeleteAsync'
import { useOpenLocalWorkspaceFolderItem } from '../../../desktop/hooks/useOpenLocalWorkspaceFolderItem'
import { useOpenLocalItemWithDefaultApp } from '../../../desktop/hooks/useOpenLocalItemWithDefaultApp'
import { FileOpsActionExec } from './fileOpsTypes'
import { useNavigate } from 'react-router'
import { routeToFileHistory } from '../../../RouteDefinitions'
import { isNetworkError } from '../../../utils/errorClassify'
import { errorToast } from '../../../utils/toast'
import { StatusCodes } from 'http-status-codes'
import { ApiError } from '../../../api/agentapi'
import { log } from '../../../utils/log'

export const useFileOpsActions = (
  repoId: string,
  workspaceId: string
): { doAction: FileOpsActionExec; dialogs: () => ReactNode } => {
  const navigate = useNavigate()
  const deleteFileAsync = useDeleteAsync(repoId, workspaceId)
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [moveDialogIsOpen, setMoveDialogIsOpen] = useState(false)
  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false)
  const [resetDialogIsOpen, setResetDialogIsOpen] = useState(false)
  const [agentIsDownDialogIsOpen, setAgentIsDownDialogIsOpen] = useState(false)
  const { openLocalWorkspaceFolderItem } = useOpenLocalWorkspaceFolderItem()
  const setAgentIsDown = useCallback(() => {
    setAgentIsDownDialogIsOpen(true)
  }, [])
  const { openLocalItemWithDefaultApp } = useOpenLocalItemWithDefaultApp(setAgentIsDown)
  const [filePath, setFilePath] = useState<string>('')

  const doAction = useCallback<FileOpsActionExec>(
    async (action, path) => {
      setFilePath(path)
      switch (action) {
        case 'delete':
          setDeleteDialogIsOpen(true)
          break
        case 'rename':
          setRenameDialogIsOpen(true)
          break
        case 'move':
          setMoveDialogIsOpen(true)
          break
        case 'reset':
          setResetDialogIsOpen(true)
          break
        case 'history':
          navigate(routeToFileHistory(repoId!, path, undefined, workspaceId))
          break
        case 'open-folder':
          try {
            await openLocalWorkspaceFolderItem(repoId, workspaceId, path, true)
          } catch (e) {
            if (isNetworkError(e)) {
              setAgentIsDownDialogIsOpen(true)
              return
            }
            if (e instanceof ApiError && e.status === StatusCodes.NOT_FOUND) {
              errorToast('Path not found, make sure the workspace is cloned locally')
            } else {
              errorToast(`Error occurred while opening ${path}`)
              log.error('Error occurred while opening local folder', { error: e, workspaceId, path })
            }
          }
          break
        case 'open-with-default':
          await openLocalItemWithDefaultApp(repoId, workspaceId, path)
          break
        default:
          log.error(`Unknown action: ${action}`, { path })
          break
      }
    },
    [navigate, workspaceId, repoId, openLocalWorkspaceFolderItem, openLocalItemWithDefaultApp]
  )

  const dialogs = useCallback(
    () => (
      <>
        <DeleteDialog
          isOpen={deleteDialogIsOpen}
          setOpen={setDeleteDialogIsOpen}
          filePath={filePath}
          postDeleteAsync={() => deleteFileAsync(filePath)}
        />
        <MoveDialog path={filePath} isOpen={moveDialogIsOpen} setOpen={setMoveDialogIsOpen} rename={false} />
        <MoveDialog path={filePath} isOpen={renameDialogIsOpen} setOpen={setRenameDialogIsOpen} rename={true} />
        <ResetDialog
          isOpen={resetDialogIsOpen}
          setOpen={setResetDialogIsOpen}
          resetAll={false}
          filePaths={[filePath]}
        />
        <AgentDownDialog isOpen={agentIsDownDialogIsOpen} setOpen={setAgentIsDownDialogIsOpen} />
      </>
    ),
    [
      agentIsDownDialogIsOpen,
      deleteDialogIsOpen,
      deleteFileAsync,
      filePath,
      moveDialogIsOpen,
      renameDialogIsOpen,
      resetDialogIsOpen,
    ]
  )

  return { doAction, dialogs }
}
