import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const TextRegular = css`
  font-family: canada-type-gibson, sans-serif;
  font-size: 1rem;
  font-weight: 400;
`

export const TextBold = css`
  font-weight: 500;
`

export const TextItalic = css`
  font-style: italic;
`

export const TextSmall = css`
  font-size: 0.8rem;
  font-weight: 300;
`

export const TextSmallBold = css`
  font-size: 0.8rem;
  font-weight: 500;
`

export const TextBig = css`
  font-size: 1.1rem;
`

export const TextTitle = css`
  font-size: 1.5rem;
`

export const RefNameText = css`
  font-family: 'Inconsolata', monospace;
  font-size: 1rem;
`

export const FilePath = styled.span`
  ${RefNameText};
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.blue.light};
  padding: ${({ theme }) => theme.padding.s}rem;
  border-radius: 0.5rem;
`
