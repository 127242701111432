import styled from '@emotion/styled'
import { MergeConflictsImage } from './MergeConflictsImage'
import { Link } from 'react-router-dom'
import { routeToMerges } from '../../RouteDefinitions'
import { FlexRowStyle } from '../base/Flex'

type Props = {
  repoId: string
}

const StyledLink = styled(Link)`
  ${FlexRowStyle};
  align-items: center;
  padding: 0 2.5rem;
  cursor: pointer;
`

const StyledMergeConflictsImage = styled(MergeConflictsImage)`
  margin-right: 0.5rem;
`

export const ToMergeConflicts = ({ repoId }: Props) => {
  const text = 'Merge Conflicts'
  return (
    <StyledLink to={routeToMerges(repoId)} title={text}>
      <StyledMergeConflictsImage />
      <span>{text}</span>
    </StyledLink>
  )
}
