import { AuthColumn, AuthErrorText, AuthPage, StyledInput, StyledLink, SubText } from './Common'
import React, { useCallback, useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { resetPasswordAsync } from './actions'
import { usePageTitle } from '../../hooks/usePageTitle'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { useNavigate } from 'react-router'
import { routeToSignIn, routeToSignInPasswordResetVerify, routeToSignUp } from '../../RouteDefinitions'
import { useKeyPress } from '../../hooks/useKeyPress'

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`

const expectedResetPasswordErrors = ['UserNotFoundException']

export const PasswordResetPage = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>()
  const [requestError, setRequestError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const onApiError = useContext(PublishApiErrorContext)
  usePageTitle('Reset Password')

  const resetPassword = useCallback(async () => {
    await callAsync(
      async () => {
        const response = await resetPasswordAsync(username!)
        const email = response.CodeDeliveryDetails.Destination as string
        navigate(routeToSignInPasswordResetVerify(username!, email))
      },
      setLoading,
      (error) => {
        if (expectedResetPasswordErrors.includes(error.name)) {
          setRequestError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }, [navigate, onApiError, username])

  useKeyPress(
    'Enter',
    (event: KeyboardEvent) => {
      if (!isEmpty(username)) {
        resetPassword()
      }
    },
    true
  )

  return (
    <AuthPage>
      <AuthColumn>
        <div>Forgot your password?</div>
        <SubText>Enter your Username below and we will send a message to reset your password</SubText>
        <StyledInput
          autoFocus
          type="text"
          placeholder="Username"
          autoComplete="username"
          onChange={(e) => {
            setRequestError(undefined)
            setUsername(e.target.value)
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <StyledButton disabled={isEmpty(username)} onClick={() => resetPassword()}>
            Reset my password
          </StyledButton>
        )}
        {requestError && <AuthErrorText>{requestError}</AuthErrorText>}
        <SubText>
          <StyledLink onClick={() => navigate(routeToSignIn())}>Sign in</StyledLink> or{' '}
          <StyledLink onClick={() => navigate(routeToSignUp())}>Sign up</StyledLink>
        </SubText>
      </AuthColumn>
    </AuthPage>
  )
}
