import { createContext } from 'react'

export type FileOpsAction = 'delete' | 'rename' | 'move' | 'open-folder' | 'open-with-default' | 'reset' | 'history'

export type FileOpsActionExec = (action: FileOpsAction, path: string) => Promise<void>

export type FileOpsContextType = {
  doAction: FileOpsActionExec
}

export const FileOpsActionContext = createContext<FileOpsContextType>({
  doAction: async () => {},
})
