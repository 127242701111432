import type { MonacoDiffEditor } from '@monaco-editor/react'
import type { editor } from 'monaco-editor'

// Represents which side of the diff view is being referenced in the URL
// This allows users to share direct links to specific lines in either the original or modified file
enum DiffSide {
  ORIGINAL = 'L', // Left side (original file)
  MODIFIED = 'R', // Right side (modified file)
}

type EditorType = MonacoDiffEditor | editor.IStandaloneCodeEditor

// Represents a selected line in either the original or modified file
// Used to track user's current position and maintain it in the URL
type SelectedLine = {
  number: number
  side: DiffSide
}

const defaultSelectedLine: SelectedLine = { number: 0, side: DiffSide.ORIGINAL }

// Extracts line number and side from URL hash, enabling deep-linking to specific lines
// Example URLs: #L42 (line 42 in original) or #R17 (line 17 in modified)
const getLineNumberFromUrl = (): SelectedLine => {
  const hash = window.location.hash.slice(1) // Remove '#'
  const side = hash.startsWith(DiffSide.ORIGINAL) ? DiffSide.ORIGINAL : DiffSide.MODIFIED
  const number = parseInt(hash.slice(1)) // slice(1) to skip the side marker

  const isValidHash = hash.startsWith(side) && !isNaN(number)
  return isValidHash ? { number, side } : defaultSelectedLine
}
const { number: selectedLineNumber, side: selectedSide } = getLineNumberFromUrl()

// Updates URL hash when user selects different lines
const updateLineNumberUrlHash = (position: SelectedLine) => {
  window.location.hash = `${position.side}${position.number}`
}

// Tracks line selection changes in the editor and updates the URL accordingly
// This ensures the URL always reflects the user's current position
const trackEditorLineSelection = (editor: editor.IStandaloneCodeEditor, side: DiffSide) => {
  editor.onDidChangeCursorSelection((e) => {
    updateLineNumberUrlHash({ number: e.selection.startLineNumber, side })
  })
}

// Scrolls editor to specified line and positions cursor there
// Used when loading a page with a line number in the URL
const scrollToEditorLine = (anEditor: editor.IStandaloneCodeEditor, lineNumber: number) => {
  if (!anEditor || lineNumber < 1) {
    return
  }
  anEditor.setPosition({ lineNumber, column: 1 })
  anEditor.revealLineInCenter(lineNumber)
}

const isMonacoDiffEditor = (editor: EditorType): editor is MonacoDiffEditor => {
  return 'getOriginalEditor' in editor && 'getModifiedEditor' in editor
}

// Scrolls the editor to the line selected in the URL,
// and updates URL hash when user selects different lines
export const syncEditorLine = (editor: EditorType) => {
  if (isMonacoDiffEditor(editor)) {
    const { originalEditor, modifiedEditor } = {
      originalEditor: editor.getOriginalEditor(),
      modifiedEditor: editor.getModifiedEditor(),
    }
    trackEditorLineSelection(originalEditor, DiffSide.ORIGINAL)
    trackEditorLineSelection(modifiedEditor, DiffSide.MODIFIED)
    const targetEditor = selectedSide === DiffSide.ORIGINAL ? originalEditor : modifiedEditor
    scrollToEditorLine(targetEditor, selectedLineNumber)
  } else {
    trackEditorLineSelection(editor, DiffSide.ORIGINAL)
    scrollToEditorLine(editor, selectedLineNumber)
  }
}
