import styled from '@emotion/styled'
import config from '../../env/config'
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react'
import { TextSmall } from '../base/TextStyle'
import { FlexRow } from '../base/Flex'
import { Anchor } from '../base/Anchor'
import { remToPx } from '../../theme'
import { emailQueryParam } from '../../RouteDefinitions'
import { useSearchParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const WIDTH = 32

const BANNER_WIDTH = 682

const banner = config.IS_DEV_ENV
  ? require('../../assets/images/auth_banner_test.png')
  : require('../../assets/images/auth_banner.png')

const Banner = ({ width }: { width?: number }) => <img alt="Diversion" src={banner} width={width} />

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black.primary};
  gap: 1rem;
  padding: ${({ theme }) => theme.padding.l}rem;
`

const Container = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}rem` : `${BANNER_WIDTH}px`)};
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.authCard.primary};
  padding: ${({ theme }) => theme.padding.xl}rem;
  border-radius: 2rem;
  gap: 2rem;
  color: ${({ theme }) => theme.colors.black.secondary};
`

export const AuthColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: start;
  gap: 0.5rem;
`

export const StyledInput = styled.input`
  padding: ${({ theme }) => theme.padding.m}rem;
  margin: ${({ theme }) => theme.padding.s}rem 0;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 0.5rem;
  width: 100%;
`

export const SubText = styled.div`
  ${TextSmall};
`

export const StyledAnchor = styled(Anchor)`
  color: ${({ theme }) => theme.colors.blue.primary};
`

export const StyledLink = styled.span`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`

export const AuthErrorText = styled.div`
  color: ${({ theme }) => theme.colors.red.primary};
`

type Props = {
  children: ReactNode
}

export const AuthPage = ({ children }: Props) => (
  <Page>
    <Banner width={remToPx(WIDTH)} />
    <Container width={WIDTH}>{children}</Container>
    <FlexRow gap={1}>
      <div>
        Contact us at <StyledAnchor target={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</StyledAnchor>
      </div>
      <div>·</div>
      <div>
        Chat with us on <StyledAnchor target={config.DISCORD_LINK}>Discord</StyledAnchor>
      </div>
    </FlexRow>
  </Page>
)

export const useSetEmailFromQuery = (setEmail: Dispatch<SetStateAction<string | undefined>>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get(emailQueryParam)
  useEffect(() => {
    if (!isEmpty(email)) {
      setEmail(email!)
      setSearchParams({}, { replace: true })
    }
  }, [email, searchParams, setEmail, setSearchParams])
  return email || undefined
}
