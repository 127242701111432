import { AuthColumn, AuthErrorText, AuthPage, StyledInput, StyledLink, SubText } from './Common'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { signUpResendCodeAsync, signUpVerifyAsync } from './actions'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { usePageTitle } from '../../hooks/usePageTitle'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import { useNavigate } from 'react-router'
import {
  verifyCodeQueryParam,
  emailQueryParam,
  routeToSignUp,
  usernameQueryParam,
  routeToSignIn,
} from '../../RouteDefinitions'
import { useSearchParams } from 'react-router-dom'
import { infoToast } from '../../utils/toast'
import { useKeyPress } from '../../hooks/useKeyPress'
import { rawNavigate } from '../../utils/navigateRaw'

const StyledButton = styled(PrimaryButton)`
  width: 100%;
`

const expectedVerifyErrors = ['UserNotFoundException']

export const SignupVerifyPage = () => {
  const navigate = useNavigate()
  const [requestError, setRequestError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [signupSucceeded, setSignupSucceeded] = useState(false)
  const [searchParams] = useSearchParams()
  const username = searchParams.get(usernameQueryParam)
  const email = searchParams.get(emailQueryParam)
  const verifyCodeFromParams = searchParams.get(verifyCodeQueryParam)
  const [code, setCode] = useState<string>(verifyCodeFromParams || '')
  const postAnalytics = useAnalytics()
  const onApiError = useContext(PublishApiErrorContext)
  usePageTitle('Sign up verify')

  useEffect(() => {
    if (isEmpty(username) || isEmpty(email)) {
      navigate(routeToSignUp())
    }
  }, [email, navigate, username])

  const verifyCode = useCallback(async () => {
    postAnalytics('SignUpCodeEntered', { email: email! })
    await callAsync(
      async () => {
        await signUpVerifyAsync(username!, code!)
        setSignupSucceeded(true)
        const autoSignIn = sessionStorage.getItem('autoSignIn')
        if (!autoSignIn || autoSignIn === 'false') {
          rawNavigate(routeToSignIn())
        }
      },
      setLoading,
      (error) => {
        if (expectedVerifyErrors.includes(error.name)) {
          setRequestError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }, [postAnalytics, username, email, code, onApiError])

  const resendCode = useCallback(async () => {
    await callAsync(
      async () => {
        await signUpResendCodeAsync(username!)
        infoToast('New code sent')
      },
      setLoading,
      (error) => {
        if (expectedVerifyErrors.includes(error.name)) {
          setRequestError(error.message)
        } else {
          onApiError(error)
        }
      }
    )
  }, [onApiError, username])

  useKeyPress(
    'Enter',
    (event: KeyboardEvent) => {
      if (!isEmpty(code)) {
        verifyCode()
      }
    },
    true
  )

  return (
    <AuthPage>
      <AuthColumn>
        <div>We have sent a code by email to {email}</div>
        <div>Enter the code below to confirm your account</div>
        <StyledInput
          autoFocus
          type="text"
          value={code}
          placeholder="Verification code"
          onChange={(e) => {
            setRequestError(undefined)
            setCode(e.target.value.trim())
          }}
        />
        {loading || signupSucceeded ? (
          <Loader />
        ) : (
          <StyledButton disabled={signupSucceeded || isEmpty(code)} onClick={() => verifyCode()}>
            Confirm account
          </StyledButton>
        )}
        {requestError && <AuthErrorText>{requestError}</AuthErrorText>}
        <SubText>
          Didn't receive a code? <StyledLink onClick={() => !loading && resendCode()}>Send a new code</StyledLink>
        </SubText>
      </AuthColumn>
    </AuthPage>
  )
}
