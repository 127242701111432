import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { FileState } from '../../models/fileState'

type Props = {
  fileName: string
  file: FileState
}

const StyledImage = styled.img`
  max-width: calc(100% - 1rem);
  max-height: calc(100% - 1rem);
  height: fit-content;
  color: ${({ theme }) => theme.colors.black.secondary};
`

const AltMessage = styled.div`
  color: ${({ theme }) => theme.colors.black.secondary};
`

export const DynamicImage = ({ fileName, file }: Props) => {
  const [loadFailed, setLoadFailed] = useState<boolean>(false)
  useEffect(() => {
    setLoadFailed(false)
  }, [file])
  if (file.sizeBytes === 0) {
    return <AltMessage>No content</AltMessage>
  }
  if (loadFailed) {
    return <AltMessage>Failed to load image</AltMessage>
  }
  return <StyledImage src={file.downloadUrl} alt={fileName} title={fileName} onError={() => setLoadFailed(true)} />
}
