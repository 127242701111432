import { urlSafeDecode } from '@aws-amplify/core'
import { log } from '../../utils/log'
import { Amplify } from 'aws-amplify'
import { customConfig } from '../../env/cognito'
import isEmpty from 'lodash/isEmpty'
import config from '../../env/config'
import { routeToDashboard } from '../../RouteDefinitions'

const clientIdQueryParam = 'client_id'
const redirectUriQueryParam = 'redirect_uri'
const oauthStateQueryParam = 'state'
const oauthCompletePath = 'complete'

const searchParams = new URLSearchParams(document.location.search)
const clientId = searchParams.get(clientIdQueryParam)
const redirectUri = searchParams.get(redirectUriQueryParam)

const parseCognitoState = (): string | undefined => {
  try {
    const state = new URLSearchParams(document.location.search).get(oauthStateQueryParam)?.split('-')[1]
    if (!state) {
      return undefined
    }
    return urlSafeDecode(state).replace(`/${oauthCompletePath}`, '')
  } catch (e) {
    log.warn('failed to parse cognito state', { search: document.location.search, e })
  }
}

const currentUrlWithoutQuery = window.location.href.split('?')[0]!

export var didCustomConfigure = false

const stateSearchParams = parseCognitoState()
if (stateSearchParams) {
  const stateClientId = new URLSearchParams(stateSearchParams).get(clientIdQueryParam)
  if (!isEmpty(stateClientId)) {
    log.info('configuring amplify with custom config (from state)', stateSearchParams)
    Amplify.configure(customConfig(stateClientId!, currentUrlWithoutQuery))
    didCustomConfigure = true
  }
} else if (!isEmpty(clientId)) {
  log.info('configuring amplify with custom config (from query)', { clientId, redirectUri })
  Amplify.configure(customConfig(clientId!, `${currentUrlWithoutQuery}${oauthCompletePath}`))
  didCustomConfigure = true
}

export const buildOAuthState = (): string => {
  if (config.AUTH_TOKEN_MODE) {
    return `/${oauthCompletePath}?${clientIdQueryParam}=${encodeURIComponent(
      clientId!
    )}&${redirectUriQueryParam}=${encodeURIComponent(redirectUri!)}`
  }
  return redirectUri || `${config.WEBAPP_URL}${routeToDashboard()}`
}

export const isOAuthComplete = () => {
  return window.location.pathname === `/${oauthCompletePath}`
}

export const getRedirectUri = () => redirectUri

export const buildSigninUrl = (baseUrl: string, clientId?: string, redirectUri?: string) => {
  const searchParams = new URLSearchParams()
  if (clientId) {
    searchParams.set(clientIdQueryParam, clientId)
  }
  if (redirectUri) {
    searchParams.set(redirectUriQueryParam, encodeURIComponent(redirectUri))
  }
  return `${baseUrl}?${searchParams.toString()}`
}
