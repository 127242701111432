import { useAnalytics } from '../../../../hooks/api/useAnalytics'
import { useUrlState } from '../../../../hooks/useUrlState'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PublishApiErrorContext } from '../../../../contexts/ErrorContext'
import { callAsync } from '../../../../utils/callAsync'
import { FileMutationService } from '../../../../api/coreapi'
import { clientId } from '../../../../api/configure'
import { DirectoryPickerDialog } from '../../../dialogs/DirectoryPickerDialog'
import { getFileName, getParentPath, joinPaths } from '../../../../utils/pathUtils'
import { ActionDialog } from '../../../dialogs/ActionDialog'
import isEmpty from 'lodash/isEmpty'
import { DialogInput } from '../../../dialogs/DialogStyle'
import { FilePath, TextSmall } from '../../../base/TextStyle'
import styled from '@emotion/styled'
import { ActionValidationContext } from '../actionValidationContext'

type Props = {
  path: string
  isOpen: boolean
  setOpen: (open: boolean) => void
  rename: boolean
}

const RenameBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const moveFileAsync = async (repoId: string, workspaceId: string, fromPath: string, toPath: string) => {
  await FileMutationService.srcHandlersv2FilesMoveFile({
    repoId,
    refId: workspaceId,
    path: fromPath,
    newPath: toPath,
    xDvClientId: clientId(),
  })
}

const ValidationError = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.red.primary};
`

export const MoveDialog = ({ path, isOpen, setOpen, rename }: Props) => {
  const postAnalytics = useAnalytics()
  const { repoId, workspaceId } = useUrlState()
  const [moving, setMoving] = useState(false)
  const { revalidateState } = useContext(ActionValidationContext)
  const onApiError = useContext(PublishApiErrorContext)
  const [newName, setNewName] = useState<string>()
  const [nameErr, setNameErr] = useState<string>()

  useEffect(() => {
    const invalidCharsRegex = /[<>:"\\/|?*]/ // Regular expression to match invalid characters
    setNameErr(newName && invalidCharsRegex.test(newName) ? 'Name cannot contain invalid characters' : '')
  }, [newName])

  const moveCallback = useCallback(
    async (to: string) => {
      await callAsync(
        async () => {
          const toPath = rename ? joinPaths(getParentPath(path), to) : joinPaths(to, getFileName(path))
          if (path === toPath) {
            return
          }
          await moveFileAsync(repoId!, workspaceId!, path, toPath)
          revalidateState()
          postAnalytics('MoveButtonClick', { path, new_path: toPath })
        },
        setMoving,
        onApiError,
        () => setOpen(false)
      )
    },
    [onApiError, rename, path, repoId, workspaceId, revalidateState, postAnalytics, setOpen]
  )

  const dialog = rename ? (
    <ActionDialog
      title="Rename"
      message={
        <RenameBody>
          <div>
            Enter new name for <FilePath>{path}</FilePath>
          </div>
          <DialogInput
            autoFocus
            type="text"
            placeholder="Name"
            onChange={(event) => setNewName(event.target.value || '')}
          />
          {nameErr && <ValidationError>{nameErr}</ValidationError>}
        </RenameBody>
      }
      isOpen={isOpen}
      setOpen={setOpen}
      disabled={isEmpty(newName) || !isEmpty(nameErr)}
      onConfirmAsync={async () => {
        setMoving(true)
        await moveCallback(newName || '')
      }}
      confirmButtonLabel="Rename"
      loadingMessage="Renaming..."
    />
  ) : (
    <DirectoryPickerDialog
      title={moving ? 'Moving...' : 'Select target path'}
      treeIdSuffix="move-item"
      buttonLabel="Move"
      isOpen={isOpen}
      handleClose={() => setOpen(false)}
      loading={moving}
      onSelected={(path) => {
        setMoving(true)
        moveCallback(path)
      }}
    />
  )
  return isOpen && dialog
}
