import { Outlet } from 'react-router'
import { CommitHeader } from './CommitHeader'
import { CommitTree } from './CommitTree'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { useUrlState } from '../../hooks/useUrlState'
import {
  routeToBranchView,
  routeToCommitCompareWithCommit,
  routeToCommitView,
  routeToWorkspaceCommitView,
} from '../../RouteDefinitions'
import { useDelayedLoad } from '../../utils/useDelayedLoad'
import { useEffect, useState } from 'react'
import { FlexRow } from '../base/Flex'
import { Loader } from '../base/Loader'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'
import { notNil } from '../../utils/objectUtil'
import { SidePanel } from '../sidepanel/SidePanel'

const Container = styled.div`
  color: ${({ theme }) => theme.colors.black.primary};
  width: 100%;
  height: 100%;
`

const Content = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.white.primary};
  width: auto;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
`

const OutletWrapper = styled.div<{ hasContent: boolean }>`
  flex: 1;
  height: 100%;
  background-color: ${({ theme, hasContent }) => (hasContent ? 'unset' : theme.colors.overlay.primary)};
`

export const CommitTreeRoute = () => {
  const { repoId, branchId, commitId, compareCommitId, workspaceId, '*': filePath } = useUrlState()
  const isFileSelected = !isEmpty(filePath)
  const isInCompareContext = Boolean(compareCommitId)
  const loaded = useDelayedLoad()
  const [selectedFileBasePath, setSelectedFileBasePath] = useState<string>()

  useEffect(() => {
    setSelectedFileBasePath(undefined)
  }, [filePath])

  const fileUrlPrefix = notNil(workspaceId)
    ? routeToWorkspaceCommitView(repoId!, workspaceId!, commitId!)
    : isInCompareContext
      ? routeToCommitCompareWithCommit(repoId!, commitId!, compareCommitId!)
      : branchId
        ? routeToBranchView(repoId!, branchId!, commitId!)
        : routeToCommitView(repoId!, commitId!)
  return (
    <>
      <FileViewerPathContext.Provider
        value={{ basePath: selectedFileBasePath, setBasePath: setSelectedFileBasePath, setPath: () => {} }}
      >
        <Container>
          <CommitHeader repoId={repoId!} commitId={commitId!} compareCommitId={compareCommitId} filePath={filePath} />
          <Content>
            <SidePanel contextName={'commitTree'} hasOutletContent>
              <CommitTree
                repoId={repoId!}
                commitId={commitId!}
                compareCommitId={compareCommitId}
                allowViewChangesSwitch={!isInCompareContext}
                fileUrlPrefix={fileUrlPrefix}
                selectedFilePath={filePath}
              />
            </SidePanel>
            <OutletWrapper hasContent={isFileSelected}>{loaded ? <Outlet /> : <Loader addPadding />}</OutletWrapper>
          </Content>
        </Container>
      </FileViewerPathContext.Provider>
    </>
  )
}
