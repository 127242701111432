import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { routeToMembers, routeToOrganizationRepos, routeToProfile } from '../../RouteDefinitions'
import { FlexRowStyle } from '../base/Flex'
import { Tabs, Tab } from '@mui/material'
import { TabContext } from '@mui/lab'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Typography } from '@mui/material'
import { useOrganizations } from '../../hooks/api/useOrganizations'
import { useOrganizationSelection } from '../../hooks/useOrganizationSelection'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.primary};
  ${FlexRowStyle};
  height: 100%;
  width: 100%;
  justify-content: start;
`

const StyledTabs = styled(Tabs)`
  border-right: 1px solid ${({ theme }) => theme.colors.white.secondary};
  height: 100%;
  min-width: 200px;
  align-self: flex-start;
  padding: 16px 0;
`

const TabSection = styled.div`
  padding: 16px 24px 8px;
`

const TabSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.black.secondary};
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface LinkTabProps {
  value: string
  label?: string
  href?: string
  selected?: boolean
}

const samePageLinkNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
  !(
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  )

const LinkTab = (props: LinkTabProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Tab
      sx={{ alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '40px', width: '100%' }}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        if (location.pathname === props.href || samePageLinkNavigation(event)) {
          return
        }

        if (props.href) {
          navigate(props.href)
        }
      }}
      selected={props.selected}
      aria-current={props.selected && 'page'}
      {...props}
    />
  )
}

const enum SettingsTab {
  PROFILE = 'profile',
  MEMBERS = 'members',
  REPOSITORIES = 'repositories',
}

export const SettingsRoute = () => {
  const { pathname } = useLocation()
  const { organizations } = useOrganizations()
  const { currentOrg } = useOrganizationSelection(organizations)

  const activeTab = useMemo(() => {
    if (organizations.length === 0) return SettingsTab.PROFILE

    // Only after we have organizations, check the path
    if (pathname.includes(routeToMembers())) {
      return SettingsTab.MEMBERS
    } else if (pathname.includes(routeToOrganizationRepos())) {
      return SettingsTab.REPOSITORIES
    }
    return SettingsTab.PROFILE
  }, [pathname, organizations])

  const shouldShowOrganizations = organizations.length > 0

  return (
    <Container>
      <TabContext value={activeTab}>
        <StyledTabs
          orientation="vertical"
          variant="fullWidth"
          aria-label="User settings tabs"
          value={activeTab}
          role="navigation"
        >
          {shouldShowOrganizations && (
            <TabSection>
              <TabSectionTitle>Private</TabSectionTitle>
            </TabSection>
          )}
          <LinkTab
            value={'profile'}
            label="Profile"
            href={routeToProfile()}
            selected={activeTab === SettingsTab.PROFILE}
          />
          {shouldShowOrganizations && (
            <TabSection>
              <TabSectionTitle>Organization</TabSectionTitle>
            </TabSection>
          )}
          {shouldShowOrganizations && [
            <LinkTab
              value={SettingsTab.MEMBERS}
              label="Members"
              href={routeToMembers(currentOrg?.id)}
              selected={activeTab === SettingsTab.MEMBERS}
            />,
            <LinkTab
              value={SettingsTab.REPOSITORIES}
              label="Repositories"
              href={routeToOrganizationRepos(currentOrg?.id)}
              selected={activeTab === SettingsTab.REPOSITORIES}
            />,
          ]}
        </StyledTabs>
        <Outlet />
      </TabContext>
    </Container>
  )
}
