import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { errorToast, infoToast } from '../../utils/toast'
import { RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { isCheckoutFailedWithPendingChanges, isOperationFailedWithNewerChanges } from '../../utils/errorClassify'

export const useCheckoutAsync = (
  repoId: string | undefined,
  workspaceId: string | undefined,
  onHasPendingChanges: (branchId: string, workspaceId: string) => void
) => {
  const postAnalytics = useAnalytics()
  return useCallback(
    async (branchId: string) => {
      if (isEmpty(workspaceId)) {
        errorToast('No workspace selected')
        return false
      }
      try {
        await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceCheckout({
          repoId: repoId!,
          workspaceId: workspaceId!,
          requestBody: {
            ref_id: branchId,
          },
        })
      } catch (e) {
        if (isCheckoutFailedWithPendingChanges(e)) {
          onHasPendingChanges(branchId, workspaceId!)
          return true
        } else {
          if (isOperationFailedWithNewerChanges(e)) {
            errorToast('Workspace got new changes since checkout started. Try again.')
            return false
          } else {
            throw e
          }
        }
      }
      infoToast('Branch switched successfully', true)
      postAnalytics('BranchCheckedOut', {
        repo_id: repoId,
        branch_id: branchId,
        workspace_id: workspaceId,
      })

      return false
    },
    [onHasPendingChanges, postAnalytics, repoId, workspaceId]
  )
}
