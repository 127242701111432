import React from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Commit } from '../../api/coreapi'
import { formatTimestamp, timeAgo } from '../../utils/dateUtils'
import { StyledLink } from '../auth/Common'
import { useNavigate } from 'react-router'
import { routeToCommitView } from '../../RouteDefinitions'
import styled from '@emotion/styled'
import { TextRegular, TextBold, TextTitle } from '../base/TextStyle'

interface CommitDrawerProps {
  selectedCommit?: Commit
  repoId: string
}

const StyledPaper = styled(Paper)`
  height: 200px;
`

const StyledDrawer = styled(Drawer)`
  padding: 16px;
`

const CommitDetailsTitle = styled(Typography)`
  ${TextTitle};
`

const CommitDetailsText = styled.p`
  ${TextRegular};
`

const CommitDetailsBoldText = styled.strong`
  ${TextBold};
`

const DrawerContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CommitDrawer: React.FC<CommitDrawerProps> = ({ selectedCommit, repoId }) => {
  const navigate = useNavigate()

  return (
    <StyledDrawer
      anchor="bottom"
      open={true}
      variant="persistent"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        component: StyledPaper,
      }}
    >
      <DrawerContent>
        {selectedCommit ? (
          <>
            <CommitDetailsTitle variant="h5" noWrap>
              Commit details
            </CommitDetailsTitle>
            <CommitDetailsText>
              <CommitDetailsBoldText>Commit ID:</CommitDetailsBoldText> {selectedCommit.commit_id}
            </CommitDetailsText>
            <CommitDetailsText>
              <CommitDetailsBoldText>Message:</CommitDetailsBoldText> {selectedCommit.commit_message}
            </CommitDetailsText>
            <CommitDetailsText>
              <CommitDetailsBoldText>Author:</CommitDetailsBoldText> {selectedCommit.author.email}
            </CommitDetailsText>
            <CommitDetailsText>
              <CommitDetailsBoldText>Branch:</CommitDetailsBoldText> {selectedCommit.branch_id}
            </CommitDetailsText>
            <CommitDetailsText>
              <CommitDetailsBoldText>Committed at:</CommitDetailsBoldText> {formatTimestamp(selectedCommit.created_ts)}{' '}
              ({timeAgo(selectedCommit.created_ts)})
            </CommitDetailsText>
            <StyledLink onClick={() => navigate(routeToCommitView(repoId, selectedCommit.commit_id))}>
              View commit
            </StyledLink>
          </>
        ) : (
          <CommitDetailsText>Select a commit to see its details</CommitDetailsText>
        )}
      </DrawerContent>
    </StyledDrawer>
  )
}

export default CommitDrawer
