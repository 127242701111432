import styled from '@emotion/styled'
import DialogContent from '@mui/material/DialogContent'
import { PaddingExtra } from '../base/PaddingStyle'
import DialogActions from '@mui/material/DialogActions'
import { FlexColumnStyle, FlexRowStyle } from '../base/Flex'
import { css } from '@emotion/react'

export const StyledDialogContent = styled(DialogContent)<{
  centerText?: boolean
  maxHeight?: string
  width?: string
  scrollable?: boolean
}>`
  ${FlexColumnStyle};
  ${PaddingExtra};
  min-height: 16rem;
  max-height: ${({ maxHeight }) => maxHeight || 'none'};
  height: auto;
  width: ${({ width }) => width || '38rem'};
  align-items: start;
  justify-content: ${({ scrollable }) => (scrollable ? 'start' : 'center')};
  gap: 1rem;
  text-align: ${({ centerText }) => (centerText ? 'center' : 'start')};
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'unset')};
  background-color: ${({ theme }) => theme.colors.white.primary};
`

export const StyledDialogActions = styled(DialogActions)<{ column?: boolean; height?: number }>`
  ${PaddingExtra};
  padding-top: 0;
  ${({ column }) => (column ? FlexColumnStyle : FlexRowStyle)};
  width: 100%;
  justify-content: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.white.primary};
  box-sizing: border-box;
  ${({ height }) =>
    height &&
    css`
      height: ${height}rem;
    `}

  > * {
    flex: 1 1 0;
    margin-left: 0 !important;
    ${({ column }) =>
      column &&
      css`
        width: 100%;
        height: 3rem;
      `}
  }
`

export const DialogInput = styled.input`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 1rem;
`
