import { useAnalytics } from '../../../hooks/api/useAnalytics'
import { useSessionStorage } from 'usehooks-ts'
import { TreeViewOptions } from '../../tree/TreeViewOptions'
import { useWorkspace } from '../../../hooks/api/useWorkspace'
import { useRepo } from '../../../hooks/api/useRepo'
import { useNotifications } from '../../../hooks/api/useNotifications'
import { useUserInfo } from '../../../hooks/api/useUserInfo'
import * as React from 'react'
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { FileOpsActionContext } from './fileOpsTypes'
import { useFileOpsActions } from './useFileOpsActions'
import { ActionValidationContext, ActionValidationState } from './actionValidationContext'
import config from '../../../env/config'
import { useTreeData } from '../../../hooks/api/useTreeData'
import { useWorkspaceStatus, WorkspaceStatusType } from '../../../hooks/api/useWorkspaceStatus'
import { log } from '../../../utils/log'
import { useTreeSearch } from '../../../hooks/api/useTreeSearch'
import { appendFilePath, routeToWorkspaceEdit } from '../../../RouteDefinitions'
import {
  getFastUploadActionName,
  useOpenLocalWorkspaceFolderItem,
} from '../../../desktop/hooks/useOpenLocalWorkspaceFolderItem'
import { useOtherRefsStatus } from '../../../hooks/api/useOtherRefsStatus'
import { useDropzone } from 'react-dropzone'
import { useUploadingAgents } from '../../../hooks/api/useUploadingAgents'
import { DirStructureTreeView, ReloadIfHasUnloadedChild } from '../../tree/DirStructureTreeView'
import { useIsLocallyCloned } from '../../../desktop/hooks/useIsLocallyCloned'
import { getParentPath } from '../../../utils/pathUtils'
import { ObjectStatusValues } from '../../../models/ChangeType'
import { FileEntry, Notification, Repo, User } from '../../../api/coreapi'
import { FILE_MODE_DIRECTORY } from '../../../models/fileMode'
import { epochSeconds } from '../../../utils/dateUtils'
import { FilesPage } from '../../../hooks/api/getTreePage'
import isNil from 'lodash/isNil'
import { Loader } from '../../base/Loader'
import { WorkspaceStatusBarUpdater } from './WorkspaceStatusBarUpdater'
import { ResetDialog } from './reset/ResetDialog'
import { WorkspaceTopBar } from './WorkspaceTopBar'
import { Separator } from '../../base/Separator'
import { ViewChangesSwitch } from '../../base/ViewChangesSwitch'
import { FlexColumn, FlexFiller, FlexRowStyle } from '../../base/Flex'
import { IsDesktopApp } from '../../../desktop/components/utils/DesktopAppApi'
import { keyframes, Tooltip } from '@mui/material'
import { errorToast } from '../../../utils/toast'
import { FlatDirsView } from '../../tree/FlatDirsView'
import debounce from 'lodash/debounce'
import compact from 'lodash/compact'
import { CommitMessageSection } from './CommitMessageSection'
import styled from '@emotion/styled'
import { WorkspaceCreateDir } from './WorkspaceCreateDir'
import { WorkspaceFileUpload } from './upload/WorkspaceFileUpload'
import { TextTitle } from '../../base/TextStyle'
import Split from 'react-split'
import { Z_INDEX_TOPMOST } from '../../../theme'
import { pluralize } from '../../../utils/textUtils'
import { WorkspaceCreateContent } from './WorkspaceGenContent'
import LaunchIcon from '@mui/icons-material/Launch'
import { RedWarningContainer, WarningContainer } from '../../base/WarningContainer'
import WarningIcon from '@mui/icons-material/Warning'
import { WorkspaceRevisionContext } from '../../workspace/useWorkspaceRevisionUpdater'

type Props = {
  repoId: string
  workspaceId: string
  selectedFilePath?: string
  checkedKeys: string[]
  setCheckedKeys: Dispatch<SetStateAction<string[]>>
  allChecked: boolean | null
  checkedPathsCount: number
  setCheckedPathsCount: (count: number) => void
  onChecked: (keys: string[]) => void
  checkableKeys: string[]
}
const Container = styled(FlexColumn)<{ canCommit: boolean }>`
  min-height: 0;
  flex-grow: 1;
`
const StyledSplit = styled(Split)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .gutter {
    background-color: ${({ theme }) => theme.colors.white.secondary};
    background-repeat: no-repeat;
    background-position: 50%;
  }

  & > .gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  }
  min-height: 0;
`
const glow = keyframes`
  0% { opacity: 0.3; text-shadow: 0 0 10px rgba(255, 255, 255, 0.5); transform: translate(-50%, -50%); }
  100% { opacity: 1; text-shadow: 0 0 0 rgba(255, 255, 255, 0); transform: translate(-50%, -50%);  }
`
const GlowSpan = styled.span`
  animation: ${glow} 0.25s ease-out;
`
const UpperRowLabel = ({
  allChecked,
  checkedFilesCount,
}: {
  allChecked: boolean | null
  checkedFilesCount: number
}) => {
  if (allChecked === true) {
    return (
      <div>
        <GlowSpan key={-1}>All files checked</GlowSpan>
      </div>
    )
  }
  if (allChecked === false) {
    return (
      <div>
        <GlowSpan key={0}>No files checked</GlowSpan>
      </div>
    )
  }
  return (
    <div>
      <GlowSpan key={checkedFilesCount}>
        {`${checkedFilesCount}`} {`${pluralize(checkedFilesCount, 'file', 's', false)} checked`}
      </GlowSpan>
    </div>
  )
}
export const useRefreshOnWorkspaceRevisionUpdated = (refresh: () => void) => {
  const [lastSawRevision, setLastSawRevision] = useState<number>()
  const { workspaceRevision } = useContext(WorkspaceRevisionContext)
  useEffect(() => {
    if (lastSawRevision && workspaceRevision && lastSawRevision !== workspaceRevision) {
      // We want refresh our page only when we see a new revision and don't refresh in the initialization since we already build all the data at this state
      refresh()
    }
    if (workspaceRevision !== lastSawRevision) {
      setLastSawRevision(workspaceRevision)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceRevision, lastSawRevision])
}
const TreeControls = styled.div`
  ${FlexRowStyle};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`
const StyledFileUpload = styled(WorkspaceFileUpload)`
  margin-left: 1rem;
`
const StyledCreateDir = styled(WorkspaceCreateDir)`
  margin: 0 1rem;
`
const StyledCreateContent = styled(WorkspaceCreateContent)`
  margin: 0 1rem;
`
const StyledOpenExplorerIcon = styled(LaunchIcon)`
  cursor: pointer;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.s}rem;
`
const DragFilesOverlay = styled.div`
  height: 100%;
  width: inherit;
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue.hover};
  z-index: ${Z_INDEX_TOPMOST};
`
const DragFilesMessage = styled.div`
  ${TextTitle};
  height: fit-content;
  padding: ${({ theme }) => theme.padding.l}rem;
  border-radius: 0.5rem;
  margin-top: 6rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  background-color: ${({ theme }) => theme.colors.white.primary};
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.white.primary};
`
const hasBreachingNotificationsAndInWarnGroup = async (
  notifications?: Notification[],
  userInfo?: User,
  repo?: Repo
) => {
  if (!notifications) return false
  const isBreaching = notifications.some((notification) =>
    ['org_collaborator_plan_limit', 'org_owner_plan_limit'].includes(notification.id)
  )
  if (!isBreaching) return false

  const encoderEmail = new TextEncoder()
  const dataEmail = encoderEmail.encode(userInfo?.email)

  const hashBufferEmail = await crypto.subtle.digest('SHA-256', dataEmail)

  // Convert the hash to a hexadecimal string
  const hashArrayEmail = Array.from(new Uint8Array(hashBufferEmail))
  const hashHexEmail = hashArrayEmail.map((b) => b.toString(16).padStart(2, '0')).join('')
  const organizationMarked = [
    'dv.org.8b8cdad7-ddfe-4d0e-abc0-3497d06be4e6',
    'dv.org.6ede1154-b60a-4fa9-9bb6-bd877b7cea9a',
    'dv.org.05c9b3cc-9189-4e13-adae-9a93243008f2',
    // 'dv.org.2f2bb9c0-9eaa-4a6c-b4b8-c611688ea8eb', // dv test org
  ].includes(repo?.organization_id || '')
  const repoMarked = [
    'dv.repo.74621c1e-c212-4b5c-8492-3d72d70a268d',
    'dv.repo.f96f5e44-7067-45a6-847c-98ee35c8c4c4',
  ].includes(repo?.repo_id || '')
  const emailMarked = [
    '',
    // 'ca8025a96a1bdc484159805e046a7198368e9dca520e46142ccf893f96d1bcab',
  ].includes(hashHexEmail)
  return organizationMarked || repoMarked || emailMarked
}
const WORKSPACE_IS_CLEAN_MESSAGE = 'Workspace is clean'
export const maxDepthToFetchInTree = 2

export const WorkspacePanel = ({
  repoId,
  workspaceId,
  selectedFilePath,
  checkedKeys,
  setCheckedKeys,
  allChecked,
  checkedPathsCount,
  setCheckedPathsCount,
  onChecked,
  checkableKeys,
}: Props) => {
  const postAnalytics = useAnalytics()
  const [treeViewOption, setTreeViewOption] = useSessionStorage<TreeViewOptions>('workspace.treeView', 'all')
  const { workspace, refresh: refreshWorkspaceInstance } = useWorkspace(repoId, workspaceId)
  const { repo } = useRepo(repoId)
  const { data: notifications } = useNotifications()
  const { data: userInfo } = useUserInfo()
  const [showBreachingTimer, setShowBreachingTimer] = useState(false)
  const canCommit = !isEmpty(workspace?.branch_id)
  const { doAction, dialogs: ActionDialogs } = useFileOpsActions(repoId!, workspaceId!)
  const { actionValidationState, revalidateState } = useContext(ActionValidationContext)

  useEffect(() => {
    const checkIfBreaching = async () => {
      const isBreaching = await hasBreachingNotificationsAndInWarnGroup(notifications, userInfo, repo)
      setShowBreachingTimer(isBreaching)
    }
    checkIfBreaching()
  }, [notifications, userInfo, repo])

  //TODO: remove this after the tests and create a more generic solution
  const UnrealEngineRepoId = 'dv.repo.123ewq12-6bc6-4b1b-9bc2-ccfaa2c44228'
  const customMaxDepth = config.IS_DEV_ENV && repoId === UnrealEngineRepoId ? 1 : maxDepthToFetchInTree
  const {
    treeData,
    loading,
    onExpandNodeAsync,
    refresh: refreshTree,
    refreshWithOptions: refreshTreeWithOptions,
    isNewTree,
    frequentUploadMode,
  } = useTreeData(repoId, workspaceId, undefined, {}, customMaxDepth, workspace?.branch_id || workspace?.base_commit_id)
  const {
    data: changesData,
    loading: changesLoading,
    refresh: refreshChanges,
  } = useWorkspaceStatus('TreeData', repoId, workspaceId)
  useRefreshOnWorkspaceRevisionUpdated(() => {
    log.info('workspace revision updated')
    refreshWorkspaceInstance()
    refreshTree()
    refreshChanges()
  })

  useEffect(() => {
    const checkableKeysSet = new Set(checkableKeys)
    setCheckedKeys((keys) => keys.filter((key) => checkableKeysSet.has(key)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkableKeys, treeData, treeViewOption])

  const [resetDialogIsOpen, setResetDialogIsOpen] = useState(false)
  const [query, setQuery] = useState<string>()
  const { data: searchResultKeys, loading: searchLoading } = useTreeSearch(repoId, workspaceId, query)
  const workspaceEditRoute = useMemo(() => routeToWorkspaceEdit(repoId, workspaceId), [repoId, workspaceId])
  const { openLocalWorkspaceFolderItem } = useOpenLocalWorkspaceFolderItem()
  const { otherStatusesByPath, loadPath } = useOtherRefsStatus(repoId, workspaceId)
  const [createdDirPath, setCreatedDirPath] = useState<string>()
  const [droppedFiles, setDroppedFiles] = useState<File[]>([])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop: (files, rejections) => {
      if (rejections.length > 0) {
        return
      }
      postAnalytics('UploadFilesDropped', {
        repo_id: repoId,
        workspace_id: workspaceId,
        files_count: files.length.toString(),
      })
      setDroppedFiles(files)
    },
  })

  useEffect(() => {
    if (createdDirPath) {
      setTimeout(() => {
        setCreatedDirPath(undefined)
      }, 1000)
    }
  }, [createdDirPath])
  const { data: uploadingAgents } = useUploadingAgents(repoId, workspaceId)
  const isUploading = (uploadingAgents?.uploaders?.length || 0) > 0
  const reloadIfSelected = useCallback(ReloadIfHasUnloadedChild, [])
  const { isLocallyCloned } = useIsLocallyCloned(workspaceId)
  const redirectRouteOnClick = useCallback(
    (path: string) => appendFilePath(workspaceEditRoute, path),
    [workspaceEditRoute]
  )

  const onCreated = useCallback(
    (directoryPath: string) => {
      setCreatedDirPath(getParentPath(directoryPath))
      const existingDirChange = changesData?.items.find((item) => item.path === directoryPath)
      const movedDirChange = changesData?.items.find(
        (item) => item.path !== directoryPath && item.prev_path === directoryPath
      )
      const hasChildrenItems = changesData?.items.some(
        (item) => item.path.startsWith(directoryPath + '/') && item.status === ObjectStatusValues.Deleted
      )

      const predictedStatus = (() => {
        if (movedDirChange) {
          return ObjectStatusValues.Intact
        }

        if (!existingDirChange) {
          return ObjectStatusValues.Added // Completely new directory
        }

        if (existingDirChange.status === ObjectStatusValues.Deleted) {
          // If there were files under it when deleted, it will be Modified
          if (hasChildrenItems) {
            return ObjectStatusValues.Modified
          }
          // Otherwise, it will be Intact
          return ObjectStatusValues.Intact
        }

        return ObjectStatusValues.Added // Default
      })()

      const optimisticDirEntry: FileEntry = {
        path: directoryPath,
        mode: FILE_MODE_DIRECTORY,
        mtime: epochSeconds().toString(),
        status: predictedStatus,
      }
      refreshTreeWithOptions({
        optimisticData: (currentData: FilesPage | undefined) => {
          if (!currentData) return currentData

          return {
            ...currentData,
            items: [...currentData.items, optimisticDirEntry],
          }
        },
        rollbackOnError: true,
        revalidate: false,
      })
      refreshChanges({
        optimisticData: (currentData: WorkspaceStatusType | undefined) => {
          if (!currentData) return currentData

          const existingIndex = currentData.items.findIndex((item) => item.path === directoryPath)

          // Calculate the change in cascadedChangesCount
          const deltaCount = (() => {
            // New item being added
            if (existingIndex < 0) {
              return 1
            }

            const existingItem = currentData.items[existingIndex]

            // If existing change was deleted and now being recreated (status=2,3,4), it counts as the same change if it's not Intact
            if (existingItem!.status === ObjectStatusValues.Deleted && predictedStatus === ObjectStatusValues.Intact) {
              return -1
            }

            // If changing from non-deleted to deleted, it would normally be handled in delete operations, not in create
            // If just changing between non-deleted statuses, count doesn't change
            return 0
          })()

          if (existingIndex >= 0) {
            return {
              ...currentData,
              items: currentData.items.map((item, index) =>
                index === existingIndex ? { ...item, status: predictedStatus } : item
              ),
              cascadedChangesCount: currentData.cascadedChangesCount + deltaCount,
            }
          } else {
            return {
              ...currentData,
              items: [...currentData.items, optimisticDirEntry],
              cascadedChangesCount: currentData.cascadedChangesCount + deltaCount,
            }
          }
        },
        rollbackOnError: true,
        revalidate: false,
      })
    },
    [changesData?.items, refreshChanges, refreshTreeWithOptions]
  )

  const handleCommitSuccess = useCallback(() => {
    const optimisticTreeData = (currentData?: FilesPage) => {
      if (!currentData) return currentData

      return {
        ...currentData,
        items: currentData.items.map((item) =>
          checkedKeys.includes(item.path) ? { ...item, status: ObjectStatusValues.Intact } : item
        ),
      }
    }
    const optimisticChangesData = (currentData?: WorkspaceStatusType): WorkspaceStatusType | undefined => {
      if (!currentData) return currentData

      const remainingItems = currentData.items.filter((item) => !checkedKeys.includes(item.path))

      return {
        ...currentData,
        items: remainingItems,
        cascadedChangesCount: remainingItems.length,
      }
    }
    revalidateState(true, optimisticTreeData, optimisticChangesData)
  }, [revalidateState, checkedKeys])

  return (
    <>
      <ActionDialogs />
      <FileOpsActionContext.Provider value={{ doAction }}>
        <Container canCommit={canCommit} {...getRootProps({ className: 'dropzone' })}>
          {loading && isNil(treeData) ? (
            <Loader addPadding />
          ) : (
            <>
              <input {...getInputProps()} />
              <WorkspaceStatusBarUpdater
                repoId={repoId}
                workspaceId={workspaceId}
                needToForwardWorkspace={changesData?.hasConflict}
                isUploading={isUploading}
              />
              {frequentUploadMode && <MassUploadWarning />}
              {treeData && treeData.caseInsensitivePathsDuplication.length > 0 && (
                <CaseInsensitivityWarning paths={treeData.caseInsensitivePathsDuplication} />
              )}
              <ResetDialog
                isOpen={resetDialogIsOpen}
                setOpen={setResetDialogIsOpen}
                resetAll={allChecked === true}
                filePaths={checkedKeys}
              />
              <WorkspaceTopBar
                upperRowLabel={<UpperRowLabel allChecked={allChecked} checkedFilesCount={checkedPathsCount} />}
                allChecked={allChecked}
                checkedPathsCount={checkedPathsCount}
                refreshingViewAfterAction={actionValidationState !== ActionValidationState.ReadyForAction}
                cascadedChangesCount={changesData?.cascadedChangesCount || 0}
                onResetClick={() => setResetDialogIsOpen(true)}
                onAllChecked={(checked) => {
                  if (checked) {
                    setCheckedKeys(checkableKeys)
                    setCheckedPathsCount(checkableKeys.length)
                  } else {
                    setCheckedKeys([])
                    setCheckedPathsCount(0)
                  }
                }}
              />
              <Separator />
              <TreeControls>
                <ViewChangesSwitch selected={treeViewOption} setSelected={setTreeViewOption} />
                <FlexFiller />
                {config.IS_DEV_ENV ? <StyledCreateContent onCreated={() => {}} /> : <></>}
                {IsDesktopApp() && workspace && isLocallyCloned ? (
                  <Tooltip title={getFastUploadActionName()} arrow>
                    <StyledOpenExplorerIcon
                      onClick={async () => {
                        postAnalytics('FastUploadInExplorerClicked', {})
                        try {
                          await openLocalWorkspaceFolderItem(repoId, workspaceId)
                        } catch (e: any) {
                          const msg = 'Failed opening local workspace'
                          log.warn(msg, e)
                          errorToast(msg)
                        }
                      }}
                    />
                  </Tooltip>
                ) : null}
                <StyledFileUpload dragDroppedFiles={droppedFiles} clearDroppedFiles={() => setDroppedFiles([])} />
                <StyledCreateDir onCreated={onCreated} />
              </TreeControls>
              <StyledSplit direction={'vertical'} sizes={[90, 10]}>
                <div style={{ minHeight: 0, overflow: 'clip' }}>
                  {treeViewOption === 'list_changes' ? (
                    <FlatDirsView
                      changedItems={changesData?.items}
                      changesLoading={changesLoading}
                      noContentLabel={WORKSPACE_IS_CLEAN_MESSAGE}
                      selectedNodeKey={selectedFilePath}
                      redirectRouteOnClick={redirectRouteOnClick}
                      checkedKeys={checkedKeys}
                      onChecked={onChecked}
                      setCheckedPathsCount={setCheckedPathsCount}
                      enableWorkspaceActions
                    />
                  ) : (
                    <DirStructureTreeView
                      treeId={workspaceId}
                      treeData={treeData!}
                      checkedKeys={checkedKeys}
                      onChecked={onChecked}
                      setCheckedPathsCount={setCheckedPathsCount}
                      noContentLabel={WORKSPACE_IS_CLEAN_MESSAGE}
                      redirectRouteOnClick={redirectRouteOnClick}
                      selectedNodeKey={selectedFilePath}
                      changedOnly={treeViewOption === 'tree_changes'}
                      onExpandNodeAsync={async (key) => {
                        loadPath(key)
                        return await onExpandNodeAsync(key)
                      }}
                      onSearch={debounce(setQuery)}
                      searchLoading={searchLoading}
                      searchResultKeys={searchResultKeys}
                      otherStatusesByPath={otherStatusesByPath}
                      expandHints={compact([selectedFilePath, createdDirPath])}
                      enableWorkspaceActions
                      loadOnSelectOrExpand={reloadIfSelected}
                      isNewTree={isNewTree}
                      minHeight={0}
                    />
                  )}
                </div>
                {canCommit && (
                  <div style={{ minHeight: '13rem', flexGrow: 1 }}>
                    <CommitMessageSection
                      workspaceId={workspaceId}
                      repoId={repoId}
                      checkedKeys={checkedKeys}
                      allChecked={allChecked}
                      afterCommit={handleCommitSuccess}
                      disabled={
                        isUploading || changesLoading || actionValidationState !== ActionValidationState.ReadyForAction
                      }
                      shouldUseCountdownTimer={showBreachingTimer}
                      changedItems={changesData?.items}
                    />
                  </div>
                )}
              </StyledSplit>
            </>
          )}
          {isDragActive && (
            <DragFilesOverlay>
              <DragFilesMessage>Drop files to upload to workspace</DragFilesMessage>
            </DragFilesOverlay>
          )}
        </Container>
      </FileOpsActionContext.Provider>
    </>
  )
}
const MassUploadWarning = () => (
  <WarningContainer>
    <WarningIcon /> During uploads tree view might be outdated
  </WarningContainer>
)
const CaseInsensitivityWarning = ({ paths }: { paths: string[][] }) => (
  <Tooltip
    title={
      <FlexColumn>
        <div>
          Two or more file paths differ only by letter case. This can cause issues on case-insensitive systems like
          Windows. Consider renaming or consolidating these paths to avoid conflicts.
        </div>
        <br />
        <div>Conflicting paths:</div>
        {paths.map((p) => <div>{p.join(', ')}</div>).slice(0, 5)}
      </FlexColumn>
    }
    placement={'bottom'}
    arrow
  >
    <RedWarningContainer>
      <WarningIcon /> Path case-sensitivity conflict detected
    </RedWarningContainer>
  </Tooltip>
)
