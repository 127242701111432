import isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from '../base/PrimaryButton'
import { Loader } from '../base/Loader'
import { FlexColumn, FlexRow } from '../base/Flex'
import { CopyIcon } from '../base/CopyIcon'
import React, { useCallback, useEffect, useState } from 'react'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { useSearchParams } from 'react-router-dom'
import { refreshTokenQueryParam } from '../auth/SignInPage'
import { log } from '../../utils/log'
import { sanitizeUrl } from '../../utils/url'
import config from '../../env/config'
import { routeToApiTokens } from '../../RouteDefinitions'
import { buildSigninUrl } from '../auth/configure'
import { rawNavigate } from '../../utils/navigateRaw'
import { FilePath } from '../base/TextStyle'
import styled from '@emotion/styled'
import { useAnalytics } from '../../hooks/api/useAnalytics'

const TokenStyle = styled(FilePath)`
  width: 64rem;
  word-wrap: break-word;
  padding: ${({ theme }) => theme.padding.l}rem;
`

export const TokensTab = () => {
  const [refreshToken, setRefreshToken] = useState<string>()
  const [authLoading] = useState(false)
  const copyToClipboard = useCopyToClipboardAction()
  const [searchParams, setSearchParams] = useSearchParams()
  const postAnalytics = useAnalytics()

  useEffect(() => {
    const queryToken = searchParams.get(refreshTokenQueryParam)
    if (!isEmpty(queryToken)) {
      log.info('got token in query params')
      setRefreshToken(queryToken!)
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    postAnalytics('TokensTabLoaded', {})
  }, [postAnalytics])

  const getToken = useCallback(() => {
    postAnalytics('NewAPITokenClicked', {})
    const redirectUri = `${sanitizeUrl(config.WEBAPP_URL!)}${routeToApiTokens()}`
    const url = buildSigninUrl(config.TOKENS_APP_URL!, config.USER_POOL_CLIENT_ID_PAT!, redirectUri)
    rawNavigate(url)
  }, [postAnalytics])

  return (
    <FlexColumn gap={2}>
      {!isEmpty(refreshToken) && (
        <FlexColumn gap={1}>
          <FlexRow>
            <div>Access Token:</div>
            <CopyIcon copyToClipboard={copyToClipboard} text={refreshToken!} title="Copy token" />
          </FlexRow>
          <TokenStyle>{refreshToken}</TokenStyle>
          <div>Make sure to copy your personal access token now. You won’t be able to see it again!</div>
        </FlexColumn>
      )}
      {authLoading && <Loader />}
      <FlexRow gap={1}>
        {isEmpty(refreshToken) && (
          <PrimaryButton disabled={false} onClick={getToken}>
            Generate a new API token
          </PrimaryButton>
        )}
      </FlexRow>
    </FlexColumn>
  )
}
