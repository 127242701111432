import React, { useCallback, useContext, useState, useEffect, ReactNode } from 'react'
import { PublishApiErrorContext } from '../../contexts/ErrorContext'
import isEmpty from 'lodash/isEmpty'
import { Organization, Repo, RepositoryManagementService } from '../../api/coreapi'
import { ActionDialog } from './ActionDialog'
import styled from '@emotion/styled'
import FormControl from '@mui/material/FormControl'
import { TextRegular } from '../base/TextStyle'
import { CustomSelect, SelectOption } from '../base/CustomSelect'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import config from '../../env/config'
import { CodeRef } from '../base/CodeRef'
import { useOrganizations } from '../../hooks/api/useOrganizations'
import { callAsync } from '../../utils/callAsync'

const OrgSelectContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`

const InfoText = styled.div`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.secondary};
  margin-top: 0.5rem;
  font-size: 0.85rem;
  font-weight: normal;
`

const LearnMoreLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const LinksContainer = styled.div`
  margin-top: 0.5rem;
  font-size: 0.85rem;
`

const SectionDivider = styled(Divider)`
  margin: 1.5rem 0;
  opacity: 0.6;
`

const RepoNameContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 0.6rem;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue.primary};
    box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.1);
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
`

const FieldLabel = styled.label`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.secondary};
  font-weight: 500;
  min-width: 140px;
`

type OrgLinksProps = {
  orgName?: string
}

type OrganizationSelectorProps = {
  organizations: Organization[]
  selectedOrgId: string
  setSelectedOrgId: (id: string) => void
}

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onCreated: (repoId: string, repoName: string) => void
}

const OrganizationLinks = ({ orgName }: OrgLinksProps) => {
  const emailSubject = 'Organization Assistance'
  const emailBody = orgName
    ? `I need help with my organization ${encodeURIComponent(orgName)}.`
    : 'I need help with organizations.'

  return (
    <LinksContainer>
      <LearnMoreLink href={config.ORGANIZATION_MANAGEMENT_LINK} target="_blank" rel="noopener noreferrer">
        Learn more
      </LearnMoreLink>{' '}
      about organization management or{' '}
      <LearnMoreLink href={`mailto:${config.SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`}>
        contact us
      </LearnMoreLink>{' '}
      for further assistance.
    </LinksContainer>
  )
}

const OrganizationDropdown = ({ organizations, selectedOrgId, setSelectedOrgId }: OrganizationSelectorProps) => {
  const orgOptions: SelectOption[] = organizations.map((org) => ({
    value: org.id,
    label: org.name,
  }))

  const selectedOrg = selectedOrgId ? organizations.find((org) => org.id === selectedOrgId) : null

  return (
    <OrgSelectContainer>
      <DropdownContainer>
        <FieldLabel>Parent Organization:</FieldLabel>
        <FormControl fullWidth>
          <CustomSelect
            id="organization-select"
            value={selectedOrgId}
            onChange={(e) => setSelectedOrgId(e.target.value as string)}
            options={orgOptions}
            size="small"
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '0.95rem',
                padding: '0.4rem 0.8rem',
              },
            }}
          />
        </FormControl>
      </DropdownContainer>
      <InfoText>Organization repositories share storage quota and access permissions.</InfoText>
      <OrganizationLinks orgName={selectedOrg?.name} />
    </OrgSelectContainer>
  )
}

export const NewRepoDialog = ({ isOpen, setOpen, onCreated }: Props) => {
  const [repoName, setRepoName] = useState<string>('')
  const [selectedOrgId, setSelectedOrgId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onApiError = useContext(PublishApiErrorContext)
  const { organizations } = useOrganizations()

  // Set first organization as selected when organizations are loaded
  useEffect(() => {
    if (isOpen && organizations.length > 0 && organizations[0]?.id) {
      setSelectedOrgId(organizations[0].id)
    }
  }, [organizations, isOpen])

  // Reset state when dialog closes
  useEffect(() => {
    if (!isOpen) {
      setRepoName('')
      setSelectedOrgId('')
    }
  }, [isOpen])

  const handleConfirmAsync = useCallback(async (): Promise<void> => {
    return callAsync(
      async () => {
        const repo = (await RepositoryManagementService.srcHandlersv2RepoPost({
          requestBody: {
            repo_name: repoName,
            organization_id: selectedOrgId || undefined,
          },
        })) as Repo
        onCreated(repo.repo_id, repoName)
      },
      setIsLoading,
      onApiError
    )
  }, [repoName, selectedOrgId, onCreated, onApiError])

  const renderDialogContent = (): ReactNode => {
    const hasSingleOrg = organizations.length === 1
    const singleOrg = hasSingleOrg ? organizations[0] : null

    return (
      <Box sx={{ padding: '0.5rem 0' }}>
        {organizations.length > 1 && (
          <>
            <OrganizationDropdown
              organizations={organizations}
              selectedOrgId={selectedOrgId}
              setSelectedOrgId={setSelectedOrgId}
            />
            <SectionDivider />
          </>
        )}

        {hasSingleOrg && singleOrg && (
          <div style={{ marginBottom: '1.5rem' }}>
            <div style={{ fontSize: '0.9rem', color: '#5a5a5a' }}>
              This repository will be associated with <CodeRef>{singleOrg.name}</CodeRef>.
            </div>
            <OrganizationLinks orgName={singleOrg.name} />
          </div>
        )}

        <RepoNameContainer>
          <DropdownContainer>
            <FieldLabel>Repository Name:</FieldLabel>
            <StyledInput
              autoFocus
              type="text"
              value={repoName}
              placeholder="my-awesome-repo"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRepoName(event.target.value || '')}
            />
          </DropdownContainer>

          <InfoText>
            Use a descriptive name for your repository. Only alphanumeric characters, hyphens, and underscores are
            allowed.
          </InfoText>
        </RepoNameContainer>
      </Box>
    )
  }

  return (
    <ActionDialog
      title="Create New Repository"
      message={renderDialogContent()}
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={handleConfirmAsync}
      confirmButtonLabel="Create"
      loadingMessage="Creating repository..."
      disabled={isEmpty(repoName) || (organizations.length > 1 && isEmpty(selectedOrgId)) || isLoading}
    />
  )
}
