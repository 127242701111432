import { useCallback, useContext } from 'react'
import { FileMutationService } from '../../../../api/coreapi'
import { clientId } from '../../../../api/configure'
import { infoToast } from '../../../../utils/toast'
import { useAnalytics } from '../../../../hooks/api/useAnalytics'
import { useNavigate } from 'react-router'
import { routeToWorkspaceEdit } from '../../../../RouteDefinitions'
import { ActionValidationContext } from '../actionValidationContext'
import { FilesPage } from '../../../../hooks/api/getTreePage'
import { WorkspaceStatusType } from '../../../../hooks/api/useWorkspaceStatus'
import { ObjectStatusValues } from '../../../../models/ChangeType'

export const useDeleteAsync = (repoId: string, workspaceId: string) => {
  const navigate = useNavigate()
  const { revalidateState } = useContext(ActionValidationContext)
  const postAnalytics = useAnalytics()
  return useCallback(
    async (filePath: string) => {
      const optimisticTreeData = (currentData?: FilesPage) => {
        if (!currentData) return currentData

        const currentItem = currentData.items.find((item) => item.path === filePath)

        if (currentItem?.status === ObjectStatusValues.Added) {
          return {
            ...currentData,
            items: currentData.items.filter((item) => item.path !== filePath),
          }
        }

        return {
          ...currentData,
          items: currentData.items.map((item) => {
            if (item.path === filePath) {
              return { ...item, status: ObjectStatusValues.Deleted }
            }
            return item
          }),
        }
      }

      const optimisticChangesData = (currentData?: WorkspaceStatusType) => {
        if (!currentData) return currentData

        const currentItem = currentData.items.find((item) => item.path === filePath)

        if (currentItem?.status === ObjectStatusValues.Added) {
          return {
            ...currentData,
            items: currentData.items.filter((item) => item.path !== filePath),
            cascadedChangesCount: currentData.cascadedChangesCount - 1,
          }
        }

        // Modified to Deleted
        return {
          ...currentData,
          items: currentData.items.map((item) => {
            if (item.path === filePath) {
              return { ...item, status: ObjectStatusValues.Deleted }
            }
            return item
          }),
          cascadedChangesCount: currentData.cascadedChangesCount,
        }
      }

      await FileMutationService.srcHandlersv2FilesDeleteFile({
        repoId,
        refId: workspaceId,
        xDvClientId: clientId(),
        path: filePath,
      })
      infoToast('Item deleted successfully', true)

      revalidateState(true, optimisticTreeData, optimisticChangesData)

      postAnalytics('DeleteItems', {
        files_count: '1',
        path: filePath,
      })
      navigate(routeToWorkspaceEdit(repoId, workspaceId))
    },
    [navigate, postAnalytics, revalidateState, repoId, workspaceId]
  )
}
