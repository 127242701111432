import { ActionDialog } from './ActionDialog'
import React, { useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { Tier } from '../../api/coreapi'
import { sleep } from '../../utils/sleep'
import { TierInfo, UserTierChooser } from '../user/UserTierChooser'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onConfirm: (tier: Tier) => Promise<void>
}

export const IsSetTierButtonDisabled = (selectedTier: string, checkboxChecked: boolean, extraTierInfo?: string) => {
  if (selectedTier === Tier.PROFESSIONAL) {
    return false
  }
  if (!checkboxChecked) {
    return true
  }
  if (selectedTier === Tier.EDUCATION) {
    return !extraTierInfo?.trim()
  }
  return false
}

export const SetUserTierDialog = ({ isOpen, setOpen, onConfirm }: Props) => {
  const postAnalytics = useAnalytics()
  const [selectedTier, setSelectedTier] = useState(Tier.UNKNOWN)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [contactMeChecked, setContactMeChecked] = useState(false)
  const [tierInfo, setTierInfo] = useState<TierInfo>({})
  return (
    <ActionDialog
      title="Choose your organization type"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        let extraProperties = {
          user_profession: tierInfo.userProfession,
          company_field: tierInfo.companyField,
          project_stage: tierInfo.projectStage,
          referred_by: tierInfo.referredBy,
          extra_tier_info: tierInfo.extraInfo,
          contact_me: contactMeChecked ? 'true' : 'false',
        }
        postAnalytics('SetUserTierClicked', { tier: selectedTier, ...extraProperties })
        await Promise.all([onConfirm(selectedTier), sleep(2500)])
      }}
      message={
        <UserTierChooser
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          contactMeChecked={contactMeChecked}
          setContactMeChecked={setContactMeChecked}
          tierInfo={tierInfo}
          setTierInfo={setTierInfo}
        />
      }
      setOpen={setOpen}
      confirmButtonLabel="Choose tier"
      loadingMessage="Choosing tier..."
      disabled={IsSetTierButtonDisabled(selectedTier, checkboxChecked, tierInfo.extraInfo)}
    />
  )
}
