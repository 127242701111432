import type { Monaco } from '@monaco-editor/react'
import type { languages } from 'monaco-editor'

// Registers more file extensions for Monaco syntax highlighting.
export const registerFileExtensions = (monaco: Monaco) => {
  if (!monaco) {
    return
  }
  // Unreal Engine related file extensions:
  monaco.languages.register({
    id: 'json',
    extensions: ['.uproject', '.uplugin'],
    aliases: ['JSON', 'json'],
    mimetypes: ['application/json'],
  })
  monaco.languages.register({
    id: 'ini',
    extensions: ['.uprojectdirs'],
    aliases: ['INI', 'ini'],
    mimetypes: ['text/x-properties'],
  })
  monaco.languages.register({
    id: 'c',
    extensions: ['.ispc', '.usf', '.ush', '.glsl', '.hlsl', '.isph'],
    aliases: ['ISPC', 'USF', 'USH', 'GLSL', 'HLSL', 'ISPH'],
    mimetypes: ['text/x-c'],
  })
  monaco.languages.register({
    id: 'markdown',
    extensions: ['.udn'],
    aliases: ['UDN', 'udn'],
    mimetypes: ['text/x-markdown'],
  })
}

// Extracts the extension from a file path.
const ext = (filePath: string) => filePath.split('.').pop()?.toLowerCase() || ''

// Detects the language of a file based on Monaco's language extension points.
// This is needed because DiffEditor sometimes needs language to be set explicitly
// to enable syntax highlighting.
export const detectLanguage = (monaco: Monaco | undefined, filePath: string) => {
  if (!monaco) {
    return 'plaintext'
  }
  const langExtPoints = monaco.languages.getLanguages()
  const fileExt = ext(filePath)
  const lang = langExtPoints.find(
    (lang: languages.ILanguageExtensionPoint) =>
      lang.extensions?.includes(`.${fileExt}`) || lang.filenames?.includes(filePath)
  )
  return lang?.id || 'plaintext'
}
