import { useCallback, useState, useEffect } from 'react'
import { ActionDialog } from './ActionDialog'
import {
  AccessMode,
  OrganizationManagementService,
  OrganizationMemberData,
  PaginatedOrganizationMemberList,
} from '../../api/coreapi'
import { errorToast, infoToast } from '../../utils/toast'
import { callAsync } from '../../utils/callAsync'
import styled from '@emotion/styled'
import { TextRegular } from '../base/TextStyle'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'
import { FlexColumn } from '../base/Flex'
import { Refresh } from '../../hooks/useApi'

const RoleLabel = styled.span`
  ${TextRegular}
`

const RoleDescription = styled.span`
  margin-left: 2rem;
  ${TextRegular}
  color: ${({ theme }) => theme.colors.black.secondary};
`

const StyledFormLabel = styled(FormLabel)`
  ${TextRegular}
  margin-bottom: 1rem;
`

const roleInfo = {
  [AccessMode.ADMIN]: {
    label: 'Admin',
    description: 'Can manage organization members, repositories, and settings.',
  },
  [AccessMode.READ]: {
    label: 'Member',
    description: 'Can view and contribute to assigned repositories.',
  },
}

interface ChangeRoleDialogProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  orgId: string
  userId: string
  memberName: string
  currentRole: string
  refreshMembers: Refresh
}

export const ChangeRoleDialog = ({
  isOpen,
  setOpen,
  orgId,
  userId,
  memberName,
  currentRole,
  refreshMembers,
}: ChangeRoleDialogProps) => {
  const mapToAccessMode = (role: string): AccessMode => {
    switch (role) {
      case 'ADMIN':
      case 'OWNER':
        return AccessMode.ADMIN
      default:
        return AccessMode.READ
    }
  }

  const [selectedRole, setSelectedRole] = useState<string>(currentRole)
  useEffect(() => {
    if (isOpen) {
      setSelectedRole(currentRole)
    }
  }, [isOpen, currentRole])

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value)
  }

  const onApiError = useCallback(
    (error: Error) => {
      console.error('Failed to change user role:', error)
      errorToast('Failed to update permissions')
      refreshMembers()
    },
    [refreshMembers]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleConfirm = useCallback(async () => {
    if (selectedRole === currentRole) {
      handleClose()
      return
    }

    await callAsync(
      async () => {
        await OrganizationManagementService.srcHandlersv2OrganizationChangeRole({
          orgId,
          userId,
          requestBody: {
            role: mapToAccessMode(selectedRole),
          },
        })
        infoToast(`Role updated to ${selectedRole.toLowerCase()} for ${memberName}`)

        refreshMembers({
          optimisticData: (currentData: PaginatedOrganizationMemberList) => {
            if (!currentData) return currentData
            return {
              ...currentData,
              items: currentData.items.map((item: OrganizationMemberData) =>
                item.user.id === userId
                  ? {
                      ...item,
                      member: { ...item.member, role: mapToAccessMode(selectedRole) },
                    }
                  : item
              ),
            }
          },
          rollbackOnError: true,
          revalidate: false,
          populateCache: true,
        })
      },
      undefined,
      onApiError,
      handleClose
    )
  }, [selectedRole, currentRole, onApiError, handleClose, orgId, userId, memberName, refreshMembers])

  return (
    <ActionDialog
      title={`Change role for ${memberName}`}
      isOpen={isOpen}
      setOpen={setOpen}
      message={
        <FormControl>
          <StyledFormLabel>Select a role</StyledFormLabel>
          <RadioGroup aria-label="role" name="role" value={selectedRole} onChange={handleRoleChange}>
            <FlexColumn>
              <FormControlLabel
                value="ADMIN"
                control={<Radio size="small" />}
                label={<RoleLabel>{roleInfo[AccessMode.ADMIN].label}</RoleLabel>}
              />
              <RoleDescription>{roleInfo[AccessMode.ADMIN].description}</RoleDescription>
            </FlexColumn>
            <FlexColumn>
              <FormControlLabel
                value="MEMBER"
                control={<Radio size="small" />}
                label={<RoleLabel>{roleInfo[AccessMode.READ].label}</RoleLabel>}
              />
              <RoleDescription>{roleInfo[AccessMode.READ].description}</RoleDescription>
            </FlexColumn>
          </RadioGroup>
        </FormControl>
      }
      onConfirmAsync={handleConfirm}
      confirmButtonLabel="Confirm"
      loadingMessage="Updating role..."
      disabled={selectedRole === currentRole}
    />
  )
}
