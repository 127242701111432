import { Repo } from '../../api/coreapi'
import { SelectItem } from './SelectItem'
import { routeToRepo } from '../../RouteDefinitions'
import { SearchBox } from './SearchBox'
import isEmpty from 'lodash/isEmpty'
import { NoContentMessage } from './NoContentMessage'
import { useFiltered } from '../../hooks/useFiltered'
import { SelectorContainer } from './SelectorContainer'
import { Separator } from '../base/Separator'

type Props = {
  selectedRepoId?: string
  repos: Repo[]
}

export const RepoSelector = ({ selectedRepoId, repos }: Props) => {
  const [filteredRepos, search] = useFiltered(repos, (r) => [r.repo_name, r.repo_id])

  return (
    <SelectorContainer>
      {isEmpty(repos) ? (
        <NoContentMessage>You have no repositories</NoContentMessage>
      ) : (
        <>
          <SearchBox hint={'Search for a repo'} onChange={(query) => search(query)} />
          <Separator />
          {isEmpty(filteredRepos) ? (
            <NoContentMessage>No repositories found</NoContentMessage>
          ) : (
            filteredRepos.map((repo) => (
              <SelectItem
                key={repo.repo_id}
                description={`Repo ${repo.repo_id}`}
                redirectToOnClick={routeToRepo(repo.repo_id)}
                title={repo.repo_name}
                isSelected={selectedRepoId === repo.repo_id}
                clickable={selectedRepoId !== repo.repo_id}
              />
            ))
          )}
        </>
      )}
    </SelectorContainer>
  )
}
